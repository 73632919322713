import { gql } from 'graphql.macro';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHoc from '@apollo/client/react/hoc';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  File: any;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any;
  /**
   * The `Time` scalar type represents a Time value as
   * specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Time: any;
  /**
   * Create scalar that ignores normal serialization/deserialization, since
   * that will be handled by the multipart request spec
   */
  Upload: any;
};

export type AccountsCachedData = {
  __typename?: 'AccountsCachedData';
  cellphone: Scalars['String'];
  cpf: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
};

export type AccountsInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  cellphone?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type AccountsUser = Node & {
  __typename?: 'AccountsUser';
  /** The ID of the object. */
  id: Scalars['ID'];
  userId?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
  allowedCompanies?: Maybe<Array<Maybe<Company>>>;
  allowedBrands?: Maybe<Array<Maybe<Brand>>>;
  allowedCompaniesByBrand?: Maybe<Array<Maybe<Company>>>;
};

export type AccountsUserAllowedCompaniesByBrandArgs = {
  brandId: Scalars['Int'];
};

export type AdditionalChargeParameter = Node & {
  __typename?: 'AdditionalChargeParameter';
  optional?: Maybe<Scalars['Boolean']>;
  orderTypeParameterId?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  additionalPriceType?: Maybe<AdditionalChargePriceTypeEnum>;
  additionalValue?: Maybe<Scalars['Float']>;
  additionalType?: Maybe<AdditionalChargeTypeEnum>;
  /** The ID of the object. */
  id: Scalars['ID'];
};

export enum AdditionalChargePriceTypeEnum {
  Fixed = 'FIXED',
  Percentage = 'PERCENTAGE',
}

export enum AdditionalChargeTypeEnum {
  Delivery = 'DELIVERY',
  Package = 'PACKAGE',
  Other = 'OTHER',
}

export type Address = Node & {
  __typename?: 'Address';
  /** The ID of the object. */
  id: Scalars['ID'];
  street?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  geoLat?: Maybe<Scalars['Float']>;
  geoLon?: Maybe<Scalars['Float']>;
};

export type AddressCachedData = {
  __typename?: 'AddressCachedData';
  street: Scalars['String'];
  number: Scalars['String'];
  zipCode: Scalars['String'];
  state: Scalars['String'];
  complement: Scalars['String'];
  city: Scalars['String'];
};

export type AddressInput = {
  street: Scalars['String'];
  number: Scalars['String'];
  complement?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
  country: Scalars['String'];
  geoLat: Scalars['Float'];
  geoLon: Scalars['Float'];
};

export type AddressLatLongCachedData = {
  __typename?: 'AddressLatLongCachedData';
  geoLat?: Maybe<Scalars['Float']>;
  geoLon?: Maybe<Scalars['Float']>;
};

export enum AggregatorFilterOption {
  DeliveryArea = 'DELIVERY_AREA',
}

export enum AuthUserType {
  Crm = 'CRM',
}

export type BackendMenuDeleteProduct = {
  __typename?: 'BackendMenuDeleteProduct';
  success?: Maybe<Scalars['Boolean']>;
};

export type BackendMenuDeleteProductPictures = {
  __typename?: 'BackendMenuDeleteProductPictures';
  success?: Maybe<Scalars['Boolean']>;
};

export type BackendMenuReorderProduct = {
  __typename?: 'BackendMenuReorderProduct';
  success?: Maybe<Scalars['Boolean']>;
};

export type BackendMenuUpsertChoosablesInput = {
  name: Scalars['String'];
  productId?: Maybe<Scalars['Int']>;
  minimumChoices: Scalars['Int'];
  maximumChoices: Scalars['Int'];
  simples?: Maybe<Array<Maybe<BackendMenuUpsertSimplesInput>>>;
};

export type BackendMenuUpsertItem = {
  __typename?: 'BackendMenuUpsertItem';
  category?: Maybe<Category>;
  product?: Maybe<Product>;
  productCompany?: Maybe<ProductCompany>;
};

export type BackendMenuUpsertItemInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  category: Scalars['String'];
  productId?: Maybe<Scalars['Int']>;
  categoryId?: Maybe<Scalars['Int']>;
  choosables?: Maybe<Array<Maybe<BackendMenuUpsertChoosablesInput>>>;
};

export type BackendMenuUpsertSimplesInput = {
  name: Scalars['String'];
  productId?: Maybe<Scalars['Int']>;
  minimumChoices: Scalars['Int'];
  maximumChoices: Scalars['Int'];
  price?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Int']>;
};

export type BackendSubproductRemoveItem = {
  __typename?: 'BackendSubproductRemoveItem';
  success?: Maybe<Scalars['Boolean']>;
};

export type Balance = {
  __typename?: 'Balance';
  brand?: Maybe<Brand>;
  points?: Maybe<Scalars['Int']>;
};

export type BalanceConnection = {
  __typename?: 'BalanceConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BalanceEdge>>;
};

/** A Relay edge containing a `Balance` and its cursor. */
export type BalanceEdge = {
  __typename?: 'BalanceEdge';
  /** The item at the end of the edge */
  node?: Maybe<Balance>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Brand = Node & {
  __typename?: 'Brand';
  /** The ID of the object. */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  layout?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  brandTheme?: Maybe<BrandThemeConnection>;
  category?: Maybe<CategoryConnection>;
  company?: Maybe<CompanySharedConnection>;
  menuCategory?: Maybe<MenuCategoryConnection>;
  menuProduct?: Maybe<MenuProductConnection>;
  products?: Maybe<ProductConnection>;
  promotions?: Maybe<PromotionConnection>;
  timeRule?: Maybe<TimeRuleConnection>;
  numericalId?: Maybe<Scalars['Int']>;
  images?: Maybe<Array<Maybe<Image>>>;
  reference?: Maybe<Scalars['String']>;
};

export type BrandBrandThemeArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type BrandCategoryArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type BrandCompanyArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type BrandMenuCategoryArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type BrandMenuProductArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type BrandProductsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type BrandPromotionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type BrandTimeRuleArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type BrandConnection = {
  __typename?: 'BrandConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BrandEdge>>;
};

/** A Relay edge containing a `Brand` and its cursor. */
export type BrandEdge = {
  __typename?: 'BrandEdge';
  /** The item at the end of the edge */
  node?: Maybe<Brand>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type BrandImageInput = {
  __typename?: 'BrandImageInput';
  cropHeight: Scalars['Int'];
  cropWidth: Scalars['Int'];
  cropX: Scalars['Int'];
  cropY: Scalars['Int'];
  context: Scalars['String'];
  cropId?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum BrandSortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  LayoutAsc = 'LAYOUT_ASC',
  LayoutDesc = 'LAYOUT_DESC',
}

export type BrandTheme = Node & {
  __typename?: 'BrandTheme';
  /** The ID of the object. */
  id: Scalars['ID'];
  brandThemeColor?: Maybe<Array<Maybe<BrandThemeColor>>>;
  brandThemeMessage?: Maybe<Array<Maybe<BrandThemeMessage>>>;
  numericalId?: Maybe<Scalars['Int']>;
};

export type BrandThemeColor = Node & {
  __typename?: 'BrandThemeColor';
  /** The ID of the object. */
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type BrandThemeConnection = {
  __typename?: 'BrandThemeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BrandThemeEdge>>;
};

/** A Relay edge containing a `BrandTheme` and its cursor. */
export type BrandThemeEdge = {
  __typename?: 'BrandThemeEdge';
  /** The item at the end of the edge */
  node?: Maybe<BrandTheme>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type BrandThemeMessage = Node & {
  __typename?: 'BrandThemeMessage';
  /** The ID of the object. */
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type BusinessCachedData = {
  __typename?: 'BusinessCachedData';
  brandName: Scalars['String'];
  name: Scalars['String'];
  displayName: Scalars['String'];
  corporateName: Scalars['String'];
  cnpj: Scalars['String'];
  telephone: Scalars['String'];
  fileUrl: Scalars['String'];
  brandImageFile?: Maybe<Scalars['File']>;
  brandImageInput?: Maybe<BrandImageInput>;
};

export type CachedImage = {
  __typename?: 'CachedImage';
  url?: Maybe<Scalars['String']>;
  context?: Maybe<Scalars['String']>;
  originalImageUrl?: Maybe<Scalars['String']>;
  originalImageId?: Maybe<Scalars['Int']>;
  cropId?: Maybe<Scalars['Int']>;
  cropX?: Maybe<Scalars['Int']>;
  cropY?: Maybe<Scalars['Int']>;
  cropWidth?: Maybe<Scalars['Int']>;
  cropHeight?: Maybe<Scalars['Int']>;
  originalImageWidth?: Maybe<Scalars['Int']>;
  originalImageHeight?: Maybe<Scalars['Int']>;
  file?: Maybe<Scalars['File']>;
};

export type CalendarTimeRule = Node & {
  __typename?: 'CalendarTimeRule';
  /** The ID of the object. */
  id: Scalars['ID'];
  timeRuleId?: Maybe<Scalars['Int']>;
  dayFrom?: Maybe<Scalars['String']>;
  dayTo?: Maybe<Scalars['String']>;
  timeFrom?: Maybe<Scalars['String']>;
  timeTo?: Maybe<Scalars['String']>;
  timeRule?: Maybe<TimeRule>;
};

export type CalendarTimeRuleConnection = {
  __typename?: 'CalendarTimeRuleConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CalendarTimeRuleEdge>>;
};

/** A Relay edge containing a `CalendarTimeRule` and its cursor. */
export type CalendarTimeRuleEdge = {
  __typename?: 'CalendarTimeRuleEdge';
  /** The item at the end of the edge */
  node?: Maybe<CalendarTimeRule>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Card = Node & {
  __typename?: 'Card';
  /** The ID of the object. */
  id: Scalars['ID'];
  printedName?: Maybe<Scalars['String']>;
  expiration?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  pinValidated?: Maybe<Scalars['Boolean']>;
  maskedNumber?: Maybe<Scalars['String']>;
  singleUse?: Maybe<Scalars['Boolean']>;
  cardBrandId?: Maybe<Scalars['Int']>;
  customerId?: Maybe<Scalars['Int']>;
  cardBrand?: Maybe<CardBrand>;
  customer?: Maybe<Customer>;
};

export type CardBrand = Node & {
  __typename?: 'CardBrand';
  /** The ID of the object. */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  card?: Maybe<CardConnection>;
};

export type CardBrandCardArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CardConnection = {
  __typename?: 'CardConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CardEdge>>;
};

/** A Relay edge containing a `Card` and its cursor. */
export type CardEdge = {
  __typename?: 'CardEdge';
  /** The item at the end of the edge */
  node?: Maybe<Card>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Category = Node & {
  __typename?: 'Category';
  /** The ID of the object. */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  menuCategory?: Maybe<MenuCategoryConnection>;
  products?: Maybe<ProductConnection>;
  numericalId?: Maybe<Scalars['Int']>;
};

export type CategoryMenuCategoryArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CategoryProductsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CategoryConnection = {
  __typename?: 'CategoryConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CategoryEdge>>;
};

/** A Relay edge containing a `Category` and its cursor. */
export type CategoryEdge = {
  __typename?: 'CategoryEdge';
  /** The item at the end of the edge */
  node?: Maybe<Category>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum CategorySortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  OrderAsc = 'ORDER_ASC',
  OrderDesc = 'ORDER_DESC',
}

export type ChoosableInput = {
  productName: Scalars['String'];
  productPosName?: Maybe<Scalars['String']>;
  productPosReference?: Maybe<Scalars['String']>;
  productOperator: Scalars['String'];
  productSequence: Scalars['Int'];
  productCompanyPrice?: Maybe<Scalars['Float']>;
  productCompanyPosPrice?: Maybe<Scalars['Float']>;
  productShortDescription?: Maybe<Scalars['String']>;
  productFullDescription?: Maybe<Scalars['String']>;
  productCategoryId?: Maybe<Scalars['Int']>;
  productCategoryName?: Maybe<Scalars['String']>;
  productImageUrl?: Maybe<Scalars['String']>;
  productMinimumChoices: Scalars['Int'];
  productMaximumChoices: Scalars['Int'];
  simples: Array<Maybe<SimpleInput>>;
};

export type CompaniesUpdateOnlineOfflineBatch = {
  __typename?: 'CompaniesUpdateOnlineOfflineBatch';
  success?: Maybe<Scalars['Boolean']>;
};

export type CompaniesUpdateOnlineOfflineBatchInput = {
  id: Scalars['Int'];
  unavailable: Scalars['Boolean'];
};

export type Company = Node & {
  __typename?: 'Company';
  /** The ID of the object. */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  unavailable?: Maybe<Scalars['Boolean']>;
  brandId?: Maybe<Scalars['Int']>;
  documentId?: Maybe<Scalars['Int']>;
  addressId?: Maybe<Scalars['Int']>;
  corporateName?: Maybe<Scalars['String']>;
  menuId?: Maybe<Scalars['Int']>;
  isDigitalMenu?: Maybe<Scalars['Boolean']>;
  categoryId?: Maybe<Scalars['Int']>;
  isLgpdCompliance?: Maybe<Scalars['Boolean']>;
  isFullService?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  brand?: Maybe<Brand>;
  document?: Maybe<Document>;
  openingTimes?: Maybe<TimeRule>;
  address?: Maybe<Address>;
  category?: Maybe<CompanyCategory>;
  companyPhone?: Maybe<CompanyPhone>;
  menuProductCompany?: Maybe<MenuProductCompanyConnection>;
  productCompany?: Maybe<ProductCompanyConnection>;
  numericalId?: Maybe<Scalars['Int']>;
  onlineStatus?: Maybe<OnlineStatus>;
  highlight?: Maybe<CompanyHighlight>;
  images?: Maybe<Array<Maybe<Image>>>;
  parameter?: Maybe<Parameter>;
  preparationTime?: Maybe<PreparationTime>;
  aggregator?: Maybe<CompanyGroup>;
  shippingPrice?: Maybe<ShippingPrice>;
};

export type CompanyMenuProductCompanyArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CompanyProductCompanyArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CompanyShippingPriceArgs = {
  deliveryAddress: DeliveryAddressInput;
};

export type CompanyCategory = Node & {
  __typename?: 'CompanyCategory';
  /** The ID of the object. */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
};

export type CompanyConnection = {
  __typename?: 'CompanyConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompanyEdge>>;
};

export enum CompanyDemandStatus {
  Unknown = 'UNKNOWN',
  Normal = 'NORMAL',
  High = 'HIGH',
  Blocked = 'BLOCKED',
}

/** A Relay edge containing a `Company` and its cursor. */
export type CompanyEdge = {
  __typename?: 'CompanyEdge';
  /** The item at the end of the edge */
  node?: Maybe<Company>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CompanyGroup = Node & {
  __typename?: 'CompanyGroup';
  /** The ID of the object. */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  addressId?: Maybe<Scalars['Int']>;
  isShoppingCenter?: Maybe<Scalars['Boolean']>;
  isEvent?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Address>;
  numericalId?: Maybe<Scalars['Int']>;
  companies?: Maybe<CompanySharedConnection>;
  images?: Maybe<Array<Maybe<Image>>>;
};

export type CompanyGroupCompaniesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CompanyGroupConnection = {
  __typename?: 'CompanyGroupConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompanyGroupEdge>>;
};

/** A Relay edge containing a `CompanyGroup` and its cursor. */
export type CompanyGroupEdge = {
  __typename?: 'CompanyGroupEdge';
  /** The item at the end of the edge */
  node?: Maybe<CompanyGroup>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CompanyHighlight = {
  __typename?: 'CompanyHighlight';
  numericalId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  topLabel?: Maybe<Scalars['String']>;
  centerLabel?: Maybe<Scalars['String']>;
  bottomLabel?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
};

export type CompanyPhone = Node & {
  __typename?: 'CompanyPhone';
  /** The ID of the object. */
  id: Scalars['ID'];
  number: Scalars['String'];
};

export type CompanySelected = {
  __typename?: 'CompanySelected';
  companyId: Scalars['Int'];
  isDigitalMenu: Scalars['Boolean'];
};

export type CompanySharedConnection = {
  __typename?: 'CompanySharedConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompanySharedEdge>>;
};

/** A Relay edge containing a `CompanyShared` and its cursor. */
export type CompanySharedEdge = {
  __typename?: 'CompanySharedEdge';
  /** The item at the end of the edge */
  node?: Maybe<Company>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum CompanySortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  DisplayNameAsc = 'DISPLAY_NAME_ASC',
  DisplayNameDesc = 'DISPLAY_NAME_DESC',
  HiddenAsc = 'HIDDEN_ASC',
  HiddenDesc = 'HIDDEN_DESC',
  UnavailableAsc = 'UNAVAILABLE_ASC',
  UnavailableDesc = 'UNAVAILABLE_DESC',
  BrandIdAsc = 'BRAND_ID_ASC',
  BrandIdDesc = 'BRAND_ID_DESC',
  DocumentIdAsc = 'DOCUMENT_ID_ASC',
  DocumentIdDesc = 'DOCUMENT_ID_DESC',
  AddressIdAsc = 'ADDRESS_ID_ASC',
  AddressIdDesc = 'ADDRESS_ID_DESC',
  CorporateNameAsc = 'CORPORATE_NAME_ASC',
  CorporateNameDesc = 'CORPORATE_NAME_DESC',
  MenuIdAsc = 'MENU_ID_ASC',
  MenuIdDesc = 'MENU_ID_DESC',
  IsDigitalMenuAsc = 'IS_DIGITAL_MENU_ASC',
  IsDigitalMenuDesc = 'IS_DIGITAL_MENU_DESC',
  CategoryIdAsc = 'CATEGORY_ID_ASC',
  CategoryIdDesc = 'CATEGORY_ID_DESC',
  IsLgpdComplianceAsc = 'IS_LGPD_COMPLIANCE_ASC',
  IsLgpdComplianceDesc = 'IS_LGPD_COMPLIANCE_DESC',
  IsFullServiceAsc = 'IS_FULL_SERVICE_ASC',
  IsFullServiceDesc = 'IS_FULL_SERVICE_DESC',
}

export type CompanyWeekdayTimeRules = {
  __typename?: 'CompanyWeekdayTimeRules';
  success?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['String']>;
};

export type CompanyWeekdayTimeRulesBatch = {
  __typename?: 'CompanyWeekdayTimeRulesBatch';
  success?: Maybe<Scalars['Boolean']>;
};

export type CompanyWeekdayTimeRulesInput = {
  timeFrom: Scalars['Time'];
  timeTo: Scalars['Time'];
  numericalId?: Maybe<Scalars['Int']>;
  day: Scalars['Int'];
  operation: CompanyWeekdayTimeRulesOperation;
};

export enum CompanyWeekdayTimeRulesOperation {
  Update = 'UPDATE',
  Create = 'CREATE',
  Delete = 'DELETE',
}

export type CompanyWeekdayTimeRulesScheduleInput = {
  companyId: Scalars['Int'];
  schedule: Array<Maybe<TimeRulesScheduleInput>>;
};

export type CostumerDocument = Node & {
  __typename?: 'CostumerDocument';
  /** The ID of the object. */
  id: Scalars['ID'];
  customerId?: Maybe<Scalars['Int']>;
  documentId?: Maybe<Scalars['Int']>;
  customer?: Maybe<Customer>;
  document?: Maybe<Document>;
};

export type CostumerDocumentConnection = {
  __typename?: 'CostumerDocumentConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CostumerDocumentEdge>>;
};

/** A Relay edge containing a `CostumerDocument` and its cursor. */
export type CostumerDocumentEdge = {
  __typename?: 'CostumerDocumentEdge';
  /** The item at the end of the edge */
  node?: Maybe<CostumerDocument>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CreateAccountsUser = {
  __typename?: 'CreateAccountsUser';
  data?: Maybe<Customer>;
};

export type CreateMenuBatch = {
  __typename?: 'CreateMenuBatch';
  menuItems?: Maybe<Array<Maybe<ProductCompany>>>;
};

export type CroppedPictureInput = {
  cropHeight: Scalars['Int'];
  cropWidth: Scalars['Int'];
  cropX: Scalars['Int'];
  cropY: Scalars['Int'];
};

export type Customer = Node & {
  __typename?: 'Customer';
  /** The ID of the object. */
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  validationCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  preferencesToken?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userPtrId: Scalars['ID'];
  telephone?: Maybe<Scalars['String']>;
  validationCellphone?: Maybe<Scalars['String']>;
  facebookToken?: Maybe<Scalars['String']>;
  googleplusToken?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['Int']>;
  loginAttemptCount?: Maybe<Scalars['Int']>;
  accountsUser?: Maybe<AccountsUser>;
  card?: Maybe<CardConnection>;
  order?: Maybe<OrderConnection>;
  customerDocumentSet?: Maybe<Array<Maybe<CostumerDocument>>>;
  balance?: Maybe<BalanceConnection>;
  rewards?: Maybe<RewardConnection>;
};

export type CustomerCardArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CustomerOrderArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CustomerBalanceArgs = {
  brandId?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CustomerRewardsArgs = {
  brandId?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CustomerEngagementSignUp = {
  __typename?: 'CustomerEngagementSignUp';
  success?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['String']>;
};

export type DeleteBrandCroppedPicture = {
  __typename?: 'DeleteBrandCroppedPicture';
  error?: Maybe<Scalars['String']>;
  updatedBrand?: Maybe<Brand>;
};

export type DeleteCompanyCroppedPicture = {
  __typename?: 'DeleteCompanyCroppedPicture';
  error?: Maybe<Scalars['String']>;
  updatedCompany?: Maybe<Company>;
};

export type DeliveryAddressInput = {
  street: Scalars['String'];
  number: Scalars['String'];
  complement?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
  country: Scalars['String'];
  geoLat: Scalars['Float'];
  geoLon: Scalars['Float'];
  district: Scalars['String'];
};

export type DigitalMenuRegisterMutation = {
  __typename?: 'DigitalMenuRegisterMutation';
  token?: Maybe<Scalars['String']>;
};

export type Document = Node & {
  __typename?: 'Document';
  /** The ID of the object. */
  id: Scalars['ID'];
  documentType?: Maybe<DocumentType>;
  data?: Maybe<Scalars['String']>;
  expedition?: Maybe<Scalars['String']>;
  expires?: Maybe<Scalars['String']>;
  issuer?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  customerDocumentSet?: Maybe<CostumerDocumentConnection>;
};

export type DocumentCustomerDocumentSetArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DocumentType {
  Cpf = 'CPF',
  Cnpj = 'CNPJ',
  Identity = 'IDENTITY',
}

export type ExceptionTimeRule = Node & {
  __typename?: 'ExceptionTimeRule';
  /** The ID of the object. */
  id: Scalars['ID'];
  timeRuleId?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
  timeRule?: Maybe<TimeRule>;
};

export type ExceptionTimeRuleConnection = {
  __typename?: 'ExceptionTimeRuleConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ExceptionTimeRuleEdge>>;
};

/** A Relay edge containing a `ExceptionTimeRule` and its cursor. */
export type ExceptionTimeRuleEdge = {
  __typename?: 'ExceptionTimeRuleEdge';
  /** The item at the end of the edge */
  node?: Maybe<ExceptionTimeRule>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Image = {
  __typename?: 'Image';
  context?: Maybe<Scalars['String']>;
  cropHeight?: Maybe<Scalars['Int']>;
  cropId?: Maybe<Scalars['Int']>;
  cropWidth?: Maybe<Scalars['Int']>;
  cropX?: Maybe<Scalars['Int']>;
  cropY?: Maybe<Scalars['Int']>;
  croppedImg?: Maybe<Scalars['String']>;
  edited?: Maybe<Scalars['Boolean']>;
  file?: Maybe<Scalars['File']>;
  id?: Maybe<Scalars['String']>;
  idFromProduct?: Maybe<Scalars['Int']>;
  originalImageHeight?: Maybe<Scalars['Int']>;
  originalImageId?: Maybe<Scalars['Int']>;
  originalImageUrl?: Maybe<Scalars['String']>;
  originalImageWidth?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
};

export type LoginAuthInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  userType: AuthUserType;
};

export type LoginTokenAuth = {
  __typename?: 'LoginTokenAuth';
  accessToken?: Maybe<Scalars['String']>;
};

export type MenuCsv = {
  __typename?: 'MenuCSV';
  valid?: Maybe<Scalars['Boolean']>;
  data?: Maybe<Scalars['GenericScalar']>;
};

export type MenuCategory = Node & {
  __typename?: 'MenuCategory';
  /** The ID of the object. */
  id: Scalars['ID'];
  brandId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  deleted?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  backendCategoryId?: Maybe<Scalars['Int']>;
  publishStatus?: Maybe<MenuPublishStatus>;
  slug?: Maybe<Scalars['String']>;
  brand?: Maybe<Brand>;
  backendCategory?: Maybe<Category>;
  menuProduct?: Maybe<MenuProductConnection>;
  numericalId?: Maybe<Scalars['Int']>;
};

export type MenuCategoryMenuProductArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type MenuCategoryConnection = {
  __typename?: 'MenuCategoryConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MenuCategoryEdge>>;
};

/** A Relay edge containing a `MenuCategory` and its cursor. */
export type MenuCategoryEdge = {
  __typename?: 'MenuCategoryEdge';
  /** The item at the end of the edge */
  node?: Maybe<MenuCategory>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum MenuCategorySortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  BrandIdAsc = 'BRAND_ID_ASC',
  BrandIdDesc = 'BRAND_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  OrderAsc = 'ORDER_ASC',
  OrderDesc = 'ORDER_DESC',
  DeletedAsc = 'DELETED_ASC',
  DeletedDesc = 'DELETED_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  BackendCategoryIdAsc = 'BACKEND_CATEGORY_ID_ASC',
  BackendCategoryIdDesc = 'BACKEND_CATEGORY_ID_DESC',
  PublishStatusAsc = 'PUBLISH_STATUS_ASC',
  PublishStatusDesc = 'PUBLISH_STATUS_DESC',
}

export type MenuCreateOrUpdateItem = {
  __typename?: 'MenuCreateOrUpdateItem';
  menuCategory?: Maybe<MenuCategory>;
  menuProduct?: Maybe<MenuProduct>;
  menuProductCompany?: Maybe<MenuProductCompany>;
};

export type MenuCreateOrUpdateItemInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  category: Scalars['String'];
  productId?: Maybe<Scalars['Int']>;
  categoryId?: Maybe<Scalars['Int']>;
};

export type MenuDeleteItem = {
  __typename?: 'MenuDeleteItem';
  menuCategory?: Maybe<MenuCategory>;
  menuProduct?: Maybe<MenuProduct>;
  menuProductCompany?: Maybe<MenuProductCompany>;
};

export type MenuProduct = Node & {
  __typename?: 'MenuProduct';
  /** The ID of the object. */
  id: Scalars['ID'];
  brandId?: Maybe<Scalars['Int']>;
  categoryId?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  fullDescription?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['Int']>;
  hidden?: Maybe<Scalars['Boolean']>;
  deleted?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  backendProductId?: Maybe<Scalars['Int']>;
  publishStatus?: Maybe<MenuPublishStatus>;
  brand?: Maybe<Brand>;
  category?: Maybe<MenuCategory>;
  backendProduct?: Maybe<Product>;
  menuProductCompany?: Maybe<MenuProductCompanyConnection>;
  numericalId?: Maybe<Scalars['Int']>;
};

export type MenuProductMenuProductCompanyArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type MenuProductCompany = Node & {
  __typename?: 'MenuProductCompany';
  /** The ID of the object. */
  id: Scalars['ID'];
  productId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  hidden?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Float']>;
  unavailable?: Maybe<Scalars['Boolean']>;
  deleted?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  backendProductCompanyId?: Maybe<Scalars['Int']>;
  publishStatus?: Maybe<MenuPublishStatus>;
  product?: Maybe<MenuProduct>;
  company?: Maybe<Company>;
  backendProductCompany?: Maybe<ProductCompany>;
  numericalId?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type MenuProductCompanyConnection = {
  __typename?: 'MenuProductCompanyConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MenuProductCompanyEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `MenuProductCompany` and its cursor. */
export type MenuProductCompanyEdge = {
  __typename?: 'MenuProductCompanyEdge';
  /** The item at the end of the edge */
  node?: Maybe<MenuProductCompany>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum MenuProductCompanySortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ProductIdAsc = 'PRODUCT_ID_ASC',
  ProductIdDesc = 'PRODUCT_ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  HiddenAsc = 'HIDDEN_ASC',
  HiddenDesc = 'HIDDEN_DESC',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  UnavailableAsc = 'UNAVAILABLE_ASC',
  UnavailableDesc = 'UNAVAILABLE_DESC',
  DeletedAsc = 'DELETED_ASC',
  DeletedDesc = 'DELETED_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  BackendProductCompanyIdAsc = 'BACKEND_PRODUCT_COMPANY_ID_ASC',
  BackendProductCompanyIdDesc = 'BACKEND_PRODUCT_COMPANY_ID_DESC',
  PublishStatusAsc = 'PUBLISH_STATUS_ASC',
  PublishStatusDesc = 'PUBLISH_STATUS_DESC',
}

export type MenuProductConnection = {
  __typename?: 'MenuProductConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MenuProductEdge>>;
};

/** A Relay edge containing a `MenuProduct` and its cursor. */
export type MenuProductEdge = {
  __typename?: 'MenuProductEdge';
  /** The item at the end of the edge */
  node?: Maybe<MenuProduct>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type MenuPublish = {
  __typename?: 'MenuPublish';
  valid?: Maybe<Scalars['Boolean']>;
};

export enum MenuPublishStatus {
  Pending = 'PENDING',
  Publishing = 'PUBLISHING',
  Published = 'PUBLISHED',
}

export type MetabaseResourceUrl = {
  __typename?: 'MetabaseResourceUrl';
  url?: Maybe<Scalars['String']>;
};

export type MetabaseResourceUrlInput = {
  resourceName: Scalars['String'];
  resourceId: Scalars['Int'];
  companies?: Maybe<Scalars['String']>;
  brands?: Maybe<Scalars['String']>;
};

export type Mutations = {
  __typename?: 'Mutations';
  node?: Maybe<Node>;
  loginTokenAuth?: Maybe<LoginTokenAuth>;
  refreshAccessToken?: Maybe<RefreshAccessToken>;
  metabaseResourceUrl?: Maybe<MetabaseResourceUrl>;
  customerEngagementSignUp?: Maybe<CustomerEngagementSignUp>;
  updateBrandCroppedPicture?: Maybe<UpdateBrandCroppedPicture>;
  updateCompanyCroppedPicture?: Maybe<UpdateCompanyCroppedPicture>;
  deleteBrandCroppedPicture?: Maybe<DeleteBrandCroppedPicture>;
  deleteCompanyCroppedPicture?: Maybe<DeleteCompanyCroppedPicture>;
  saveBrandPictures?: Maybe<SaveBrandPictures>;
  saveCompanyPictures?: Maybe<SaveCompanyPictures>;
  companyWeekdayTimeRules?: Maybe<CompanyWeekdayTimeRules>;
  menuCsv?: Maybe<MenuCsv>;
  createAccountsUser?: Maybe<CreateAccountsUser>;
  menuPublish?: Maybe<MenuPublish>;
  selfJourneyRegister?: Maybe<DigitalMenuRegisterMutation>;
  menuCreateOrUpdateItem?: Maybe<MenuCreateOrUpdateItem>;
  menuDeleteItem?: Maybe<MenuDeleteItem>;
  backendMenuUpsertItem?: Maybe<BackendMenuUpsertItem>;
  backendMenuDeleteProduct?: Maybe<BackendMenuDeleteProduct>;
  resetAccountsPassword?: Maybe<ResetPassword>;
  backendMenuDeleteProductPictures?: Maybe<BackendMenuDeleteProductPictures>;
  backendSubproductRemoveItem?: Maybe<BackendSubproductRemoveItem>;
  companyWeekdayTimeRulesBatch?: Maybe<CompanyWeekdayTimeRulesBatch>;
  companiesUpdateOnlineOfflineBatch?: Maybe<CompaniesUpdateOnlineOfflineBatch>;
  createMenuBatch?: Maybe<CreateMenuBatch>;
  backendMenuReorderProduct?: Maybe<BackendMenuReorderProduct>;
};

export type MutationsNodeArgs = {
  id: Scalars['ID'];
};

export type MutationsLoginTokenAuthArgs = {
  input: LoginAuthInput;
};

export type MutationsMetabaseResourceUrlArgs = {
  input: MetabaseResourceUrlInput;
};

export type MutationsCustomerEngagementSignUpArgs = {
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
};

export type MutationsUpdateBrandCroppedPictureArgs = {
  brand: Scalars['Int'];
  id: Scalars['Int'];
  input: UpdateCroppedPictureInput;
};

export type MutationsUpdateCompanyCroppedPictureArgs = {
  company: Scalars['Int'];
  id: Scalars['Int'];
  input: UpdateCroppedPictureInput;
};

export type MutationsDeleteBrandCroppedPictureArgs = {
  brand: Scalars['Int'];
  id: Scalars['Int'];
};

export type MutationsDeleteCompanyCroppedPictureArgs = {
  company: Scalars['Int'];
  id: Scalars['Int'];
};

export type MutationsSaveBrandPicturesArgs = {
  brand: Scalars['Int'];
  files: Array<Maybe<Scalars['Upload']>>;
  input: Array<Maybe<SavePicturesInput>>;
};

export type MutationsSaveCompanyPicturesArgs = {
  company: Scalars['Int'];
  files: Array<Maybe<Scalars['Upload']>>;
  input: Array<Maybe<SavePicturesInput>>;
};

export type MutationsCompanyWeekdayTimeRulesArgs = {
  brandId: Scalars['Int'];
  companyId: Scalars['Int'];
  input: Array<Maybe<CompanyWeekdayTimeRulesInput>>;
};

export type MutationsMenuCsvArgs = {
  brandId: Scalars['Int'];
  companyId: Scalars['Int'];
  file: Scalars['Upload'];
};

export type MutationsCreateAccountsUserArgs = {
  cellphone?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
};

export type MutationsMenuPublishArgs = {
  companyId: Scalars['Int'];
};

export type MutationsSelfJourneyRegisterArgs = {
  selfJourneyData: SelfJourneyInput;
};

export type MutationsMenuCreateOrUpdateItemArgs = {
  brandId: Scalars['Int'];
  companyId: Scalars['Int'];
  menuCreateOrUpdateItemInput: MenuCreateOrUpdateItemInput;
};

export type MutationsMenuDeleteItemArgs = {
  brandId: Scalars['Int'];
  companyId: Scalars['Int'];
  menuCategoryId?: Maybe<Scalars['Int']>;
  menuProductId?: Maybe<Scalars['Int']>;
};

export type MutationsBackendMenuUpsertItemArgs = {
  backendMenuUpsertItemInput: BackendMenuUpsertItemInput;
  brandId: Scalars['Int'];
  companyId: Scalars['Int'];
  imageFile?: Maybe<Scalars['Upload']>;
  imageInput?: Maybe<CroppedPictureInput>;
};

export type MutationsBackendMenuDeleteProductArgs = {
  brandId: Scalars['Int'];
  companyId: Scalars['Int'];
  productCompanyId: Scalars['Int'];
};

export type MutationsResetAccountsPasswordArgs = {
  email: Scalars['String'];
};

export type MutationsBackendMenuDeleteProductPicturesArgs = {
  brandId: Scalars['Int'];
  companyId: Scalars['Int'];
  croppedPictureIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  productId: Scalars['Int'];
};

export type MutationsBackendSubproductRemoveItemArgs = {
  brandId: Scalars['Int'];
  companyId: Scalars['Int'];
  productId: Scalars['Int'];
  subproductId: Scalars['Int'];
};

export type MutationsCompanyWeekdayTimeRulesBatchArgs = {
  brandId: Scalars['Int'];
  schedules: Array<Maybe<CompanyWeekdayTimeRulesScheduleInput>>;
};

export type MutationsCompaniesUpdateOnlineOfflineBatchArgs = {
  brandId: Scalars['Int'];
  companiesItems: Array<Maybe<CompaniesUpdateOnlineOfflineBatchInput>>;
};

export type MutationsCreateMenuBatchArgs = {
  brandId: Scalars['Int'];
  companiesIds: Array<Maybe<Scalars['Int']>>;
  isOpenDeliveryIntegration?: Maybe<Scalars['Boolean']>;
  menu: Array<Maybe<ProductBatchInput>>;
};

export type MutationsBackendMenuReorderProductArgs = {
  brandId: Scalars['Int'];
  companyId: Scalars['Int'];
  productId: Scalars['Int'];
  sequence: Scalars['Int'];
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

export enum OnlineStatus {
  Online = 'ONLINE',
  Closed = 'CLOSED',
  Offline = 'OFFLINE',
}

export type Order = Node & {
  __typename?: 'Order';
  /** The ID of the object. */
  id: Scalars['ID'];
  companyId?: Maybe<Scalars['Int']>;
  ownerId?: Maybe<Scalars['Int']>;
  originBrandId?: Maybe<Scalars['Int']>;
  addressId?: Maybe<Scalars['Int']>;
  retryId?: Maybe<Scalars['Int']>;
  ownerUserAgentInfo?: Maybe<Scalars['String']>;
  orderType?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  posImportStatus?: Maybe<Scalars['Int']>;
  lastStatusReceivedMobile?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['Int']>;
  posStatus?: Maybe<Scalars['Int']>;
  posStatusDatetime?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<Scalars['Int']>;
  antifraudStatus?: Maybe<Scalars['Int']>;
  antifraudStatusDatetime?: Maybe<Scalars['DateTime']>;
  canceled?: Maybe<Scalars['Boolean']>;
  canceledDatetime?: Maybe<Scalars['DateTime']>;
  paymentAuthorizedDatetime?: Maybe<Scalars['DateTime']>;
  posReceivedDatetime?: Maybe<Scalars['DateTime']>;
  posAcceptedDatetime?: Maybe<Scalars['DateTime']>;
  posDeniedDatetime?: Maybe<Scalars['DateTime']>;
  readyDatetime?: Maybe<Scalars['DateTime']>;
  dispatchedDatetime?: Maybe<Scalars['DateTime']>;
  deliveredDatetime?: Maybe<Scalars['DateTime']>;
  receivedDatetime?: Maybe<Scalars['DateTime']>;
  consumedDatetime?: Maybe<Scalars['DateTime']>;
  posImportedDatetime?: Maybe<Scalars['DateTime']>;
  posImportErrorDatetime?: Maybe<Scalars['DateTime']>;
  preparingDatetime?: Maybe<Scalars['DateTime']>;
  posAnalysingDatetime?: Maybe<Scalars['DateTime']>;
  productUnavailableDatetime?: Maybe<Scalars['DateTime']>;
  customerActionNeededDatetime?: Maybe<Scalars['DateTime']>;
  printed?: Maybe<Scalars['Boolean']>;
  printedDatetime?: Maybe<Scalars['DateTime']>;
  creationDatetime?: Maybe<Scalars['DateTime']>;
  lastMobileUpdateDatetime?: Maybe<Scalars['DateTime']>;
  lastUpdateDatetime?: Maybe<Scalars['DateTime']>;
  prepareRightaway?: Maybe<Scalars['Boolean']>;
  ownedLoyaltyPoints?: Maybe<Scalars['Int']>;
  usedLoyaltyPoints?: Maybe<Scalars['Int']>;
  printId?: Maybe<Scalars['String']>;
  tableReference?: Maybe<Scalars['String']>;
  barCode?: Maybe<Scalars['String']>;
  displayCode?: Maybe<Scalars['String']>;
  receiptMessage?: Maybe<Scalars['String']>;
  totalValue?: Maybe<Scalars['Float']>;
  payableValue?: Maybe<Scalars['Float']>;
  discountPercentual?: Maybe<Scalars['Float']>;
  discountValue?: Maybe<Scalars['Float']>;
  subsidyValue?: Maybe<Scalars['Float']>;
  observation?: Maybe<Scalars['String']>;
  sequenceNumber?: Maybe<Scalars['String']>;
  taxPercentual?: Maybe<Scalars['Float']>;
  taxValue?: Maybe<Scalars['Float']>;
  serviceChargePercentual?: Maybe<Scalars['Float']>;
  serviceChargeValue?: Maybe<Scalars['Float']>;
  hasRedeem?: Maybe<Scalars['Boolean']>;
  preparationTimeMinimum?: Maybe<Scalars['Int']>;
  preparationTimeMaximum?: Maybe<Scalars['Int']>;
  isMock?: Maybe<Scalars['Boolean']>;
  received?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Company>;
  owner?: Maybe<Customer>;
  originBrand?: Maybe<Brand>;
  address?: Maybe<Address>;
  orderExtras?: Maybe<OrderExtraConnection>;
  items?: Maybe<Array<Maybe<OrderItem>>>;
  payments?: Maybe<Array<Maybe<Payment>>>;
  orderExtra?: Maybe<Array<Maybe<OrderExtra>>>;
};

export type OrderOrderExtrasArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type OrderConnection = {
  __typename?: 'OrderConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OrderEdge>>;
};

/** A Relay edge containing a `Order` and its cursor. */
export type OrderEdge = {
  __typename?: 'OrderEdge';
  /** The item at the end of the edge */
  node?: Maybe<Order>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type OrderExtra = Node & {
  __typename?: 'OrderExtra';
  /** The ID of the object. */
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  optional?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['Int']>;
  order?: Maybe<Order>;
};

export type OrderExtraConnection = {
  __typename?: 'OrderExtraConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OrderExtraEdge>>;
};

/** A Relay edge containing a `OrderExtra` and its cursor. */
export type OrderExtraEdge = {
  __typename?: 'OrderExtraEdge';
  /** The item at the end of the edge */
  node?: Maybe<OrderExtra>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type OrderField = Node & {
  __typename?: 'OrderField';
  /** The ID of the object. */
  id: Scalars['ID'];
  mapping?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  fieldType?: Maybe<OrderFieldType>;
  title?: Maybe<Scalars['String']>;
  requiredErrorMessage?: Maybe<Scalars['String']>;
  invalidErrorMessage?: Maybe<Scalars['String']>;
  hint?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  maxLength?: Maybe<Scalars['Int']>;
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  numericalId?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
};

export type OrderFieldParameter = Node & {
  __typename?: 'OrderFieldParameter';
  /** The ID of the object. */
  id: Scalars['ID'];
  orderTypeParameterId?: Maybe<Scalars['Int']>;
  fieldId?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  field?: Maybe<OrderField>;
};

export enum OrderFieldType {
  Text = 'TEXT',
  Numeric = 'NUMERIC',
  Telephone = 'TELEPHONE',
  Cpf = 'CPF',
  OptionList = 'OPTION_LIST',
}

export type OrderItem = Node & {
  __typename?: 'OrderItem';
  /** The ID of the object. */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  unitPosPrice?: Maybe<Scalars['Float']>;
  productType?: Maybe<ProductType>;
  registerDatetime?: Maybe<Scalars['DateTime']>;
  orderId?: Maybe<Scalars['Int']>;
  parentId?: Maybe<Scalars['Int']>;
  redeemId?: Maybe<Scalars['Int']>;
  posParentId?: Maybe<Scalars['Int']>;
  itemId?: Maybe<Scalars['Int']>;
  productCompanyItemId?: Maybe<Scalars['Int']>;
  billItemId?: Maybe<Scalars['JSONString']>;
  orderPosition?: Maybe<Scalars['Int']>;
  draggedBy?: Maybe<Scalars['Int']>;
  isPackage?: Maybe<Scalars['Boolean']>;
  combo?: Maybe<Scalars['Boolean']>;
  isNote?: Maybe<Scalars['Boolean']>;
  offer?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  posName?: Maybe<Scalars['String']>;
  posReference?: Maybe<Scalars['String']>;
  itemPosReference?: Maybe<Scalars['String']>;
  campaign?: Maybe<Scalars['String']>;
  observation?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  baseUnitPrice?: Maybe<Scalars['Float']>;
  baseUnitPosPrice?: Maybe<Scalars['Float']>;
  subsidy?: Maybe<Scalars['Float']>;
  unitPoints?: Maybe<Scalars['Int']>;
  partition?: Maybe<Scalars['Int']>;
  prepaidRewardPoints?: Maybe<Scalars['Int']>;
  numericalId?: Maybe<Scalars['Int']>;
};

export enum OrderStatusCode {
  Canceled = 'CANCELED',
  BackendReceived = 'BACKEND_RECEIVED',
  PaymentAuthorized = 'PAYMENT_AUTHORIZED',
  PosReceived = 'POS_RECEIVED',
  PosAccepted = 'POS_ACCEPTED',
  PosDenied = 'POS_DENIED',
  Ready = 'READY',
  Dispatched = 'DISPATCHED',
  Delivered = 'DELIVERED',
  Received = 'RECEIVED',
  Consumed = 'CONSUMED',
  PosImported = 'POS_IMPORTED',
  PosImportError = 'POS_IMPORT_ERROR',
  Preparing = 'PREPARING',
  PosAnalysing = 'POS_ANALYSING',
  ProductUnavailable = 'PRODUCT_UNAVAILABLE',
  CustomerActionNeeded = 'CUSTOMER_ACTION_NEEDED',
}

export enum OrderTypeFilterOptionEnum {
  Counter = 'COUNTER',
  ToGo = 'TO_GO',
  Curbside = 'CURBSIDE',
  Table = 'TABLE',
  Delivery = 'DELIVERY',
  Card = 'CARD',
  Payment = 'PAYMENT',
  Coupon = 'COUPON',
  RoomService = 'ROOM_SERVICE',
  PrepaidPoints = 'PREPAID_POINTS',
  TruckStop = 'TRUCK_STOP',
  Event = 'EVENT',
}

export type OrderTypeParameter = Node & {
  __typename?: 'OrderTypeParameter';
  /** The ID of the object. */
  id: Scalars['ID'];
  parameterId?: Maybe<Scalars['Int']>;
  additionalPreparationTime?: Maybe<Scalars['Int']>;
  orderType?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  numericalId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  additionalChargeParameter?: Maybe<Array<Maybe<AdditionalChargeParameter>>>;
  orderFields?: Maybe<Array<Maybe<OrderFieldParameter>>>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

export type Parameter = Node & {
  __typename?: 'Parameter';
  /** The ID of the object. */
  id: Scalars['ID'];
  companyGroupId?: Maybe<Scalars['Int']>;
  barcodeEnabled?: Maybe<Scalars['Boolean']>;
  mailOrderCancellation?: Maybe<Scalars['String']>;
  mailReplyTo?: Maybe<Scalars['String']>;
  productChangeDestination?: Maybe<Scalars['String']>;
  mailSenderName?: Maybe<Scalars['String']>;
  mailOrderConfirmation?: Maybe<Scalars['String']>;
  mockOrderEmail?: Maybe<Scalars['String']>;
  feedbackCommentsDestination?: Maybe<Scalars['String']>;
  orderDisplaycodeRule?: Maybe<Scalars['String']>;
  orderDisplaycodeNumber?: Maybe<Scalars['Int']>;
  redeemPointsAutomatic?: Maybe<Scalars['Boolean']>;
  allowRewardByProduct?: Maybe<Scalars['Boolean']>;
  allowRedeemNoChoosable?: Maybe<Scalars['Boolean']>;
  allowFirstPaymentInStore?: Maybe<Scalars['Boolean']>;
  rewardByCpf?: Maybe<Scalars['Boolean']>;
  rewardFeedbackListValue?: Maybe<Scalars['Int']>;
  orderActive?: Maybe<Scalars['Boolean']>;
  paymentActive?: Maybe<Scalars['Boolean']>;
  invitationListActive?: Maybe<Scalars['Boolean']>;
  posBillOnlineTimeout?: Maybe<Scalars['JSONString']>;
  posGeneralOnlineTimeout?: Maybe<Scalars['JSONString']>;
  posOrderOnlineTimeout?: Maybe<Scalars['JSONString']>;
  posTimeout?: Maybe<Scalars['Int']>;
  paymentIntegrationTimeout?: Maybe<Scalars['Int']>;
  productsCacheMaxAge?: Maybe<Scalars['JSONString']>;
  aggregatorCacheMaxAge?: Maybe<Scalars['JSONString']>;
  antifraudActive?: Maybe<Scalars['Boolean']>;
  allowCreditRewards?: Maybe<Scalars['Boolean']>;
  onyoAffiliationBalanceThreshold?: Maybe<Scalars['Float']>;
  onyoAffiliationEnabled?: Maybe<Scalars['Boolean']>;
  loyaltyActive?: Maybe<Scalars['Boolean']>;
  mobileCard?: Maybe<Scalars['JSONString']>;
  mobileClaim?: Maybe<Scalars['JSONString']>;
  mobileFeedback?: Maybe<Scalars['JSONString']>;
  mobileHome?: Maybe<Scalars['JSONString']>;
  mobileModules?: Maybe<Scalars['JSONString']>;
  mobileOrder?: Maybe<Scalars['JSONString']>;
  mobileTutorial?: Maybe<Scalars['JSONString']>;
  paymentParameter?: Maybe<Array<Maybe<PaymentParameter>>>;
  numericalId?: Maybe<Scalars['Int']>;
  orderTypes?: Maybe<Array<Maybe<OrderTypeParameter>>>;
  offlinePaymentParameter?: Maybe<Array<Maybe<PaymentParameter>>>;
};

export type Payment = Node & {
  __typename?: 'Payment';
  /** The ID of the object. */
  id: Scalars['ID'];
  value?: Maybe<Scalars['Float']>;
  method?: Maybe<PaymentMethodEnum>;
  card?: Maybe<Card>;
};

export enum PaymentMethodEnum {
  Card = 'CARD',
  Coupon = 'COUPON',
  Redeem = 'REDEEM',
  PrepaidRedeem = 'PREPAID_REDEEM',
  Wallet = 'WALLET',
  Offline = 'OFFLINE',
}

export type PaymentParameter = Node & {
  __typename?: 'PaymentParameter';
  /** The ID of the object. */
  id: Scalars['ID'];
  parameterId?: Maybe<Scalars['Int']>;
  cardBrandId?: Maybe<Scalars['Int']>;
  paymentMethod?: Maybe<Scalars['String']>;
  parameter?: Maybe<Parameter>;
  cardBrand?: Maybe<CardBrand>;
  requiresCpf?: Maybe<Scalars['Boolean']>;
};

export type Person = Customer | TemporaryUser;

export type PreparationTime = {
  __typename?: 'PreparationTime';
  minTime?: Maybe<Scalars['Int']>;
  maxTime?: Maybe<Scalars['Int']>;
  additionalTime?: Maybe<Scalars['Int']>;
  status?: Maybe<CompanyDemandStatus>;
};

export type Product = Node & {
  __typename?: 'Product';
  /** The ID of the object. */
  id: Scalars['ID'];
  brandId?: Maybe<Scalars['Int']>;
  categoryId?: Maybe<Scalars['Int']>;
  timeRuleId?: Maybe<Scalars['Int']>;
  images?: Maybe<Array<Maybe<Image>>>;
  sequence?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  fullDescription?: Maybe<Scalars['String']>;
  preparationTimeMinimum?: Maybe<Scalars['Int']>;
  preparationTimeMaximum?: Maybe<Scalars['Int']>;
  rewardable?: Maybe<Scalars['Boolean']>;
  redeemable?: Maybe<Scalars['Boolean']>;
  minimumChoices?: Maybe<Scalars['Int']>;
  maximumChoices?: Maybe<Scalars['Int']>;
  offer?: Maybe<Scalars['Boolean']>;
  productType?: Maybe<ProductType>;
  hidden?: Maybe<Scalars['Boolean']>;
  recommended?: Maybe<Scalars['Boolean']>;
  barcode?: Maybe<Scalars['String']>;
  feedbackable?: Maybe<Scalars['Boolean']>;
  posUpdated?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Scalars['Boolean']>;
  isNote?: Maybe<Scalars['Boolean']>;
  posName?: Maybe<Scalars['String']>;
  posReference?: Maybe<Scalars['String']>;
  operator?: Maybe<Scalars['Int']>;
  brand?: Maybe<Brand>;
  category?: Maybe<Category>;
  timeRule?: Maybe<TimeRule>;
  menuProduct?: Maybe<MenuProductConnection>;
  productCompany?: Maybe<ProductCompanyConnection>;
  numericalId?: Maybe<Scalars['Int']>;
  subproducts?: Maybe<Array<Maybe<Product>>>;
  productCompanyByCompanyId?: Maybe<ProductCompany>;
};

export type ProductMenuProductArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ProductProductCompanyArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ProductBatchInput = {
  productName: Scalars['String'];
  productPosName?: Maybe<Scalars['String']>;
  productPosReference?: Maybe<Scalars['String']>;
  productOperator: Scalars['String'];
  productSequence: Scalars['Int'];
  productCompanyPrice?: Maybe<Scalars['Float']>;
  productCompanyPosPrice?: Maybe<Scalars['Float']>;
  productShortDescription?: Maybe<Scalars['String']>;
  productFullDescription?: Maybe<Scalars['String']>;
  productCategoryId?: Maybe<Scalars['Int']>;
  productCategoryName?: Maybe<Scalars['String']>;
  productImageUrl?: Maybe<Scalars['String']>;
  choosables?: Maybe<Array<Maybe<ChoosableInput>>>;
};

export type ProductCompany = Node & {
  __typename?: 'ProductCompany';
  /** The ID of the object. */
  id: Scalars['ID'];
  productId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
  posPrice?: Maybe<Scalars['Float']>;
  subsidy?: Maybe<Scalars['Float']>;
  redeemPoints?: Maybe<Scalars['Int']>;
  rewardPoints?: Maybe<Scalars['Int']>;
  hidden?: Maybe<Scalars['Boolean']>;
  unavailable?: Maybe<Scalars['Boolean']>;
  isMenuException?: Maybe<Scalars['Boolean']>;
  path: Scalars['JSONString'];
  product?: Maybe<Product>;
  company?: Maybe<Company>;
  menuProductCompany?: Maybe<MenuProductCompanyConnection>;
  numericalId?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ProductCompanyMenuProductCompanyArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ProductCompanyConnection = {
  __typename?: 'ProductCompanyConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProductCompanyEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `ProductCompany` and its cursor. */
export type ProductCompanyEdge = {
  __typename?: 'ProductCompanyEdge';
  /** The item at the end of the edge */
  node?: Maybe<ProductCompany>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type ProductConnection = {
  __typename?: 'ProductConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProductEdge>>;
};

/** A Relay edge containing a `Product` and its cursor. */
export type ProductEdge = {
  __typename?: 'ProductEdge';
  /** The item at the end of the edge */
  node?: Maybe<Product>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum ProductSortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  BrandIdAsc = 'BRAND_ID_ASC',
  BrandIdDesc = 'BRAND_ID_DESC',
  CategoryIdAsc = 'CATEGORY_ID_ASC',
  CategoryIdDesc = 'CATEGORY_ID_DESC',
  TimeRuleIdAsc = 'TIME_RULE_ID_ASC',
  TimeRuleIdDesc = 'TIME_RULE_ID_DESC',
  ImagesAsc = 'IMAGES_ASC',
  ImagesDesc = 'IMAGES_DESC',
  SequenceAsc = 'SEQUENCE_ASC',
  SequenceDesc = 'SEQUENCE_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  ShortDescriptionAsc = 'SHORT_DESCRIPTION_ASC',
  ShortDescriptionDesc = 'SHORT_DESCRIPTION_DESC',
  FullDescriptionAsc = 'FULL_DESCRIPTION_ASC',
  FullDescriptionDesc = 'FULL_DESCRIPTION_DESC',
  PreparationTimeMinimumAsc = 'PREPARATION_TIME_MINIMUM_ASC',
  PreparationTimeMinimumDesc = 'PREPARATION_TIME_MINIMUM_DESC',
  PreparationTimeMaximumAsc = 'PREPARATION_TIME_MAXIMUM_ASC',
  PreparationTimeMaximumDesc = 'PREPARATION_TIME_MAXIMUM_DESC',
  RewardableAsc = 'REWARDABLE_ASC',
  RewardableDesc = 'REWARDABLE_DESC',
  RedeemableAsc = 'REDEEMABLE_ASC',
  RedeemableDesc = 'REDEEMABLE_DESC',
  MinimumChoicesAsc = 'MINIMUM_CHOICES_ASC',
  MinimumChoicesDesc = 'MINIMUM_CHOICES_DESC',
  MaximumChoicesAsc = 'MAXIMUM_CHOICES_ASC',
  MaximumChoicesDesc = 'MAXIMUM_CHOICES_DESC',
  OfferAsc = 'OFFER_ASC',
  OfferDesc = 'OFFER_DESC',
  ProductTypeAsc = 'PRODUCT_TYPE_ASC',
  ProductTypeDesc = 'PRODUCT_TYPE_DESC',
  HiddenAsc = 'HIDDEN_ASC',
  HiddenDesc = 'HIDDEN_DESC',
  RecommendedAsc = 'RECOMMENDED_ASC',
  RecommendedDesc = 'RECOMMENDED_DESC',
  BarcodeAsc = 'BARCODE_ASC',
  BarcodeDesc = 'BARCODE_DESC',
  FeedbackableAsc = 'FEEDBACKABLE_ASC',
  FeedbackableDesc = 'FEEDBACKABLE_DESC',
  PosUpdatedAsc = 'POS_UPDATED_ASC',
  PosUpdatedDesc = 'POS_UPDATED_DESC',
  GroupAsc = 'GROUP_ASC',
  GroupDesc = 'GROUP_DESC',
  IsNoteAsc = 'IS_NOTE_ASC',
  IsNoteDesc = 'IS_NOTE_DESC',
  PosNameAsc = 'POS_NAME_ASC',
  PosNameDesc = 'POS_NAME_DESC',
  PosReferenceAsc = 'POS_REFERENCE_ASC',
  PosReferenceDesc = 'POS_REFERENCE_DESC',
  OperatorAsc = 'OPERATOR_ASC',
  OperatorDesc = 'OPERATOR_DESC',
}

export enum ProductType {
  Simple = 'SIMPLE',
  Choosable = 'CHOOSABLE',
  MenuItem = 'MENU_ITEM',
}

export type Promotion = Node & {
  __typename?: 'Promotion';
  /** The ID of the object. */
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  discountOnyo?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  restrict?: Maybe<Scalars['Boolean']>;
  singleUse?: Maybe<Scalars['Boolean']>;
  uniqueUse?: Maybe<Scalars['Boolean']>;
  applyToAllBrandCompanies?: Maybe<Scalars['Boolean']>;
  applyToAllCompaniesProducts?: Maybe<Scalars['Boolean']>;
  brandId?: Maybe<Scalars['Int']>;
  topLabel?: Maybe<Scalars['String']>;
  centerLabel?: Maybe<Scalars['String']>;
  bottomLabel?: Maybe<Scalars['String']>;
  brand?: Maybe<Brand>;
  title?: Maybe<Scalars['String']>;
  companies?: Maybe<CompanySharedConnection>;
};

export type PromotionCompaniesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PromotionConnection = {
  __typename?: 'PromotionConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PromotionEdge>>;
};

/** A Relay edge containing a `Promotion` and its cursor. */
export type PromotionEdge = {
  __typename?: 'PromotionEdge';
  /** The item at the end of the edge */
  node?: Maybe<Promotion>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  accountsCachedData?: Maybe<AccountsCachedData>;
  addressCachedData?: Maybe<AddressCachedData>;
  addressLatLongCachedData?: Maybe<AddressLatLongCachedData>;
  aggregator?: Maybe<CompanyGroup>;
  aggregators?: Maybe<CompanyGroupConnection>;
  allOrdersByDays?: Maybe<OrderConnection>;
  authExecuted: Scalars['Boolean'];
  brandSelected?: Maybe<Scalars['Int']>;
  brandTheme?: Maybe<BrandTheme>;
  brands?: Maybe<Array<Maybe<Brand>>>;
  businessCachedData?: Maybe<BusinessCachedData>;
  cachedImages?: Maybe<Array<Maybe<CachedImage>>>;
  company?: Maybe<Company>;
  companySelected?: Maybe<CompanySelected>;
  crmUser?: Maybe<AccountsUser>;
  defaultBrandTheme?: Maybe<BrandTheme>;
  isAuthenticated: Scalars['Boolean'];
  isGmapsReady: Scalars['Boolean'];
  lastOrders?: Maybe<OrderConnection>;
  listBrands?: Maybe<BrandConnection>;
  listCategories?: Maybe<CategoryConnection>;
  listCompanies?: Maybe<CompanyConnection>;
  listMenuCategories?: Maybe<MenuCategoryConnection>;
  listMenuCompanyProducts?: Maybe<MenuProductCompanyConnection>;
  listProductDetails?: Maybe<Array<Maybe<Product>>>;
  listProductsByBrand?: Maybe<ProductConnection>;
  logout?: Maybe<Scalars['Boolean']>;
  me?: Maybe<Person>;
  menuProductCompanyById?: Maybe<MenuProductCompany>;
  menuPublishState?: Maybe<MenuPublishStatus>;
  mobileParameter?: Maybe<Parameter>;
  node?: Maybe<Node>;
  order?: Maybe<Order>;
  productCompanies?: Maybe<ProductCompanyConnection>;
  productCompanyById?: Maybe<ProductCompany>;
  productDetails?: Maybe<Product>;
  userListPromotions?: Maybe<PromotionConnection>;
};

export type QueryAggregatorArgs = {
  companyGroupId?: Maybe<Scalars['Int']>;
  aggregatorId: Scalars['Int'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  maximumDistanceKilometers?: Maybe<Scalars['Float']>;
  filterBy?: Maybe<AggregatorFilterOption>;
  orderType?: Maybe<Array<Maybe<OrderTypeFilterOptionEnum>>>;
  companyNameStartsWith?: Maybe<Scalars['String']>;
};

export type QueryAggregatorsArgs = {
  companyGroupId?: Maybe<Scalars['Int']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  maximumDistanceKilometers: Scalars['Float'];
  filterBy?: Maybe<AggregatorFilterOption>;
  companyNameStartsWith?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryAllOrdersByDaysArgs = {
  days: Scalars['Int'];
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryBrandThemeArgs = {
  companyId?: Maybe<Scalars['Int']>;
  brandId?: Maybe<Scalars['Int']>;
};

export type QueryBrandsArgs = {
  ids: Array<Maybe<Scalars['Int']>>;
};

export type QueryCompanyArgs = {
  companyId: Scalars['Int'];
  showHiddenCompany?: Maybe<Scalars['Boolean']>;
};

export type QueryLastOrdersArgs = {
  fromDatetime: Scalars['DateTime'];
  toDatetime?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Array<Maybe<OrderStatusCode>>>;
  brand?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryListBrandsArgs = {
  sort?: Maybe<Array<Maybe<BrandSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryListCategoriesArgs = {
  brandId: Scalars['Int'];
  sort?: Maybe<Array<Maybe<CategorySortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryListCompaniesArgs = {
  sort?: Maybe<Array<Maybe<CompanySortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryListMenuCategoriesArgs = {
  brandId: Scalars['Int'];
  sort?: Maybe<Array<Maybe<MenuCategorySortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryListMenuCompanyProductsArgs = {
  companyId: Scalars['Int'];
  productNameStartsWith?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<Maybe<MenuProductCompanySortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryListProductDetailsArgs = {
  productIds: Array<Maybe<Scalars['Int']>>;
  companyId: Scalars['Int'];
};

export type QueryListProductsByBrandArgs = {
  brandId: Scalars['Int'];
  productType?: Maybe<ProductType>;
  sort?: Maybe<Array<Maybe<ProductSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryMenuProductCompanyByIdArgs = {
  id: Scalars['Int'];
  companyId: Scalars['Int'];
};

export type QueryMenuPublishStateArgs = {
  companyId: Scalars['Int'];
};

export type QueryMobileParameterArgs = {
  companyId: Scalars['Int'];
};

export type QueryNodeArgs = {
  id: Scalars['ID'];
};

export type QueryOrderArgs = {
  id: Scalars['Int'];
};

export type QueryProductCompaniesArgs = {
  companyId: Scalars['Int'];
  productType?: Maybe<ProductType>;
  productHidden?: Maybe<Scalars['Boolean']>;
  unavailable?: Maybe<Scalars['Boolean']>;
  productNameStartsWith?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryProductCompanyByIdArgs = {
  companyId: Scalars['Int'];
  id: Scalars['Int'];
};

export type QueryProductDetailsArgs = {
  productId: Scalars['Int'];
  companyId: Scalars['Int'];
};

export type QueryUserListPromotionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type RefreshAccessToken = {
  __typename?: 'RefreshAccessToken';
  accessToken?: Maybe<Scalars['String']>;
};

export type ResetPassword = {
  __typename?: 'ResetPassword';
  success?: Maybe<Scalars['Boolean']>;
};

export type Reward = Node & {
  __typename?: 'Reward';
  /** The ID of the object. */
  id: Scalars['ID'];
  customerId?: Maybe<Scalars['Int']>;
  temporaryUserId?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  brandId?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
  datetime?: Maybe<Scalars['DateTime']>;
  expiration?: Maybe<Scalars['DateTime']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  availablePoints?: Maybe<Scalars['Int']>;
  source?: Maybe<Scalars['String']>;
  temporaryUser?: Maybe<TemporaryUser>;
  brand?: Maybe<Brand>;
};

export type RewardConnection = {
  __typename?: 'RewardConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RewardEdge>>;
};

/** A Relay edge containing a `Reward` and its cursor. */
export type RewardEdge = {
  __typename?: 'RewardEdge';
  /** The item at the end of the edge */
  node?: Maybe<Reward>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type SaveBrandPictures = {
  __typename?: 'SaveBrandPictures';
  error?: Maybe<Scalars['String']>;
  updatedBrand?: Maybe<Brand>;
};

export type SaveCompanyPictures = {
  __typename?: 'SaveCompanyPictures';
  error?: Maybe<Scalars['String']>;
  updatedCompany?: Maybe<Company>;
};

export type SavePicturesInput = {
  cropHeight: Scalars['Int'];
  cropWidth: Scalars['Int'];
  cropX: Scalars['Int'];
  cropY: Scalars['Int'];
  context: Scalars['String'];
  cropId?: Maybe<Scalars['Int']>;
};

export type SelfJourneyInput = {
  accounts: AccountsInput;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  corporateName: Scalars['String'];
  brandName: Scalars['String'];
  cnpj: Scalars['String'];
  telephone: Scalars['String'];
  address: AddressInput;
  brandImageInput?: Maybe<SavePicturesInput>;
  brandImageFile?: Maybe<Scalars['Upload']>;
};

export type ShippingPrice = {
  __typename?: 'ShippingPrice';
  value?: Maybe<Scalars['Float']>;
  label?: Maybe<Scalars['String']>;
};

export type SimpleInput = {
  productName: Scalars['String'];
  productPosName?: Maybe<Scalars['String']>;
  productPosReference?: Maybe<Scalars['String']>;
  productOperator: Scalars['String'];
  productSequence: Scalars['Int'];
  productCompanyPrice?: Maybe<Scalars['Float']>;
  productCompanyPosPrice?: Maybe<Scalars['Float']>;
  productShortDescription?: Maybe<Scalars['String']>;
  productFullDescription?: Maybe<Scalars['String']>;
  productCategoryId?: Maybe<Scalars['Int']>;
  productCategoryName?: Maybe<Scalars['String']>;
  productImageUrl?: Maybe<Scalars['String']>;
  productMinimumChoices: Scalars['Int'];
  productMaximumChoices: Scalars['Int'];
};

export type TemporaryUser = Node & {
  __typename?: 'TemporaryUser';
  /** The ID of the object. */
  id: Scalars['ID'];
  couponId?: Maybe<Scalars['Int']>;
  token?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  validationCode?: Maybe<Scalars['String']>;
  creationDatetime?: Maybe<Scalars['DateTime']>;
  validated?: Maybe<Scalars['Boolean']>;
  balance?: Maybe<BalanceConnection>;
  rewards?: Maybe<RewardConnection>;
};

export type TemporaryUserBalanceArgs = {
  brandId?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type TemporaryUserRewardsArgs = {
  brandId?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type TimeRule = Node & {
  __typename?: 'TimeRule';
  /** The ID of the object. */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  brandId?: Maybe<Scalars['Int']>;
  brand?: Maybe<Brand>;
  calendarTimeRule?: Maybe<CalendarTimeRuleConnection>;
  company?: Maybe<Company>;
  exceptionTimeRule?: Maybe<ExceptionTimeRuleConnection>;
  products?: Maybe<ProductConnection>;
  weekdayTimeRule?: Maybe<WeekedayTimeRuleConnection>;
};

export type TimeRuleCalendarTimeRuleArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type TimeRuleExceptionTimeRuleArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type TimeRuleProductsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type TimeRuleWeekdayTimeRuleArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type TimeRuleConnection = {
  __typename?: 'TimeRuleConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TimeRuleEdge>>;
};

/** A Relay edge containing a `TimeRule` and its cursor. */
export type TimeRuleEdge = {
  __typename?: 'TimeRuleEdge';
  /** The item at the end of the edge */
  node?: Maybe<TimeRule>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type TimeRulesScheduleInput = {
  timeFrom: Scalars['Time'];
  timeTo: Scalars['Time'];
  numericalId?: Maybe<Scalars['Int']>;
  day: Scalars['Int'];
};

export type UpdateBrandCroppedPicture = {
  __typename?: 'UpdateBrandCroppedPicture';
  error?: Maybe<Scalars['String']>;
  updatedBrand?: Maybe<Brand>;
};

export type UpdateCompanyCroppedPicture = {
  __typename?: 'UpdateCompanyCroppedPicture';
  error?: Maybe<Scalars['String']>;
  updatedCompany?: Maybe<Company>;
};

export type UpdateCroppedPictureInput = {
  cropHeight: Scalars['Int'];
  cropWidth: Scalars['Int'];
  cropX: Scalars['Int'];
  cropY: Scalars['Int'];
  picture: Scalars['String'];
  target: Scalars['String'];
};

export type User = Node & {
  __typename?: 'User';
  /** The ID of the object. */
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  validationCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  preferencesToken?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  dateJoined?: Maybe<Scalars['DateTime']>;
  isSuperuser?: Maybe<Scalars['Boolean']>;
  isStaff?: Maybe<Scalars['Boolean']>;
  accountsUser?: Maybe<AccountsUser>;
  customer?: Maybe<Customer>;
};

export type WeekedayTimeRule = Node & {
  __typename?: 'WeekedayTimeRule';
  /** The ID of the object. */
  id: Scalars['ID'];
  timeRuleId?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
  timeFrom?: Maybe<Scalars['String']>;
  timeTo?: Maybe<Scalars['String']>;
  timeRule?: Maybe<TimeRule>;
  numericalId?: Maybe<Scalars['Int']>;
};

export type WeekedayTimeRuleConnection = {
  __typename?: 'WeekedayTimeRuleConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<WeekedayTimeRuleEdge>>;
};

/** A Relay edge containing a `WeekedayTimeRule` and its cursor. */
export type WeekedayTimeRuleEdge = {
  __typename?: 'WeekedayTimeRuleEdge';
  /** The item at the end of the edge */
  node?: Maybe<WeekedayTimeRule>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type LogoutQueryVariables = Exact<{ [key: string]: never }>;

export type LogoutQuery = { __typename?: 'Query' } & Pick<Query, 'logout'>;

export type GetAllowedBrandsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllowedBrandsQuery = { __typename?: 'Query' } & {
  crmUser?: Maybe<
    { __typename?: 'AccountsUser' } & {
      allowedBrands?: Maybe<
        Array<Maybe<{ __typename?: 'Brand' } & Pick<Brand, 'name' | 'numericalId'>>>
      >;
    }
  >;
};

export type GetAllowedCompaniesByBrandQueryVariables = Exact<{
  brandId: Scalars['Int'];
}>;

export type GetAllowedCompaniesByBrandQuery = { __typename?: 'Query' } & {
  crmUser?: Maybe<
    { __typename?: 'AccountsUser' } & {
      allowedCompaniesByBrand?: Maybe<
        Array<
          Maybe<
            { __typename?: 'Company' } & Pick<Company, 'name' | 'numericalId' | 'isDigitalMenu'>
          >
        >
      >;
    }
  >;
};

export type GetAllowedCompaniesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllowedCompaniesQuery = { __typename?: 'Query' } & {
  crmUser?: Maybe<
    { __typename?: 'AccountsUser' } & {
      allowedCompanies?: Maybe<
        Array<Maybe<{ __typename?: 'Company' } & Pick<Company, 'name' | 'numericalId'>>>
      >;
    }
  >;
};

export type GetCachedBrandSelectedQueryVariables = Exact<{ [key: string]: never }>;

export type GetCachedBrandSelectedQuery = { __typename?: 'Query' } & Pick<Query, 'brandSelected'>;

export type GetCachedCompanySelectedQueryVariables = Exact<{ [key: string]: never }>;

export type GetCachedCompanySelectedQuery = { __typename?: 'Query' } & {
  companySelected?: Maybe<
    { __typename?: 'CompanySelected' } & Pick<CompanySelected, 'companyId' | 'isDigitalMenu'>
  >;
};

export type GetCachedImagesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCachedImagesQuery = { __typename?: 'Query' } & {
  cachedImages?: Maybe<
    Array<
      Maybe<
        { __typename?: 'CachedImage' } & Pick<
          CachedImage,
          | 'url'
          | 'context'
          | 'originalImageUrl'
          | 'originalImageId'
          | 'cropId'
          | 'cropX'
          | 'cropY'
          | 'cropWidth'
          | 'cropHeight'
          | 'originalImageWidth'
          | 'originalImageHeight'
          | 'file'
        >
      >
    >
  >;
};

export type GetIsGmapsReadyQueryVariables = Exact<{ [key: string]: never }>;

export type GetIsGmapsReadyQuery = { __typename?: 'Query' } & Pick<Query, 'isGmapsReady'>;

export type GetUserInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserInfoQuery = { __typename?: 'Query' } & {
  crmUser?: Maybe<
    { __typename?: 'AccountsUser' } & Pick<AccountsUser, 'userId'> & {
        user?: Maybe<
          { __typename?: 'User' } & Pick<User, 'firstName' | 'lastName' | 'email'> & {
              customer?: Maybe<
                { __typename?: 'Customer' } & Pick<
                  Customer,
                  'telephone' | 'validationCellphone'
                > & {
                    customerDocumentSet?: Maybe<
                      Array<
                        Maybe<
                          { __typename?: 'CostumerDocument' } & {
                            document?: Maybe<
                              { __typename?: 'Document' } & Pick<Document, 'data' | 'documentType'>
                            >;
                          }
                        >
                      >
                    >;
                  }
              >;
            }
        >;
      }
  >;
};

export type GetAccountsCachedDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetAccountsCachedDataQuery = { __typename?: 'Query' } & {
  accountsCachedData?: Maybe<
    { __typename?: 'AccountsCachedData' } & Pick<
      AccountsCachedData,
      'cellphone' | 'cpf' | 'email' | 'firstName' | 'lastName' | 'password'
    >
  >;
};

export type GetAddressCachedDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetAddressCachedDataQuery = { __typename?: 'Query' } & {
  addressCachedData?: Maybe<
    { __typename?: 'AddressCachedData' } & Pick<
      AddressCachedData,
      'street' | 'number' | 'zipCode' | 'state' | 'complement' | 'city'
    >
  >;
};

export type GetLatLongCachedDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetLatLongCachedDataQuery = { __typename?: 'Query' } & {
  addressLatLongCachedData?: Maybe<
    { __typename?: 'AddressLatLongCachedData' } & Pick<
      AddressLatLongCachedData,
      'geoLat' | 'geoLon'
    >
  >;
};

export type DeleteBrandCroppedPictureMutationVariables = Exact<{
  id: Scalars['Int'];
  brand: Scalars['Int'];
}>;

export type DeleteBrandCroppedPictureMutation = { __typename?: 'Mutations' } & {
  deleteBrandCroppedPicture?: Maybe<
    { __typename?: 'DeleteBrandCroppedPicture' } & {
      updatedBrand?: Maybe<
        { __typename?: 'Brand' } & {
          images?: Maybe<
            Array<
              Maybe<
                { __typename?: 'Image' } & Pick<
                  Image,
                  | 'url'
                  | 'context'
                  | 'originalImageUrl'
                  | 'originalImageId'
                  | 'cropId'
                  | 'cropX'
                  | 'cropY'
                  | 'cropWidth'
                  | 'cropHeight'
                  | 'originalImageWidth'
                  | 'originalImageHeight'
                >
              >
            >
          >;
        }
      >;
    }
  >;
};

export type GetBrandImagesQueryVariables = Exact<{
  ids: Array<Maybe<Scalars['Int']>>;
}>;

export type GetBrandImagesQuery = { __typename?: 'Query' } & {
  brands?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Brand' } & {
          images?: Maybe<
            Array<
              Maybe<
                { __typename?: 'Image' } & Pick<
                  Image,
                  | 'url'
                  | 'context'
                  | 'originalImageUrl'
                  | 'originalImageId'
                  | 'cropId'
                  | 'cropX'
                  | 'cropY'
                  | 'cropWidth'
                  | 'cropHeight'
                  | 'originalImageWidth'
                  | 'originalImageHeight'
                >
              >
            >
          >;
        }
      >
    >
  >;
};

export type SaveBrandPicturesMutationVariables = Exact<{
  files: Array<Maybe<Scalars['Upload']>>;
  input: Array<Maybe<SavePicturesInput>>;
  brand: Scalars['Int'];
}>;

export type SaveBrandPicturesMutation = { __typename?: 'Mutations' } & {
  saveBrandPictures?: Maybe<
    { __typename?: 'SaveBrandPictures' } & {
      updatedBrand?: Maybe<
        { __typename?: 'Brand' } & {
          images?: Maybe<
            Array<
              Maybe<
                { __typename?: 'Image' } & Pick<
                  Image,
                  | 'url'
                  | 'context'
                  | 'originalImageUrl'
                  | 'originalImageId'
                  | 'cropId'
                  | 'cropX'
                  | 'cropY'
                  | 'cropWidth'
                  | 'cropHeight'
                  | 'originalImageWidth'
                  | 'originalImageHeight'
                >
              >
            >
          >;
        }
      >;
    }
  >;
};

export type UpdateBrandCroppedPictureMutationVariables = Exact<{
  id: Scalars['Int'];
  input: UpdateCroppedPictureInput;
  brand: Scalars['Int'];
}>;

export type UpdateBrandCroppedPictureMutation = { __typename?: 'Mutations' } & {
  updateBrandCroppedPicture?: Maybe<
    { __typename?: 'UpdateBrandCroppedPicture' } & {
      updatedBrand?: Maybe<
        { __typename?: 'Brand' } & {
          images?: Maybe<
            Array<
              Maybe<
                { __typename?: 'Image' } & Pick<
                  Image,
                  | 'url'
                  | 'context'
                  | 'originalImageUrl'
                  | 'originalImageId'
                  | 'cropId'
                  | 'cropX'
                  | 'cropY'
                  | 'cropWidth'
                  | 'cropHeight'
                  | 'originalImageWidth'
                  | 'originalImageHeight'
                >
              >
            >
          >;
        }
      >;
    }
  >;
};

export type GetBusinessCachedDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetBusinessCachedDataQuery = { __typename?: 'Query' } & {
  businessCachedData?: Maybe<
    { __typename?: 'BusinessCachedData' } & Pick<
      BusinessCachedData,
      | 'brandName'
      | 'name'
      | 'displayName'
      | 'corporateName'
      | 'cnpj'
      | 'telephone'
      | 'fileUrl'
      | 'brandImageFile'
    > & {
        brandImageInput?: Maybe<
          { __typename?: 'BrandImageInput' } & Pick<
            BrandImageInput,
            'cropHeight' | 'cropWidth' | 'cropX' | 'cropY' | 'context' | 'cropId'
          >
        >;
      }
  >;
};

export type DeleteCompanyCroppedPictureMutationVariables = Exact<{
  id: Scalars['Int'];
  company: Scalars['Int'];
}>;

export type DeleteCompanyCroppedPictureMutation = { __typename?: 'Mutations' } & {
  deleteCompanyCroppedPicture?: Maybe<
    { __typename?: 'DeleteCompanyCroppedPicture' } & {
      updatedCompany?: Maybe<
        { __typename?: 'Company' } & {
          images?: Maybe<
            Array<
              Maybe<
                { __typename?: 'Image' } & Pick<
                  Image,
                  | 'url'
                  | 'context'
                  | 'originalImageUrl'
                  | 'originalImageId'
                  | 'cropId'
                  | 'cropX'
                  | 'cropY'
                  | 'cropWidth'
                  | 'cropHeight'
                  | 'originalImageWidth'
                  | 'originalImageHeight'
                >
              >
            >
          >;
        }
      >;
    }
  >;
};

export type GetCompanyImagesQueryVariables = Exact<{
  companyId: Scalars['Int'];
  showHiddenCompany?: Maybe<Scalars['Boolean']>;
}>;

export type GetCompanyImagesQuery = { __typename?: 'Query' } & {
  company?: Maybe<
    { __typename?: 'Company' } & {
      images?: Maybe<
        Array<
          Maybe<
            { __typename?: 'Image' } & Pick<
              Image,
              | 'url'
              | 'context'
              | 'originalImageUrl'
              | 'originalImageId'
              | 'cropId'
              | 'cropX'
              | 'cropY'
              | 'cropWidth'
              | 'cropHeight'
              | 'originalImageWidth'
              | 'originalImageHeight'
            >
          >
        >
      >;
    }
  >;
};

export type SaveCompanyPicturesMutationVariables = Exact<{
  files: Array<Maybe<Scalars['Upload']>>;
  input: Array<Maybe<SavePicturesInput>>;
  company: Scalars['Int'];
}>;

export type SaveCompanyPicturesMutation = { __typename?: 'Mutations' } & {
  saveCompanyPictures?: Maybe<
    { __typename?: 'SaveCompanyPictures' } & {
      updatedCompany?: Maybe<
        { __typename?: 'Company' } & {
          images?: Maybe<
            Array<
              Maybe<
                { __typename?: 'Image' } & Pick<
                  Image,
                  | 'url'
                  | 'context'
                  | 'originalImageUrl'
                  | 'originalImageId'
                  | 'cropId'
                  | 'cropX'
                  | 'cropY'
                  | 'cropWidth'
                  | 'cropHeight'
                  | 'originalImageWidth'
                  | 'originalImageHeight'
                >
              >
            >
          >;
        }
      >;
    }
  >;
};

export type UpdateCompanyCroppedPictureMutationVariables = Exact<{
  id: Scalars['Int'];
  input: UpdateCroppedPictureInput;
  company: Scalars['Int'];
}>;

export type UpdateCompanyCroppedPictureMutation = { __typename?: 'Mutations' } & {
  updateCompanyCroppedPicture?: Maybe<
    { __typename?: 'UpdateCompanyCroppedPicture' } & {
      updatedCompany?: Maybe<
        { __typename?: 'Company' } & {
          images?: Maybe<
            Array<
              Maybe<
                { __typename?: 'Image' } & Pick<
                  Image,
                  | 'url'
                  | 'context'
                  | 'originalImageUrl'
                  | 'originalImageId'
                  | 'cropId'
                  | 'cropX'
                  | 'cropY'
                  | 'cropWidth'
                  | 'cropHeight'
                  | 'originalImageWidth'
                  | 'originalImageHeight'
                >
              >
            >
          >;
        }
      >;
    }
  >;
};

export type SelfJourneyMutationVariables = Exact<{
  selfJourneyData: SelfJourneyInput;
}>;

export type SelfJourneyMutation = { __typename?: 'Mutations' } & {
  selfJourneyRegister?: Maybe<
    { __typename?: 'DigitalMenuRegisterMutation' } & Pick<DigitalMenuRegisterMutation, 'token'>
  >;
};

export type LoginMutationVariables = Exact<{
  input: LoginAuthInput;
}>;

export type LoginMutation = { __typename?: 'Mutations' } & {
  loginTokenAuth?: Maybe<{ __typename?: 'LoginTokenAuth' } & Pick<LoginTokenAuth, 'accessToken'>>;
};

export type ResetAccountsPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type ResetAccountsPasswordMutation = { __typename?: 'Mutations' } & {
  resetAccountsPassword?: Maybe<{ __typename?: 'ResetPassword' } & Pick<ResetPassword, 'success'>>;
};

export type ProductFragment = { __typename?: 'Product' } & Pick<
  Product,
  'numericalId' | 'name' | 'productType' | 'minimumChoices' | 'maximumChoices' | 'hidden'
> & {
    productCompanyByCompanyId?: Maybe<
      { __typename?: 'ProductCompany' } & Pick<ProductCompany, 'price' | 'unavailable' | 'hidden'>
    >;
  };

export type BackendMenuDeleteProductPicturesMutationVariables = Exact<{
  brandId: Scalars['Int'];
  companyId: Scalars['Int'];
  croppedPictureIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  productId: Scalars['Int'];
}>;

export type BackendMenuDeleteProductPicturesMutation = { __typename?: 'Mutations' } & {
  backendMenuDeleteProductPictures?: Maybe<
    { __typename?: 'BackendMenuDeleteProductPictures' } & Pick<
      BackendMenuDeleteProductPictures,
      'success'
    >
  >;
};

export type BackendMenuDeleteProductMutationVariables = Exact<{
  brandId: Scalars['Int'];
  companyId: Scalars['Int'];
  productCompanyId: Scalars['Int'];
}>;

export type BackendMenuDeleteProductMutation = { __typename?: 'Mutations' } & {
  backendMenuDeleteProduct?: Maybe<
    { __typename?: 'BackendMenuDeleteProduct' } & Pick<BackendMenuDeleteProduct, 'success'>
  >;
};

export type BackendMenuDeleteSubproductMutationVariables = Exact<{
  brandId: Scalars['Int'];
  companyId: Scalars['Int'];
  productId: Scalars['Int'];
  subproductId: Scalars['Int'];
}>;

export type BackendMenuDeleteSubproductMutation = { __typename?: 'Mutations' } & {
  backendSubproductRemoveItem?: Maybe<
    { __typename?: 'BackendSubproductRemoveItem' } & Pick<BackendSubproductRemoveItem, 'success'>
  >;
};

export type BackendMenuReorderProductMutationVariables = Exact<{
  brandId: Scalars['Int'];
  companyId: Scalars['Int'];
  productId: Scalars['Int'];
  sequence: Scalars['Int'];
}>;

export type BackendMenuReorderProductMutation = { __typename?: 'Mutations' } & {
  backendMenuReorderProduct?: Maybe<
    { __typename?: 'BackendMenuReorderProduct' } & Pick<BackendMenuReorderProduct, 'success'>
  >;
};

export type BackendMenuUpsertItemMutationVariables = Exact<{
  brandId: Scalars['Int'];
  companyId: Scalars['Int'];
  backendMenuUpsertItemInput: BackendMenuUpsertItemInput;
  imageFile?: Maybe<Scalars['Upload']>;
  imageInput?: Maybe<CroppedPictureInput>;
}>;

export type BackendMenuUpsertItemMutation = { __typename?: 'Mutations' } & {
  backendMenuUpsertItem?: Maybe<
    { __typename?: 'BackendMenuUpsertItem' } & {
      product?: Maybe<{ __typename?: 'Product' } & Pick<Product, 'id' | 'numericalId'>>;
    }
  >;
};

export type DigitalMenuUpsertItemMutationVariables = Exact<{
  brandId: Scalars['Int'];
  companyId: Scalars['Int'];
  menuCreateOrUpdateItemInput: MenuCreateOrUpdateItemInput;
}>;

export type DigitalMenuUpsertItemMutation = { __typename?: 'Mutations' } & {
  menuCreateOrUpdateItem?: Maybe<
    { __typename?: 'MenuCreateOrUpdateItem' } & {
      menuProduct?: Maybe<{ __typename?: 'MenuProduct' } & Pick<MenuProduct, 'id'>>;
    }
  >;
};

export type SaveMenuCsvMutationVariables = Exact<{
  file: Scalars['Upload'];
  brandId: Scalars['Int'];
  companyId: Scalars['Int'];
}>;

export type SaveMenuCsvMutation = { __typename?: 'Mutations' } & {
  menuCsv?: Maybe<{ __typename?: 'MenuCSV' } & Pick<MenuCsv, 'valid' | 'data'>>;
};

export type MenuDeleteItemMutationVariables = Exact<{
  brandId: Scalars['Int'];
  companyId: Scalars['Int'];
  menuCategoryId?: Maybe<Scalars['Int']>;
  menuProductId?: Maybe<Scalars['Int']>;
}>;

export type MenuDeleteItemMutation = { __typename?: 'Mutations' } & {
  menuDeleteItem?: Maybe<
    { __typename?: 'MenuDeleteItem' } & {
      menuProduct?: Maybe<{ __typename?: 'MenuProduct' } & Pick<MenuProduct, 'id'>>;
    }
  >;
};

export type PublishMenuMutationVariables = Exact<{
  companyId: Scalars['Int'];
}>;

export type PublishMenuMutation = { __typename?: 'Mutations' } & {
  menuPublish?: Maybe<{ __typename?: 'MenuPublish' } & Pick<MenuPublish, 'valid'>>;
};

export type GetAllowedBrandsAndCompaniesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllowedBrandsAndCompaniesQuery = { __typename?: 'Query' } & {
  crmUser?: Maybe<
    { __typename?: 'AccountsUser' } & Pick<AccountsUser, 'userId'> & {
        allowedBrands?: Maybe<
          Array<
            Maybe<
              { __typename?: 'Brand' } & Pick<Brand, 'name' | 'numericalId'> & {
                  company?: Maybe<
                    { __typename?: 'CompanySharedConnection' } & {
                      edges: Array<
                        Maybe<
                          { __typename?: 'CompanySharedEdge' } & {
                            node?: Maybe<
                              { __typename?: 'Company' } & Pick<
                                Company,
                                'name' | 'numericalId' | 'isDigitalMenu'
                              >
                            >;
                          }
                        >
                      >;
                    }
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type GetBackendCompanyProductItemQueryVariables = Exact<{
  id: Scalars['Int'];
  companyId: Scalars['Int'];
}>;

export type GetBackendCompanyProductItemQuery = { __typename?: 'Query' } & {
  productCompanyById?: Maybe<
    { __typename?: 'ProductCompany' } & Pick<ProductCompany, 'price'> & {
        product?: Maybe<
          { __typename?: 'Product' } & Pick<
            Product,
            'name' | 'numericalId' | 'shortDescription'
          > & {
              images?: Maybe<
                Array<Maybe<{ __typename?: 'Image' } & Pick<Image, 'context' | 'url' | 'cropId'>>>
              >;
              category?: Maybe<{ __typename?: 'Category' } & Pick<Category, 'name'>>;
              subproducts?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'Product' } & {
                      subproducts?: Maybe<
                        Array<Maybe<{ __typename?: 'Product' } & ProductFragment>>
                      >;
                    } & ProductFragment
                  >
                >
              >;
            }
        >;
      }
  >;
};

export type GetMenuCompanyInfoQueryVariables = Exact<{
  companyId: Scalars['Int'];
  showHiddenCompany?: Maybe<Scalars['Boolean']>;
}>;

export type GetMenuCompanyInfoQuery = { __typename?: 'Query' } & {
  company?: Maybe<
    { __typename?: 'Company' } & Pick<Company, 'name'> & {
        brand?: Maybe<
          { __typename?: 'Brand' } & {
            images?: Maybe<Array<Maybe<{ __typename?: 'Image' } & Pick<Image, 'url' | 'context'>>>>;
          }
        >;
        aggregator?: Maybe<{ __typename?: 'CompanyGroup' } & Pick<CompanyGroup, 'numericalId'>>;
      }
  >;
};

export type GetMenuCompanyProductItemQueryVariables = Exact<{
  id: Scalars['Int'];
  companyId: Scalars['Int'];
}>;

export type GetMenuCompanyProductItemQuery = { __typename?: 'Query' } & {
  menuProductCompanyById?: Maybe<
    { __typename?: 'MenuProductCompany' } & Pick<MenuProductCompany, 'price'> & {
        product?: Maybe<
          { __typename?: 'MenuProduct' } & Pick<
            MenuProduct,
            'name' | 'numericalId' | 'shortDescription'
          > & { category?: Maybe<{ __typename?: 'MenuCategory' } & Pick<MenuCategory, 'name'>> }
        >;
      }
  >;
};

export type GetMenuPublishStateQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;

export type GetMenuPublishStateQuery = { __typename?: 'Query' } & Pick<Query, 'menuPublishState'>;

export type GetListBackendMenuCategoriesQueryVariables = Exact<{
  brandId: Scalars['Int'];
}>;

export type GetListBackendMenuCategoriesQuery = { __typename?: 'Query' } & {
  listCategories?: Maybe<
    { __typename?: 'CategoryConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'CategoryEdge' } & {
            node?: Maybe<
              { __typename?: 'Category' } & Pick<Category, 'name' | 'order' | 'numericalId'>
            >;
          }
        >
      >;
    }
  >;
};

export type GetCompanyProductsQueryVariables = Exact<{
  companyId: Scalars['Int'];
  first?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  productType?: Maybe<ProductType>;
  productHidden?: Maybe<Scalars['Boolean']>;
  unavailable?: Maybe<Scalars['Boolean']>;
  productNameStartsWith?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['Int']>;
}>;

export type GetCompanyProductsQuery = { __typename?: 'Query' } & {
  productCompanies?: Maybe<
    { __typename?: 'ProductCompanyConnection' } & Pick<ProductCompanyConnection, 'totalCount'> & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'startCursor' | 'endCursor'>;
        edges: Array<
          Maybe<
            { __typename?: 'ProductCompanyEdge' } & {
              node?: Maybe<
                { __typename?: 'ProductCompany' } & Pick<
                  ProductCompany,
                  'price' | 'numericalId'
                > & {
                    product?: Maybe<
                      { __typename?: 'Product' } & Pick<
                        Product,
                        'name' | 'numericalId' | 'sequence'
                      > & {
                          category?: Maybe<
                            { __typename?: 'Category' } & Pick<Category, 'name' | 'numericalId'>
                          >;
                          images?: Maybe<
                            Array<Maybe<{ __typename?: 'Image' } & Pick<Image, 'context' | 'url'>>>
                          >;
                        }
                    >;
                  }
              >;
            }
          >
        >;
      }
  >;
};

export type GetDigitalMenuCompanyProductsQueryVariables = Exact<{
  companyId: Scalars['Int'];
  first?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  productNameStartsWith?: Maybe<Scalars['String']>;
}>;

export type GetDigitalMenuCompanyProductsQuery = { __typename?: 'Query' } & {
  listMenuCompanyProducts?: Maybe<
    { __typename?: 'MenuProductCompanyConnection' } & Pick<
      MenuProductCompanyConnection,
      'totalCount'
    > & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'startCursor' | 'endCursor'>;
        edges: Array<
          Maybe<
            { __typename?: 'MenuProductCompanyEdge' } & {
              node?: Maybe<
                { __typename?: 'MenuProductCompany' } & Pick<
                  MenuProductCompany,
                  'price' | 'numericalId'
                > & {
                    product?: Maybe<
                      { __typename?: 'MenuProduct' } & Pick<
                        MenuProduct,
                        'name' | 'numericalId' | 'updatedAt'
                      > & {
                          category?: Maybe<
                            { __typename?: 'MenuCategory' } & Pick<MenuCategory, 'name'>
                          >;
                        }
                    >;
                  }
              >;
            }
          >
        >;
      }
  >;
};

export type GetListMenuCategoriesQueryVariables = Exact<{
  brandId: Scalars['Int'];
  first?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
}>;

export type GetListMenuCategoriesQuery = { __typename?: 'Query' } & {
  listMenuCategories?: Maybe<
    { __typename?: 'MenuCategoryConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'MenuCategoryEdge' } & {
            node?: Maybe<
              { __typename?: 'MenuCategory' } & Pick<MenuCategory, 'name' | 'numericalId'>
            >;
          }
        >
      >;
    }
  >;
};

export type TimeRuleFragment = { __typename: 'TimeRule' } & Pick<TimeRule, 'id'> & {
    weekdayTimeRule?: Maybe<
      { __typename?: 'WeekedayTimeRuleConnection' } & {
        edges: Array<
          Maybe<
            { __typename?: 'WeekedayTimeRuleEdge' } & {
              node?: Maybe<
                { __typename?: 'WeekedayTimeRule' } & Pick<
                  WeekedayTimeRule,
                  'numericalId' | 'day' | 'timeFrom' | 'timeTo' | 'timeRuleId'
                >
              >;
            }
          >
        >;
      }
    >;
  };

export type CompanyWeekdayTimeRulesMutationVariables = Exact<{
  brandId: Scalars['Int'];
  companyId: Scalars['Int'];
  input: Array<Maybe<CompanyWeekdayTimeRulesInput>>;
}>;

export type CompanyWeekdayTimeRulesMutation = { __typename?: 'Mutations' } & {
  companyWeekdayTimeRules?: Maybe<
    { __typename?: 'CompanyWeekdayTimeRules' } & Pick<CompanyWeekdayTimeRules, 'error'>
  >;
};

export type GetCompanyTimeRulesQueryVariables = Exact<{
  companyId: Scalars['Int'];
  showHiddenCompany?: Maybe<Scalars['Boolean']>;
}>;

export type GetCompanyTimeRulesQuery = { __typename?: 'Query' } & {
  company?: Maybe<
    { __typename?: 'Company' } & Pick<Company, 'brandId'> & {
        openingTimes?: Maybe<{ __typename?: 'TimeRule' } & TimeRuleFragment>;
      }
  >;
};

export const ProductFragmentDoc = gql`
  fragment product on Product {
    numericalId
    name
    productType
    minimumChoices
    maximumChoices
    hidden
    productCompanyByCompanyId {
      price
      unavailable
      hidden
    }
  }
`;
export const TimeRuleFragmentDoc = gql`
  fragment timeRule on TimeRule {
    id
    __typename
    weekdayTimeRule {
      edges {
        node {
          numericalId
          day
          timeFrom
          timeTo
          timeRuleId
        }
      }
    }
  }
`;
export const LogoutDocument = gql`
  query Logout {
    logout @client
  }
`;
export type LogoutComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<LogoutQuery, LogoutQueryVariables>,
  'query'
>;

export const LogoutComponent = (props: LogoutComponentProps) => (
  <ApolloReactComponents.Query<LogoutQuery, LogoutQueryVariables>
    query={LogoutDocument}
    {...props}
  />
);

export type LogoutProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<LogoutQuery, LogoutQueryVariables>;
} & TChildProps;
export function withLogout<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    LogoutQuery,
    LogoutQueryVariables,
    LogoutProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    LogoutQuery,
    LogoutQueryVariables,
    LogoutProps<TChildProps, TDataName>
  >(LogoutDocument, {
    alias: 'logout',
    ...operationOptions,
  });
}

/**
 * __useLogoutQuery__
 *
 * To run a query within a React component, call `useLogoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogoutQuery({
 *   variables: {
 *   },
 * });
 */
export function useLogoutQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<LogoutQuery, LogoutQueryVariables>
) {
  return ApolloReactHooks.useQuery<LogoutQuery, LogoutQueryVariables>(LogoutDocument, baseOptions);
}
export function useLogoutLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LogoutQuery, LogoutQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<LogoutQuery, LogoutQueryVariables>(
    LogoutDocument,
    baseOptions
  );
}
export type LogoutQueryHookResult = ReturnType<typeof useLogoutQuery>;
export type LogoutLazyQueryHookResult = ReturnType<typeof useLogoutLazyQuery>;
export type LogoutQueryResult = ApolloReactCommon.QueryResult<LogoutQuery, LogoutQueryVariables>;
export const GetAllowedBrandsDocument = gql`
  query GetAllowedBrands {
    crmUser {
      allowedBrands {
        name
        numericalId
      }
    }
  }
`;
export type GetAllowedBrandsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetAllowedBrandsQuery,
    GetAllowedBrandsQueryVariables
  >,
  'query'
>;

export const GetAllowedBrandsComponent = (props: GetAllowedBrandsComponentProps) => (
  <ApolloReactComponents.Query<GetAllowedBrandsQuery, GetAllowedBrandsQueryVariables>
    query={GetAllowedBrandsDocument}
    {...props}
  />
);

export type GetAllowedBrandsProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetAllowedBrandsQuery,
    GetAllowedBrandsQueryVariables
  >;
} & TChildProps;
export function withGetAllowedBrands<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetAllowedBrandsQuery,
    GetAllowedBrandsQueryVariables,
    GetAllowedBrandsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetAllowedBrandsQuery,
    GetAllowedBrandsQueryVariables,
    GetAllowedBrandsProps<TChildProps, TDataName>
  >(GetAllowedBrandsDocument, {
    alias: 'getAllowedBrands',
    ...operationOptions,
  });
}

/**
 * __useGetAllowedBrandsQuery__
 *
 * To run a query within a React component, call `useGetAllowedBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllowedBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllowedBrandsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllowedBrandsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAllowedBrandsQuery,
    GetAllowedBrandsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetAllowedBrandsQuery, GetAllowedBrandsQueryVariables>(
    GetAllowedBrandsDocument,
    baseOptions
  );
}
export function useGetAllowedBrandsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAllowedBrandsQuery,
    GetAllowedBrandsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetAllowedBrandsQuery, GetAllowedBrandsQueryVariables>(
    GetAllowedBrandsDocument,
    baseOptions
  );
}
export type GetAllowedBrandsQueryHookResult = ReturnType<typeof useGetAllowedBrandsQuery>;
export type GetAllowedBrandsLazyQueryHookResult = ReturnType<typeof useGetAllowedBrandsLazyQuery>;
export type GetAllowedBrandsQueryResult = ApolloReactCommon.QueryResult<
  GetAllowedBrandsQuery,
  GetAllowedBrandsQueryVariables
>;
export const GetAllowedCompaniesByBrandDocument = gql`
  query GetAllowedCompaniesByBrand($brandId: Int!) {
    crmUser {
      allowedCompaniesByBrand(brandId: $brandId) {
        name
        numericalId
        isDigitalMenu
      }
    }
  }
`;
export type GetAllowedCompaniesByBrandComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetAllowedCompaniesByBrandQuery,
    GetAllowedCompaniesByBrandQueryVariables
  >,
  'query'
> &
  ({ variables: GetAllowedCompaniesByBrandQueryVariables; skip?: boolean } | { skip: boolean });

export const GetAllowedCompaniesByBrandComponent = (
  props: GetAllowedCompaniesByBrandComponentProps
) => (
  <ApolloReactComponents.Query<
    GetAllowedCompaniesByBrandQuery,
    GetAllowedCompaniesByBrandQueryVariables
  >
    query={GetAllowedCompaniesByBrandDocument}
    {...props}
  />
);

export type GetAllowedCompaniesByBrandProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetAllowedCompaniesByBrandQuery,
    GetAllowedCompaniesByBrandQueryVariables
  >;
} & TChildProps;
export function withGetAllowedCompaniesByBrand<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetAllowedCompaniesByBrandQuery,
    GetAllowedCompaniesByBrandQueryVariables,
    GetAllowedCompaniesByBrandProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetAllowedCompaniesByBrandQuery,
    GetAllowedCompaniesByBrandQueryVariables,
    GetAllowedCompaniesByBrandProps<TChildProps, TDataName>
  >(GetAllowedCompaniesByBrandDocument, {
    alias: 'getAllowedCompaniesByBrand',
    ...operationOptions,
  });
}

/**
 * __useGetAllowedCompaniesByBrandQuery__
 *
 * To run a query within a React component, call `useGetAllowedCompaniesByBrandQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllowedCompaniesByBrandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllowedCompaniesByBrandQuery({
 *   variables: {
 *      brandId: // value for 'brandId'
 *   },
 * });
 */
export function useGetAllowedCompaniesByBrandQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAllowedCompaniesByBrandQuery,
    GetAllowedCompaniesByBrandQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetAllowedCompaniesByBrandQuery,
    GetAllowedCompaniesByBrandQueryVariables
  >(GetAllowedCompaniesByBrandDocument, baseOptions);
}
export function useGetAllowedCompaniesByBrandLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAllowedCompaniesByBrandQuery,
    GetAllowedCompaniesByBrandQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetAllowedCompaniesByBrandQuery,
    GetAllowedCompaniesByBrandQueryVariables
  >(GetAllowedCompaniesByBrandDocument, baseOptions);
}
export type GetAllowedCompaniesByBrandQueryHookResult = ReturnType<
  typeof useGetAllowedCompaniesByBrandQuery
>;
export type GetAllowedCompaniesByBrandLazyQueryHookResult = ReturnType<
  typeof useGetAllowedCompaniesByBrandLazyQuery
>;
export type GetAllowedCompaniesByBrandQueryResult = ApolloReactCommon.QueryResult<
  GetAllowedCompaniesByBrandQuery,
  GetAllowedCompaniesByBrandQueryVariables
>;
export const GetAllowedCompaniesDocument = gql`
  query GetAllowedCompanies {
    crmUser {
      allowedCompanies {
        name
        numericalId
      }
    }
  }
`;
export type GetAllowedCompaniesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetAllowedCompaniesQuery,
    GetAllowedCompaniesQueryVariables
  >,
  'query'
>;

export const GetAllowedCompaniesComponent = (props: GetAllowedCompaniesComponentProps) => (
  <ApolloReactComponents.Query<GetAllowedCompaniesQuery, GetAllowedCompaniesQueryVariables>
    query={GetAllowedCompaniesDocument}
    {...props}
  />
);

export type GetAllowedCompaniesProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetAllowedCompaniesQuery,
    GetAllowedCompaniesQueryVariables
  >;
} & TChildProps;
export function withGetAllowedCompanies<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetAllowedCompaniesQuery,
    GetAllowedCompaniesQueryVariables,
    GetAllowedCompaniesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetAllowedCompaniesQuery,
    GetAllowedCompaniesQueryVariables,
    GetAllowedCompaniesProps<TChildProps, TDataName>
  >(GetAllowedCompaniesDocument, {
    alias: 'getAllowedCompanies',
    ...operationOptions,
  });
}

/**
 * __useGetAllowedCompaniesQuery__
 *
 * To run a query within a React component, call `useGetAllowedCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllowedCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllowedCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllowedCompaniesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAllowedCompaniesQuery,
    GetAllowedCompaniesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetAllowedCompaniesQuery, GetAllowedCompaniesQueryVariables>(
    GetAllowedCompaniesDocument,
    baseOptions
  );
}
export function useGetAllowedCompaniesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAllowedCompaniesQuery,
    GetAllowedCompaniesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetAllowedCompaniesQuery, GetAllowedCompaniesQueryVariables>(
    GetAllowedCompaniesDocument,
    baseOptions
  );
}
export type GetAllowedCompaniesQueryHookResult = ReturnType<typeof useGetAllowedCompaniesQuery>;
export type GetAllowedCompaniesLazyQueryHookResult = ReturnType<
  typeof useGetAllowedCompaniesLazyQuery
>;
export type GetAllowedCompaniesQueryResult = ApolloReactCommon.QueryResult<
  GetAllowedCompaniesQuery,
  GetAllowedCompaniesQueryVariables
>;
export const GetCachedBrandSelectedDocument = gql`
  query GetCachedBrandSelected {
    brandSelected @client
  }
`;
export type GetCachedBrandSelectedComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetCachedBrandSelectedQuery,
    GetCachedBrandSelectedQueryVariables
  >,
  'query'
>;

export const GetCachedBrandSelectedComponent = (props: GetCachedBrandSelectedComponentProps) => (
  <ApolloReactComponents.Query<GetCachedBrandSelectedQuery, GetCachedBrandSelectedQueryVariables>
    query={GetCachedBrandSelectedDocument}
    {...props}
  />
);

export type GetCachedBrandSelectedProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetCachedBrandSelectedQuery,
    GetCachedBrandSelectedQueryVariables
  >;
} & TChildProps;
export function withGetCachedBrandSelected<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetCachedBrandSelectedQuery,
    GetCachedBrandSelectedQueryVariables,
    GetCachedBrandSelectedProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetCachedBrandSelectedQuery,
    GetCachedBrandSelectedQueryVariables,
    GetCachedBrandSelectedProps<TChildProps, TDataName>
  >(GetCachedBrandSelectedDocument, {
    alias: 'getCachedBrandSelected',
    ...operationOptions,
  });
}

/**
 * __useGetCachedBrandSelectedQuery__
 *
 * To run a query within a React component, call `useGetCachedBrandSelectedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCachedBrandSelectedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCachedBrandSelectedQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCachedBrandSelectedQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCachedBrandSelectedQuery,
    GetCachedBrandSelectedQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetCachedBrandSelectedQuery,
    GetCachedBrandSelectedQueryVariables
  >(GetCachedBrandSelectedDocument, baseOptions);
}
export function useGetCachedBrandSelectedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCachedBrandSelectedQuery,
    GetCachedBrandSelectedQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetCachedBrandSelectedQuery,
    GetCachedBrandSelectedQueryVariables
  >(GetCachedBrandSelectedDocument, baseOptions);
}
export type GetCachedBrandSelectedQueryHookResult = ReturnType<
  typeof useGetCachedBrandSelectedQuery
>;
export type GetCachedBrandSelectedLazyQueryHookResult = ReturnType<
  typeof useGetCachedBrandSelectedLazyQuery
>;
export type GetCachedBrandSelectedQueryResult = ApolloReactCommon.QueryResult<
  GetCachedBrandSelectedQuery,
  GetCachedBrandSelectedQueryVariables
>;
export const GetCachedCompanySelectedDocument = gql`
  query GetCachedCompanySelected {
    companySelected @client {
      companyId
      isDigitalMenu
    }
  }
`;
export type GetCachedCompanySelectedComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetCachedCompanySelectedQuery,
    GetCachedCompanySelectedQueryVariables
  >,
  'query'
>;

export const GetCachedCompanySelectedComponent = (
  props: GetCachedCompanySelectedComponentProps
) => (
  <ApolloReactComponents.Query<
    GetCachedCompanySelectedQuery,
    GetCachedCompanySelectedQueryVariables
  >
    query={GetCachedCompanySelectedDocument}
    {...props}
  />
);

export type GetCachedCompanySelectedProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetCachedCompanySelectedQuery,
    GetCachedCompanySelectedQueryVariables
  >;
} & TChildProps;
export function withGetCachedCompanySelected<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetCachedCompanySelectedQuery,
    GetCachedCompanySelectedQueryVariables,
    GetCachedCompanySelectedProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetCachedCompanySelectedQuery,
    GetCachedCompanySelectedQueryVariables,
    GetCachedCompanySelectedProps<TChildProps, TDataName>
  >(GetCachedCompanySelectedDocument, {
    alias: 'getCachedCompanySelected',
    ...operationOptions,
  });
}

/**
 * __useGetCachedCompanySelectedQuery__
 *
 * To run a query within a React component, call `useGetCachedCompanySelectedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCachedCompanySelectedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCachedCompanySelectedQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCachedCompanySelectedQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCachedCompanySelectedQuery,
    GetCachedCompanySelectedQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetCachedCompanySelectedQuery,
    GetCachedCompanySelectedQueryVariables
  >(GetCachedCompanySelectedDocument, baseOptions);
}
export function useGetCachedCompanySelectedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCachedCompanySelectedQuery,
    GetCachedCompanySelectedQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetCachedCompanySelectedQuery,
    GetCachedCompanySelectedQueryVariables
  >(GetCachedCompanySelectedDocument, baseOptions);
}
export type GetCachedCompanySelectedQueryHookResult = ReturnType<
  typeof useGetCachedCompanySelectedQuery
>;
export type GetCachedCompanySelectedLazyQueryHookResult = ReturnType<
  typeof useGetCachedCompanySelectedLazyQuery
>;
export type GetCachedCompanySelectedQueryResult = ApolloReactCommon.QueryResult<
  GetCachedCompanySelectedQuery,
  GetCachedCompanySelectedQueryVariables
>;
export const GetCachedImagesDocument = gql`
  query GetCachedImages {
    cachedImages @client {
      url
      context
      originalImageUrl
      originalImageId
      cropId
      cropX
      cropY
      cropWidth
      cropHeight
      originalImageWidth
      originalImageHeight
      file
    }
  }
`;
export type GetCachedImagesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<GetCachedImagesQuery, GetCachedImagesQueryVariables>,
  'query'
>;

export const GetCachedImagesComponent = (props: GetCachedImagesComponentProps) => (
  <ApolloReactComponents.Query<GetCachedImagesQuery, GetCachedImagesQueryVariables>
    query={GetCachedImagesDocument}
    {...props}
  />
);

export type GetCachedImagesProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<GetCachedImagesQuery, GetCachedImagesQueryVariables>;
} & TChildProps;
export function withGetCachedImages<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetCachedImagesQuery,
    GetCachedImagesQueryVariables,
    GetCachedImagesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetCachedImagesQuery,
    GetCachedImagesQueryVariables,
    GetCachedImagesProps<TChildProps, TDataName>
  >(GetCachedImagesDocument, {
    alias: 'getCachedImages',
    ...operationOptions,
  });
}

/**
 * __useGetCachedImagesQuery__
 *
 * To run a query within a React component, call `useGetCachedImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCachedImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCachedImagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCachedImagesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCachedImagesQuery,
    GetCachedImagesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetCachedImagesQuery, GetCachedImagesQueryVariables>(
    GetCachedImagesDocument,
    baseOptions
  );
}
export function useGetCachedImagesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCachedImagesQuery,
    GetCachedImagesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetCachedImagesQuery, GetCachedImagesQueryVariables>(
    GetCachedImagesDocument,
    baseOptions
  );
}
export type GetCachedImagesQueryHookResult = ReturnType<typeof useGetCachedImagesQuery>;
export type GetCachedImagesLazyQueryHookResult = ReturnType<typeof useGetCachedImagesLazyQuery>;
export type GetCachedImagesQueryResult = ApolloReactCommon.QueryResult<
  GetCachedImagesQuery,
  GetCachedImagesQueryVariables
>;
export const GetIsGmapsReadyDocument = gql`
  query GetIsGmapsReady {
    isGmapsReady @client
  }
`;
export type GetIsGmapsReadyComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<GetIsGmapsReadyQuery, GetIsGmapsReadyQueryVariables>,
  'query'
>;

export const GetIsGmapsReadyComponent = (props: GetIsGmapsReadyComponentProps) => (
  <ApolloReactComponents.Query<GetIsGmapsReadyQuery, GetIsGmapsReadyQueryVariables>
    query={GetIsGmapsReadyDocument}
    {...props}
  />
);

export type GetIsGmapsReadyProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<GetIsGmapsReadyQuery, GetIsGmapsReadyQueryVariables>;
} & TChildProps;
export function withGetIsGmapsReady<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetIsGmapsReadyQuery,
    GetIsGmapsReadyQueryVariables,
    GetIsGmapsReadyProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetIsGmapsReadyQuery,
    GetIsGmapsReadyQueryVariables,
    GetIsGmapsReadyProps<TChildProps, TDataName>
  >(GetIsGmapsReadyDocument, {
    alias: 'getIsGmapsReady',
    ...operationOptions,
  });
}

/**
 * __useGetIsGmapsReadyQuery__
 *
 * To run a query within a React component, call `useGetIsGmapsReadyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIsGmapsReadyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIsGmapsReadyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIsGmapsReadyQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetIsGmapsReadyQuery,
    GetIsGmapsReadyQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetIsGmapsReadyQuery, GetIsGmapsReadyQueryVariables>(
    GetIsGmapsReadyDocument,
    baseOptions
  );
}
export function useGetIsGmapsReadyLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetIsGmapsReadyQuery,
    GetIsGmapsReadyQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetIsGmapsReadyQuery, GetIsGmapsReadyQueryVariables>(
    GetIsGmapsReadyDocument,
    baseOptions
  );
}
export type GetIsGmapsReadyQueryHookResult = ReturnType<typeof useGetIsGmapsReadyQuery>;
export type GetIsGmapsReadyLazyQueryHookResult = ReturnType<typeof useGetIsGmapsReadyLazyQuery>;
export type GetIsGmapsReadyQueryResult = ApolloReactCommon.QueryResult<
  GetIsGmapsReadyQuery,
  GetIsGmapsReadyQueryVariables
>;
export const GetUserInfoDocument = gql`
  query GetUserInfo {
    crmUser {
      userId
      user {
        firstName
        lastName
        email
        customer {
          customerDocumentSet {
            document {
              data
              documentType
            }
          }
          telephone
          validationCellphone
        }
      }
    }
  }
`;
export type GetUserInfoComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<GetUserInfoQuery, GetUserInfoQueryVariables>,
  'query'
>;

export const GetUserInfoComponent = (props: GetUserInfoComponentProps) => (
  <ApolloReactComponents.Query<GetUserInfoQuery, GetUserInfoQueryVariables>
    query={GetUserInfoDocument}
    {...props}
  />
);

export type GetUserInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<GetUserInfoQuery, GetUserInfoQueryVariables>;
} & TChildProps;
export function withGetUserInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetUserInfoQuery,
    GetUserInfoQueryVariables,
    GetUserInfoProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetUserInfoQuery,
    GetUserInfoQueryVariables,
    GetUserInfoProps<TChildProps, TDataName>
  >(GetUserInfoDocument, {
    alias: 'getUserInfo',
    ...operationOptions,
  });
}

/**
 * __useGetUserInfoQuery__
 *
 * To run a query within a React component, call `useGetUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserInfoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserInfoQuery, GetUserInfoQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(
    GetUserInfoDocument,
    baseOptions
  );
}
export function useGetUserInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserInfoQuery, GetUserInfoQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(
    GetUserInfoDocument,
    baseOptions
  );
}
export type GetUserInfoQueryHookResult = ReturnType<typeof useGetUserInfoQuery>;
export type GetUserInfoLazyQueryHookResult = ReturnType<typeof useGetUserInfoLazyQuery>;
export type GetUserInfoQueryResult = ApolloReactCommon.QueryResult<
  GetUserInfoQuery,
  GetUserInfoQueryVariables
>;
export const GetAccountsCachedDataDocument = gql`
  query GetAccountsCachedData {
    accountsCachedData @client {
      cellphone
      cpf
      email
      firstName
      lastName
      password
      cellphone
    }
  }
`;
export type GetAccountsCachedDataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetAccountsCachedDataQuery,
    GetAccountsCachedDataQueryVariables
  >,
  'query'
>;

export const GetAccountsCachedDataComponent = (props: GetAccountsCachedDataComponentProps) => (
  <ApolloReactComponents.Query<GetAccountsCachedDataQuery, GetAccountsCachedDataQueryVariables>
    query={GetAccountsCachedDataDocument}
    {...props}
  />
);

export type GetAccountsCachedDataProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetAccountsCachedDataQuery,
    GetAccountsCachedDataQueryVariables
  >;
} & TChildProps;
export function withGetAccountsCachedData<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetAccountsCachedDataQuery,
    GetAccountsCachedDataQueryVariables,
    GetAccountsCachedDataProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetAccountsCachedDataQuery,
    GetAccountsCachedDataQueryVariables,
    GetAccountsCachedDataProps<TChildProps, TDataName>
  >(GetAccountsCachedDataDocument, {
    alias: 'getAccountsCachedData',
    ...operationOptions,
  });
}

/**
 * __useGetAccountsCachedDataQuery__
 *
 * To run a query within a React component, call `useGetAccountsCachedDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsCachedDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsCachedDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountsCachedDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAccountsCachedDataQuery,
    GetAccountsCachedDataQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetAccountsCachedDataQuery, GetAccountsCachedDataQueryVariables>(
    GetAccountsCachedDataDocument,
    baseOptions
  );
}
export function useGetAccountsCachedDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAccountsCachedDataQuery,
    GetAccountsCachedDataQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetAccountsCachedDataQuery,
    GetAccountsCachedDataQueryVariables
  >(GetAccountsCachedDataDocument, baseOptions);
}
export type GetAccountsCachedDataQueryHookResult = ReturnType<typeof useGetAccountsCachedDataQuery>;
export type GetAccountsCachedDataLazyQueryHookResult = ReturnType<
  typeof useGetAccountsCachedDataLazyQuery
>;
export type GetAccountsCachedDataQueryResult = ApolloReactCommon.QueryResult<
  GetAccountsCachedDataQuery,
  GetAccountsCachedDataQueryVariables
>;
export const GetAddressCachedDataDocument = gql`
  query GetAddressCachedData {
    addressCachedData @client {
      street
      number
      zipCode
      state
      complement
      city
    }
  }
`;
export type GetAddressCachedDataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetAddressCachedDataQuery,
    GetAddressCachedDataQueryVariables
  >,
  'query'
>;

export const GetAddressCachedDataComponent = (props: GetAddressCachedDataComponentProps) => (
  <ApolloReactComponents.Query<GetAddressCachedDataQuery, GetAddressCachedDataQueryVariables>
    query={GetAddressCachedDataDocument}
    {...props}
  />
);

export type GetAddressCachedDataProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetAddressCachedDataQuery,
    GetAddressCachedDataQueryVariables
  >;
} & TChildProps;
export function withGetAddressCachedData<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetAddressCachedDataQuery,
    GetAddressCachedDataQueryVariables,
    GetAddressCachedDataProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetAddressCachedDataQuery,
    GetAddressCachedDataQueryVariables,
    GetAddressCachedDataProps<TChildProps, TDataName>
  >(GetAddressCachedDataDocument, {
    alias: 'getAddressCachedData',
    ...operationOptions,
  });
}

/**
 * __useGetAddressCachedDataQuery__
 *
 * To run a query within a React component, call `useGetAddressCachedDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddressCachedDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddressCachedDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAddressCachedDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAddressCachedDataQuery,
    GetAddressCachedDataQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetAddressCachedDataQuery, GetAddressCachedDataQueryVariables>(
    GetAddressCachedDataDocument,
    baseOptions
  );
}
export function useGetAddressCachedDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAddressCachedDataQuery,
    GetAddressCachedDataQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetAddressCachedDataQuery,
    GetAddressCachedDataQueryVariables
  >(GetAddressCachedDataDocument, baseOptions);
}
export type GetAddressCachedDataQueryHookResult = ReturnType<typeof useGetAddressCachedDataQuery>;
export type GetAddressCachedDataLazyQueryHookResult = ReturnType<
  typeof useGetAddressCachedDataLazyQuery
>;
export type GetAddressCachedDataQueryResult = ApolloReactCommon.QueryResult<
  GetAddressCachedDataQuery,
  GetAddressCachedDataQueryVariables
>;
export const GetLatLongCachedDataDocument = gql`
  query GetLatLongCachedData {
    addressLatLongCachedData @client {
      geoLat
      geoLon
    }
  }
`;
export type GetLatLongCachedDataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetLatLongCachedDataQuery,
    GetLatLongCachedDataQueryVariables
  >,
  'query'
>;

export const GetLatLongCachedDataComponent = (props: GetLatLongCachedDataComponentProps) => (
  <ApolloReactComponents.Query<GetLatLongCachedDataQuery, GetLatLongCachedDataQueryVariables>
    query={GetLatLongCachedDataDocument}
    {...props}
  />
);

export type GetLatLongCachedDataProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetLatLongCachedDataQuery,
    GetLatLongCachedDataQueryVariables
  >;
} & TChildProps;
export function withGetLatLongCachedData<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetLatLongCachedDataQuery,
    GetLatLongCachedDataQueryVariables,
    GetLatLongCachedDataProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetLatLongCachedDataQuery,
    GetLatLongCachedDataQueryVariables,
    GetLatLongCachedDataProps<TChildProps, TDataName>
  >(GetLatLongCachedDataDocument, {
    alias: 'getLatLongCachedData',
    ...operationOptions,
  });
}

/**
 * __useGetLatLongCachedDataQuery__
 *
 * To run a query within a React component, call `useGetLatLongCachedDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatLongCachedDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatLongCachedDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLatLongCachedDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetLatLongCachedDataQuery,
    GetLatLongCachedDataQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetLatLongCachedDataQuery, GetLatLongCachedDataQueryVariables>(
    GetLatLongCachedDataDocument,
    baseOptions
  );
}
export function useGetLatLongCachedDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetLatLongCachedDataQuery,
    GetLatLongCachedDataQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetLatLongCachedDataQuery,
    GetLatLongCachedDataQueryVariables
  >(GetLatLongCachedDataDocument, baseOptions);
}
export type GetLatLongCachedDataQueryHookResult = ReturnType<typeof useGetLatLongCachedDataQuery>;
export type GetLatLongCachedDataLazyQueryHookResult = ReturnType<
  typeof useGetLatLongCachedDataLazyQuery
>;
export type GetLatLongCachedDataQueryResult = ApolloReactCommon.QueryResult<
  GetLatLongCachedDataQuery,
  GetLatLongCachedDataQueryVariables
>;
export const DeleteBrandCroppedPictureDocument = gql`
  mutation DeleteBrandCroppedPicture($id: Int!, $brand: Int!) {
    deleteBrandCroppedPicture(id: $id, brand: $brand) {
      updatedBrand {
        images {
          url
          context
          originalImageUrl
          originalImageId
          cropId
          cropX
          cropY
          cropWidth
          cropHeight
          originalImageWidth
          originalImageHeight
        }
      }
    }
  }
`;
export type DeleteBrandCroppedPictureMutationFn = ApolloReactCommon.MutationFunction<
  DeleteBrandCroppedPictureMutation,
  DeleteBrandCroppedPictureMutationVariables
>;
export type DeleteBrandCroppedPictureComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteBrandCroppedPictureMutation,
    DeleteBrandCroppedPictureMutationVariables
  >,
  'mutation'
>;

export const DeleteBrandCroppedPictureComponent = (
  props: DeleteBrandCroppedPictureComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteBrandCroppedPictureMutation,
    DeleteBrandCroppedPictureMutationVariables
  >
    mutation={DeleteBrandCroppedPictureDocument}
    {...props}
  />
);

export type DeleteBrandCroppedPictureProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    DeleteBrandCroppedPictureMutation,
    DeleteBrandCroppedPictureMutationVariables
  >;
} & TChildProps;
export function withDeleteBrandCroppedPicture<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteBrandCroppedPictureMutation,
    DeleteBrandCroppedPictureMutationVariables,
    DeleteBrandCroppedPictureProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteBrandCroppedPictureMutation,
    DeleteBrandCroppedPictureMutationVariables,
    DeleteBrandCroppedPictureProps<TChildProps, TDataName>
  >(DeleteBrandCroppedPictureDocument, {
    alias: 'deleteBrandCroppedPicture',
    ...operationOptions,
  });
}

/**
 * __useDeleteBrandCroppedPictureMutation__
 *
 * To run a mutation, you first call `useDeleteBrandCroppedPictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBrandCroppedPictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBrandCroppedPictureMutation, { data, loading, error }] = useDeleteBrandCroppedPictureMutation({
 *   variables: {
 *      id: // value for 'id'
 *      brand: // value for 'brand'
 *   },
 * });
 */
export function useDeleteBrandCroppedPictureMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteBrandCroppedPictureMutation,
    DeleteBrandCroppedPictureMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteBrandCroppedPictureMutation,
    DeleteBrandCroppedPictureMutationVariables
  >(DeleteBrandCroppedPictureDocument, baseOptions);
}
export type DeleteBrandCroppedPictureMutationHookResult = ReturnType<
  typeof useDeleteBrandCroppedPictureMutation
>;
export type DeleteBrandCroppedPictureMutationResult =
  ApolloReactCommon.MutationResult<DeleteBrandCroppedPictureMutation>;
export type DeleteBrandCroppedPictureMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteBrandCroppedPictureMutation,
  DeleteBrandCroppedPictureMutationVariables
>;
export const GetBrandImagesDocument = gql`
  query GetBrandImages($ids: [Int]!) {
    brands(ids: $ids) {
      images {
        url
        context
        originalImageUrl
        originalImageId
        cropId
        cropX
        cropY
        cropWidth
        cropHeight
        originalImageWidth
        originalImageHeight
      }
    }
  }
`;
export type GetBrandImagesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<GetBrandImagesQuery, GetBrandImagesQueryVariables>,
  'query'
> &
  ({ variables: GetBrandImagesQueryVariables; skip?: boolean } | { skip: boolean });

export const GetBrandImagesComponent = (props: GetBrandImagesComponentProps) => (
  <ApolloReactComponents.Query<GetBrandImagesQuery, GetBrandImagesQueryVariables>
    query={GetBrandImagesDocument}
    {...props}
  />
);

export type GetBrandImagesProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<GetBrandImagesQuery, GetBrandImagesQueryVariables>;
} & TChildProps;
export function withGetBrandImages<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetBrandImagesQuery,
    GetBrandImagesQueryVariables,
    GetBrandImagesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetBrandImagesQuery,
    GetBrandImagesQueryVariables,
    GetBrandImagesProps<TChildProps, TDataName>
  >(GetBrandImagesDocument, {
    alias: 'getBrandImages',
    ...operationOptions,
  });
}

/**
 * __useGetBrandImagesQuery__
 *
 * To run a query within a React component, call `useGetBrandImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandImagesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetBrandImagesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetBrandImagesQuery, GetBrandImagesQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetBrandImagesQuery, GetBrandImagesQueryVariables>(
    GetBrandImagesDocument,
    baseOptions
  );
}
export function useGetBrandImagesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetBrandImagesQuery,
    GetBrandImagesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetBrandImagesQuery, GetBrandImagesQueryVariables>(
    GetBrandImagesDocument,
    baseOptions
  );
}
export type GetBrandImagesQueryHookResult = ReturnType<typeof useGetBrandImagesQuery>;
export type GetBrandImagesLazyQueryHookResult = ReturnType<typeof useGetBrandImagesLazyQuery>;
export type GetBrandImagesQueryResult = ApolloReactCommon.QueryResult<
  GetBrandImagesQuery,
  GetBrandImagesQueryVariables
>;
export const SaveBrandPicturesDocument = gql`
  mutation SaveBrandPictures($files: [Upload]!, $input: [SavePicturesInput]!, $brand: Int!) {
    saveBrandPictures(files: $files, input: $input, brand: $brand) {
      updatedBrand {
        images {
          url
          context
          originalImageUrl
          originalImageId
          cropId
          cropX
          cropY
          cropWidth
          cropHeight
          originalImageWidth
          originalImageHeight
        }
      }
    }
  }
`;
export type SaveBrandPicturesMutationFn = ApolloReactCommon.MutationFunction<
  SaveBrandPicturesMutation,
  SaveBrandPicturesMutationVariables
>;
export type SaveBrandPicturesComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SaveBrandPicturesMutation,
    SaveBrandPicturesMutationVariables
  >,
  'mutation'
>;

export const SaveBrandPicturesComponent = (props: SaveBrandPicturesComponentProps) => (
  <ApolloReactComponents.Mutation<SaveBrandPicturesMutation, SaveBrandPicturesMutationVariables>
    mutation={SaveBrandPicturesDocument}
    {...props}
  />
);

export type SaveBrandPicturesProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    SaveBrandPicturesMutation,
    SaveBrandPicturesMutationVariables
  >;
} & TChildProps;
export function withSaveBrandPictures<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SaveBrandPicturesMutation,
    SaveBrandPicturesMutationVariables,
    SaveBrandPicturesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SaveBrandPicturesMutation,
    SaveBrandPicturesMutationVariables,
    SaveBrandPicturesProps<TChildProps, TDataName>
  >(SaveBrandPicturesDocument, {
    alias: 'saveBrandPictures',
    ...operationOptions,
  });
}

/**
 * __useSaveBrandPicturesMutation__
 *
 * To run a mutation, you first call `useSaveBrandPicturesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveBrandPicturesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveBrandPicturesMutation, { data, loading, error }] = useSaveBrandPicturesMutation({
 *   variables: {
 *      files: // value for 'files'
 *      input: // value for 'input'
 *      brand: // value for 'brand'
 *   },
 * });
 */
export function useSaveBrandPicturesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveBrandPicturesMutation,
    SaveBrandPicturesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SaveBrandPicturesMutation,
    SaveBrandPicturesMutationVariables
  >(SaveBrandPicturesDocument, baseOptions);
}
export type SaveBrandPicturesMutationHookResult = ReturnType<typeof useSaveBrandPicturesMutation>;
export type SaveBrandPicturesMutationResult =
  ApolloReactCommon.MutationResult<SaveBrandPicturesMutation>;
export type SaveBrandPicturesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveBrandPicturesMutation,
  SaveBrandPicturesMutationVariables
>;
export const UpdateBrandCroppedPictureDocument = gql`
  mutation UpdateBrandCroppedPicture($id: Int!, $input: UpdateCroppedPictureInput!, $brand: Int!) {
    updateBrandCroppedPicture(id: $id, input: $input, brand: $brand) {
      updatedBrand {
        images {
          url
          context
          originalImageUrl
          originalImageId
          cropId
          cropX
          cropY
          cropWidth
          cropHeight
          originalImageWidth
          originalImageHeight
        }
      }
    }
  }
`;
export type UpdateBrandCroppedPictureMutationFn = ApolloReactCommon.MutationFunction<
  UpdateBrandCroppedPictureMutation,
  UpdateBrandCroppedPictureMutationVariables
>;
export type UpdateBrandCroppedPictureComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateBrandCroppedPictureMutation,
    UpdateBrandCroppedPictureMutationVariables
  >,
  'mutation'
>;

export const UpdateBrandCroppedPictureComponent = (
  props: UpdateBrandCroppedPictureComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateBrandCroppedPictureMutation,
    UpdateBrandCroppedPictureMutationVariables
  >
    mutation={UpdateBrandCroppedPictureDocument}
    {...props}
  />
);

export type UpdateBrandCroppedPictureProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    UpdateBrandCroppedPictureMutation,
    UpdateBrandCroppedPictureMutationVariables
  >;
} & TChildProps;
export function withUpdateBrandCroppedPicture<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateBrandCroppedPictureMutation,
    UpdateBrandCroppedPictureMutationVariables,
    UpdateBrandCroppedPictureProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateBrandCroppedPictureMutation,
    UpdateBrandCroppedPictureMutationVariables,
    UpdateBrandCroppedPictureProps<TChildProps, TDataName>
  >(UpdateBrandCroppedPictureDocument, {
    alias: 'updateBrandCroppedPicture',
    ...operationOptions,
  });
}

/**
 * __useUpdateBrandCroppedPictureMutation__
 *
 * To run a mutation, you first call `useUpdateBrandCroppedPictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBrandCroppedPictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBrandCroppedPictureMutation, { data, loading, error }] = useUpdateBrandCroppedPictureMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      brand: // value for 'brand'
 *   },
 * });
 */
export function useUpdateBrandCroppedPictureMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateBrandCroppedPictureMutation,
    UpdateBrandCroppedPictureMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateBrandCroppedPictureMutation,
    UpdateBrandCroppedPictureMutationVariables
  >(UpdateBrandCroppedPictureDocument, baseOptions);
}
export type UpdateBrandCroppedPictureMutationHookResult = ReturnType<
  typeof useUpdateBrandCroppedPictureMutation
>;
export type UpdateBrandCroppedPictureMutationResult =
  ApolloReactCommon.MutationResult<UpdateBrandCroppedPictureMutation>;
export type UpdateBrandCroppedPictureMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateBrandCroppedPictureMutation,
  UpdateBrandCroppedPictureMutationVariables
>;
export const GetBusinessCachedDataDocument = gql`
  query GetBusinessCachedData {
    businessCachedData @client {
      brandName
      name
      displayName
      corporateName
      cnpj
      telephone
      fileUrl
      brandImageFile
      brandImageInput {
        cropHeight
        cropWidth
        cropX
        cropY
        context
        cropId
      }
    }
  }
`;
export type GetBusinessCachedDataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetBusinessCachedDataQuery,
    GetBusinessCachedDataQueryVariables
  >,
  'query'
>;

export const GetBusinessCachedDataComponent = (props: GetBusinessCachedDataComponentProps) => (
  <ApolloReactComponents.Query<GetBusinessCachedDataQuery, GetBusinessCachedDataQueryVariables>
    query={GetBusinessCachedDataDocument}
    {...props}
  />
);

export type GetBusinessCachedDataProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetBusinessCachedDataQuery,
    GetBusinessCachedDataQueryVariables
  >;
} & TChildProps;
export function withGetBusinessCachedData<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetBusinessCachedDataQuery,
    GetBusinessCachedDataQueryVariables,
    GetBusinessCachedDataProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetBusinessCachedDataQuery,
    GetBusinessCachedDataQueryVariables,
    GetBusinessCachedDataProps<TChildProps, TDataName>
  >(GetBusinessCachedDataDocument, {
    alias: 'getBusinessCachedData',
    ...operationOptions,
  });
}

/**
 * __useGetBusinessCachedDataQuery__
 *
 * To run a query within a React component, call `useGetBusinessCachedDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessCachedDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessCachedDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBusinessCachedDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetBusinessCachedDataQuery,
    GetBusinessCachedDataQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetBusinessCachedDataQuery, GetBusinessCachedDataQueryVariables>(
    GetBusinessCachedDataDocument,
    baseOptions
  );
}
export function useGetBusinessCachedDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetBusinessCachedDataQuery,
    GetBusinessCachedDataQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetBusinessCachedDataQuery,
    GetBusinessCachedDataQueryVariables
  >(GetBusinessCachedDataDocument, baseOptions);
}
export type GetBusinessCachedDataQueryHookResult = ReturnType<typeof useGetBusinessCachedDataQuery>;
export type GetBusinessCachedDataLazyQueryHookResult = ReturnType<
  typeof useGetBusinessCachedDataLazyQuery
>;
export type GetBusinessCachedDataQueryResult = ApolloReactCommon.QueryResult<
  GetBusinessCachedDataQuery,
  GetBusinessCachedDataQueryVariables
>;
export const DeleteCompanyCroppedPictureDocument = gql`
  mutation DeleteCompanyCroppedPicture($id: Int!, $company: Int!) {
    deleteCompanyCroppedPicture(id: $id, company: $company) {
      updatedCompany {
        images {
          url
          context
          originalImageUrl
          originalImageId
          cropId
          cropX
          cropY
          cropWidth
          cropHeight
          originalImageWidth
          originalImageHeight
        }
      }
    }
  }
`;
export type DeleteCompanyCroppedPictureMutationFn = ApolloReactCommon.MutationFunction<
  DeleteCompanyCroppedPictureMutation,
  DeleteCompanyCroppedPictureMutationVariables
>;
export type DeleteCompanyCroppedPictureComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteCompanyCroppedPictureMutation,
    DeleteCompanyCroppedPictureMutationVariables
  >,
  'mutation'
>;

export const DeleteCompanyCroppedPictureComponent = (
  props: DeleteCompanyCroppedPictureComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteCompanyCroppedPictureMutation,
    DeleteCompanyCroppedPictureMutationVariables
  >
    mutation={DeleteCompanyCroppedPictureDocument}
    {...props}
  />
);

export type DeleteCompanyCroppedPictureProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    DeleteCompanyCroppedPictureMutation,
    DeleteCompanyCroppedPictureMutationVariables
  >;
} & TChildProps;
export function withDeleteCompanyCroppedPicture<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteCompanyCroppedPictureMutation,
    DeleteCompanyCroppedPictureMutationVariables,
    DeleteCompanyCroppedPictureProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteCompanyCroppedPictureMutation,
    DeleteCompanyCroppedPictureMutationVariables,
    DeleteCompanyCroppedPictureProps<TChildProps, TDataName>
  >(DeleteCompanyCroppedPictureDocument, {
    alias: 'deleteCompanyCroppedPicture',
    ...operationOptions,
  });
}

/**
 * __useDeleteCompanyCroppedPictureMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyCroppedPictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyCroppedPictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyCroppedPictureMutation, { data, loading, error }] = useDeleteCompanyCroppedPictureMutation({
 *   variables: {
 *      id: // value for 'id'
 *      company: // value for 'company'
 *   },
 * });
 */
export function useDeleteCompanyCroppedPictureMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteCompanyCroppedPictureMutation,
    DeleteCompanyCroppedPictureMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteCompanyCroppedPictureMutation,
    DeleteCompanyCroppedPictureMutationVariables
  >(DeleteCompanyCroppedPictureDocument, baseOptions);
}
export type DeleteCompanyCroppedPictureMutationHookResult = ReturnType<
  typeof useDeleteCompanyCroppedPictureMutation
>;
export type DeleteCompanyCroppedPictureMutationResult =
  ApolloReactCommon.MutationResult<DeleteCompanyCroppedPictureMutation>;
export type DeleteCompanyCroppedPictureMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteCompanyCroppedPictureMutation,
  DeleteCompanyCroppedPictureMutationVariables
>;
export const GetCompanyImagesDocument = gql`
  query GetCompanyImages($companyId: Int!, $showHiddenCompany: Boolean) {
    company(companyId: $companyId, showHiddenCompany: $showHiddenCompany) {
      images {
        url
        context
        originalImageUrl
        originalImageId
        cropId
        cropX
        cropY
        cropWidth
        cropHeight
        originalImageWidth
        originalImageHeight
      }
    }
  }
`;
export type GetCompanyImagesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetCompanyImagesQuery,
    GetCompanyImagesQueryVariables
  >,
  'query'
> &
  ({ variables: GetCompanyImagesQueryVariables; skip?: boolean } | { skip: boolean });

export const GetCompanyImagesComponent = (props: GetCompanyImagesComponentProps) => (
  <ApolloReactComponents.Query<GetCompanyImagesQuery, GetCompanyImagesQueryVariables>
    query={GetCompanyImagesDocument}
    {...props}
  />
);

export type GetCompanyImagesProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetCompanyImagesQuery,
    GetCompanyImagesQueryVariables
  >;
} & TChildProps;
export function withGetCompanyImages<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetCompanyImagesQuery,
    GetCompanyImagesQueryVariables,
    GetCompanyImagesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetCompanyImagesQuery,
    GetCompanyImagesQueryVariables,
    GetCompanyImagesProps<TChildProps, TDataName>
  >(GetCompanyImagesDocument, {
    alias: 'getCompanyImages',
    ...operationOptions,
  });
}

/**
 * __useGetCompanyImagesQuery__
 *
 * To run a query within a React component, call `useGetCompanyImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyImagesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      showHiddenCompany: // value for 'showHiddenCompany'
 *   },
 * });
 */
export function useGetCompanyImagesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCompanyImagesQuery,
    GetCompanyImagesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetCompanyImagesQuery, GetCompanyImagesQueryVariables>(
    GetCompanyImagesDocument,
    baseOptions
  );
}
export function useGetCompanyImagesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCompanyImagesQuery,
    GetCompanyImagesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetCompanyImagesQuery, GetCompanyImagesQueryVariables>(
    GetCompanyImagesDocument,
    baseOptions
  );
}
export type GetCompanyImagesQueryHookResult = ReturnType<typeof useGetCompanyImagesQuery>;
export type GetCompanyImagesLazyQueryHookResult = ReturnType<typeof useGetCompanyImagesLazyQuery>;
export type GetCompanyImagesQueryResult = ApolloReactCommon.QueryResult<
  GetCompanyImagesQuery,
  GetCompanyImagesQueryVariables
>;
export const SaveCompanyPicturesDocument = gql`
  mutation SaveCompanyPictures($files: [Upload]!, $input: [SavePicturesInput]!, $company: Int!) {
    saveCompanyPictures(files: $files, input: $input, company: $company) {
      updatedCompany {
        images {
          url
          context
          originalImageUrl
          originalImageId
          cropId
          cropX
          cropY
          cropWidth
          cropHeight
          originalImageWidth
          originalImageHeight
        }
      }
    }
  }
`;
export type SaveCompanyPicturesMutationFn = ApolloReactCommon.MutationFunction<
  SaveCompanyPicturesMutation,
  SaveCompanyPicturesMutationVariables
>;
export type SaveCompanyPicturesComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SaveCompanyPicturesMutation,
    SaveCompanyPicturesMutationVariables
  >,
  'mutation'
>;

export const SaveCompanyPicturesComponent = (props: SaveCompanyPicturesComponentProps) => (
  <ApolloReactComponents.Mutation<SaveCompanyPicturesMutation, SaveCompanyPicturesMutationVariables>
    mutation={SaveCompanyPicturesDocument}
    {...props}
  />
);

export type SaveCompanyPicturesProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    SaveCompanyPicturesMutation,
    SaveCompanyPicturesMutationVariables
  >;
} & TChildProps;
export function withSaveCompanyPictures<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SaveCompanyPicturesMutation,
    SaveCompanyPicturesMutationVariables,
    SaveCompanyPicturesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SaveCompanyPicturesMutation,
    SaveCompanyPicturesMutationVariables,
    SaveCompanyPicturesProps<TChildProps, TDataName>
  >(SaveCompanyPicturesDocument, {
    alias: 'saveCompanyPictures',
    ...operationOptions,
  });
}

/**
 * __useSaveCompanyPicturesMutation__
 *
 * To run a mutation, you first call `useSaveCompanyPicturesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCompanyPicturesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCompanyPicturesMutation, { data, loading, error }] = useSaveCompanyPicturesMutation({
 *   variables: {
 *      files: // value for 'files'
 *      input: // value for 'input'
 *      company: // value for 'company'
 *   },
 * });
 */
export function useSaveCompanyPicturesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveCompanyPicturesMutation,
    SaveCompanyPicturesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SaveCompanyPicturesMutation,
    SaveCompanyPicturesMutationVariables
  >(SaveCompanyPicturesDocument, baseOptions);
}
export type SaveCompanyPicturesMutationHookResult = ReturnType<
  typeof useSaveCompanyPicturesMutation
>;
export type SaveCompanyPicturesMutationResult =
  ApolloReactCommon.MutationResult<SaveCompanyPicturesMutation>;
export type SaveCompanyPicturesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveCompanyPicturesMutation,
  SaveCompanyPicturesMutationVariables
>;
export const UpdateCompanyCroppedPictureDocument = gql`
  mutation UpdateCompanyCroppedPicture(
    $id: Int!
    $input: UpdateCroppedPictureInput!
    $company: Int!
  ) {
    updateCompanyCroppedPicture(id: $id, input: $input, company: $company) {
      updatedCompany {
        images {
          url
          context
          originalImageUrl
          originalImageId
          cropId
          cropX
          cropY
          cropWidth
          cropHeight
          originalImageWidth
          originalImageHeight
        }
      }
    }
  }
`;
export type UpdateCompanyCroppedPictureMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCompanyCroppedPictureMutation,
  UpdateCompanyCroppedPictureMutationVariables
>;
export type UpdateCompanyCroppedPictureComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateCompanyCroppedPictureMutation,
    UpdateCompanyCroppedPictureMutationVariables
  >,
  'mutation'
>;

export const UpdateCompanyCroppedPictureComponent = (
  props: UpdateCompanyCroppedPictureComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateCompanyCroppedPictureMutation,
    UpdateCompanyCroppedPictureMutationVariables
  >
    mutation={UpdateCompanyCroppedPictureDocument}
    {...props}
  />
);

export type UpdateCompanyCroppedPictureProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    UpdateCompanyCroppedPictureMutation,
    UpdateCompanyCroppedPictureMutationVariables
  >;
} & TChildProps;
export function withUpdateCompanyCroppedPicture<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateCompanyCroppedPictureMutation,
    UpdateCompanyCroppedPictureMutationVariables,
    UpdateCompanyCroppedPictureProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateCompanyCroppedPictureMutation,
    UpdateCompanyCroppedPictureMutationVariables,
    UpdateCompanyCroppedPictureProps<TChildProps, TDataName>
  >(UpdateCompanyCroppedPictureDocument, {
    alias: 'updateCompanyCroppedPicture',
    ...operationOptions,
  });
}

/**
 * __useUpdateCompanyCroppedPictureMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyCroppedPictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyCroppedPictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyCroppedPictureMutation, { data, loading, error }] = useUpdateCompanyCroppedPictureMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      company: // value for 'company'
 *   },
 * });
 */
export function useUpdateCompanyCroppedPictureMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCompanyCroppedPictureMutation,
    UpdateCompanyCroppedPictureMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateCompanyCroppedPictureMutation,
    UpdateCompanyCroppedPictureMutationVariables
  >(UpdateCompanyCroppedPictureDocument, baseOptions);
}
export type UpdateCompanyCroppedPictureMutationHookResult = ReturnType<
  typeof useUpdateCompanyCroppedPictureMutation
>;
export type UpdateCompanyCroppedPictureMutationResult =
  ApolloReactCommon.MutationResult<UpdateCompanyCroppedPictureMutation>;
export type UpdateCompanyCroppedPictureMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCompanyCroppedPictureMutation,
  UpdateCompanyCroppedPictureMutationVariables
>;
export const SelfJourneyDocument = gql`
  mutation SelfJourney($selfJourneyData: SelfJourneyInput!) {
    selfJourneyRegister(selfJourneyData: $selfJourneyData) {
      token
    }
  }
`;
export type SelfJourneyMutationFn = ApolloReactCommon.MutationFunction<
  SelfJourneyMutation,
  SelfJourneyMutationVariables
>;
export type SelfJourneyComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<SelfJourneyMutation, SelfJourneyMutationVariables>,
  'mutation'
>;

export const SelfJourneyComponent = (props: SelfJourneyComponentProps) => (
  <ApolloReactComponents.Mutation<SelfJourneyMutation, SelfJourneyMutationVariables>
    mutation={SelfJourneyDocument}
    {...props}
  />
);

export type SelfJourneyProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    SelfJourneyMutation,
    SelfJourneyMutationVariables
  >;
} & TChildProps;
export function withSelfJourney<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SelfJourneyMutation,
    SelfJourneyMutationVariables,
    SelfJourneyProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SelfJourneyMutation,
    SelfJourneyMutationVariables,
    SelfJourneyProps<TChildProps, TDataName>
  >(SelfJourneyDocument, {
    alias: 'selfJourney',
    ...operationOptions,
  });
}

/**
 * __useSelfJourneyMutation__
 *
 * To run a mutation, you first call `useSelfJourneyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelfJourneyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selfJourneyMutation, { data, loading, error }] = useSelfJourneyMutation({
 *   variables: {
 *      selfJourneyData: // value for 'selfJourneyData'
 *   },
 * });
 */
export function useSelfJourneyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SelfJourneyMutation,
    SelfJourneyMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<SelfJourneyMutation, SelfJourneyMutationVariables>(
    SelfJourneyDocument,
    baseOptions
  );
}
export type SelfJourneyMutationHookResult = ReturnType<typeof useSelfJourneyMutation>;
export type SelfJourneyMutationResult = ApolloReactCommon.MutationResult<SelfJourneyMutation>;
export type SelfJourneyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SelfJourneyMutation,
  SelfJourneyMutationVariables
>;
export const LoginDocument = gql`
  mutation login($input: LoginAuthInput!) {
    loginTokenAuth(input: $input) {
      accessToken
    }
  }
`;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;
export type LoginComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<LoginMutation, LoginMutationVariables>,
  'mutation'
>;

export const LoginComponent = (props: LoginComponentProps) => (
  <ApolloReactComponents.Mutation<LoginMutation, LoginMutationVariables>
    mutation={LoginDocument}
    {...props}
  />
);

export type LoginProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;
} & TChildProps;
export function withLogin<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    LoginMutation,
    LoginMutationVariables,
    LoginProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    LoginMutation,
    LoginMutationVariables,
    LoginProps<TChildProps, TDataName>
  >(LoginDocument, {
    alias: 'login',
    ...operationOptions,
  });
}

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>
) {
  return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    baseOptions
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const ResetAccountsPasswordDocument = gql`
  mutation ResetAccountsPassword($email: String!) {
    resetAccountsPassword(email: $email) {
      success
    }
  }
`;
export type ResetAccountsPasswordMutationFn = ApolloReactCommon.MutationFunction<
  ResetAccountsPasswordMutation,
  ResetAccountsPasswordMutationVariables
>;
export type ResetAccountsPasswordComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ResetAccountsPasswordMutation,
    ResetAccountsPasswordMutationVariables
  >,
  'mutation'
>;

export const ResetAccountsPasswordComponent = (props: ResetAccountsPasswordComponentProps) => (
  <ApolloReactComponents.Mutation<
    ResetAccountsPasswordMutation,
    ResetAccountsPasswordMutationVariables
  >
    mutation={ResetAccountsPasswordDocument}
    {...props}
  />
);

export type ResetAccountsPasswordProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    ResetAccountsPasswordMutation,
    ResetAccountsPasswordMutationVariables
  >;
} & TChildProps;
export function withResetAccountsPassword<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ResetAccountsPasswordMutation,
    ResetAccountsPasswordMutationVariables,
    ResetAccountsPasswordProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ResetAccountsPasswordMutation,
    ResetAccountsPasswordMutationVariables,
    ResetAccountsPasswordProps<TChildProps, TDataName>
  >(ResetAccountsPasswordDocument, {
    alias: 'resetAccountsPassword',
    ...operationOptions,
  });
}

/**
 * __useResetAccountsPasswordMutation__
 *
 * To run a mutation, you first call `useResetAccountsPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetAccountsPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetAccountsPasswordMutation, { data, loading, error }] = useResetAccountsPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetAccountsPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ResetAccountsPasswordMutation,
    ResetAccountsPasswordMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ResetAccountsPasswordMutation,
    ResetAccountsPasswordMutationVariables
  >(ResetAccountsPasswordDocument, baseOptions);
}
export type ResetAccountsPasswordMutationHookResult = ReturnType<
  typeof useResetAccountsPasswordMutation
>;
export type ResetAccountsPasswordMutationResult =
  ApolloReactCommon.MutationResult<ResetAccountsPasswordMutation>;
export type ResetAccountsPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ResetAccountsPasswordMutation,
  ResetAccountsPasswordMutationVariables
>;
export const BackendMenuDeleteProductPicturesDocument = gql`
  mutation BackendMenuDeleteProductPictures(
    $brandId: Int!
    $companyId: Int!
    $croppedPictureIds: [Int]
    $productId: Int!
  ) {
    backendMenuDeleteProductPictures(
      brandId: $brandId
      companyId: $companyId
      croppedPictureIds: $croppedPictureIds
      productId: $productId
    ) {
      success
    }
  }
`;
export type BackendMenuDeleteProductPicturesMutationFn = ApolloReactCommon.MutationFunction<
  BackendMenuDeleteProductPicturesMutation,
  BackendMenuDeleteProductPicturesMutationVariables
>;
export type BackendMenuDeleteProductPicturesComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    BackendMenuDeleteProductPicturesMutation,
    BackendMenuDeleteProductPicturesMutationVariables
  >,
  'mutation'
>;

export const BackendMenuDeleteProductPicturesComponent = (
  props: BackendMenuDeleteProductPicturesComponentProps
) => (
  <ApolloReactComponents.Mutation<
    BackendMenuDeleteProductPicturesMutation,
    BackendMenuDeleteProductPicturesMutationVariables
  >
    mutation={BackendMenuDeleteProductPicturesDocument}
    {...props}
  />
);

export type BackendMenuDeleteProductPicturesProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    BackendMenuDeleteProductPicturesMutation,
    BackendMenuDeleteProductPicturesMutationVariables
  >;
} & TChildProps;
export function withBackendMenuDeleteProductPictures<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    BackendMenuDeleteProductPicturesMutation,
    BackendMenuDeleteProductPicturesMutationVariables,
    BackendMenuDeleteProductPicturesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    BackendMenuDeleteProductPicturesMutation,
    BackendMenuDeleteProductPicturesMutationVariables,
    BackendMenuDeleteProductPicturesProps<TChildProps, TDataName>
  >(BackendMenuDeleteProductPicturesDocument, {
    alias: 'backendMenuDeleteProductPictures',
    ...operationOptions,
  });
}

/**
 * __useBackendMenuDeleteProductPicturesMutation__
 *
 * To run a mutation, you first call `useBackendMenuDeleteProductPicturesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBackendMenuDeleteProductPicturesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [backendMenuDeleteProductPicturesMutation, { data, loading, error }] = useBackendMenuDeleteProductPicturesMutation({
 *   variables: {
 *      brandId: // value for 'brandId'
 *      companyId: // value for 'companyId'
 *      croppedPictureIds: // value for 'croppedPictureIds'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useBackendMenuDeleteProductPicturesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BackendMenuDeleteProductPicturesMutation,
    BackendMenuDeleteProductPicturesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    BackendMenuDeleteProductPicturesMutation,
    BackendMenuDeleteProductPicturesMutationVariables
  >(BackendMenuDeleteProductPicturesDocument, baseOptions);
}
export type BackendMenuDeleteProductPicturesMutationHookResult = ReturnType<
  typeof useBackendMenuDeleteProductPicturesMutation
>;
export type BackendMenuDeleteProductPicturesMutationResult =
  ApolloReactCommon.MutationResult<BackendMenuDeleteProductPicturesMutation>;
export type BackendMenuDeleteProductPicturesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  BackendMenuDeleteProductPicturesMutation,
  BackendMenuDeleteProductPicturesMutationVariables
>;
export const BackendMenuDeleteProductDocument = gql`
  mutation BackendMenuDeleteProduct($brandId: Int!, $companyId: Int!, $productCompanyId: Int!) {
    backendMenuDeleteProduct(
      brandId: $brandId
      companyId: $companyId
      productCompanyId: $productCompanyId
    ) {
      success
    }
  }
`;
export type BackendMenuDeleteProductMutationFn = ApolloReactCommon.MutationFunction<
  BackendMenuDeleteProductMutation,
  BackendMenuDeleteProductMutationVariables
>;
export type BackendMenuDeleteProductComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    BackendMenuDeleteProductMutation,
    BackendMenuDeleteProductMutationVariables
  >,
  'mutation'
>;

export const BackendMenuDeleteProductComponent = (
  props: BackendMenuDeleteProductComponentProps
) => (
  <ApolloReactComponents.Mutation<
    BackendMenuDeleteProductMutation,
    BackendMenuDeleteProductMutationVariables
  >
    mutation={BackendMenuDeleteProductDocument}
    {...props}
  />
);

export type BackendMenuDeleteProductProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    BackendMenuDeleteProductMutation,
    BackendMenuDeleteProductMutationVariables
  >;
} & TChildProps;
export function withBackendMenuDeleteProduct<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    BackendMenuDeleteProductMutation,
    BackendMenuDeleteProductMutationVariables,
    BackendMenuDeleteProductProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    BackendMenuDeleteProductMutation,
    BackendMenuDeleteProductMutationVariables,
    BackendMenuDeleteProductProps<TChildProps, TDataName>
  >(BackendMenuDeleteProductDocument, {
    alias: 'backendMenuDeleteProduct',
    ...operationOptions,
  });
}

/**
 * __useBackendMenuDeleteProductMutation__
 *
 * To run a mutation, you first call `useBackendMenuDeleteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBackendMenuDeleteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [backendMenuDeleteProductMutation, { data, loading, error }] = useBackendMenuDeleteProductMutation({
 *   variables: {
 *      brandId: // value for 'brandId'
 *      companyId: // value for 'companyId'
 *      productCompanyId: // value for 'productCompanyId'
 *   },
 * });
 */
export function useBackendMenuDeleteProductMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BackendMenuDeleteProductMutation,
    BackendMenuDeleteProductMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    BackendMenuDeleteProductMutation,
    BackendMenuDeleteProductMutationVariables
  >(BackendMenuDeleteProductDocument, baseOptions);
}
export type BackendMenuDeleteProductMutationHookResult = ReturnType<
  typeof useBackendMenuDeleteProductMutation
>;
export type BackendMenuDeleteProductMutationResult =
  ApolloReactCommon.MutationResult<BackendMenuDeleteProductMutation>;
export type BackendMenuDeleteProductMutationOptions = ApolloReactCommon.BaseMutationOptions<
  BackendMenuDeleteProductMutation,
  BackendMenuDeleteProductMutationVariables
>;
export const BackendMenuDeleteSubproductDocument = gql`
  mutation BackendMenuDeleteSubproduct(
    $brandId: Int!
    $companyId: Int!
    $productId: Int!
    $subproductId: Int!
  ) {
    backendSubproductRemoveItem(
      brandId: $brandId
      companyId: $companyId
      productId: $productId
      subproductId: $subproductId
    ) {
      success
    }
  }
`;
export type BackendMenuDeleteSubproductMutationFn = ApolloReactCommon.MutationFunction<
  BackendMenuDeleteSubproductMutation,
  BackendMenuDeleteSubproductMutationVariables
>;
export type BackendMenuDeleteSubproductComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    BackendMenuDeleteSubproductMutation,
    BackendMenuDeleteSubproductMutationVariables
  >,
  'mutation'
>;

export const BackendMenuDeleteSubproductComponent = (
  props: BackendMenuDeleteSubproductComponentProps
) => (
  <ApolloReactComponents.Mutation<
    BackendMenuDeleteSubproductMutation,
    BackendMenuDeleteSubproductMutationVariables
  >
    mutation={BackendMenuDeleteSubproductDocument}
    {...props}
  />
);

export type BackendMenuDeleteSubproductProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    BackendMenuDeleteSubproductMutation,
    BackendMenuDeleteSubproductMutationVariables
  >;
} & TChildProps;
export function withBackendMenuDeleteSubproduct<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    BackendMenuDeleteSubproductMutation,
    BackendMenuDeleteSubproductMutationVariables,
    BackendMenuDeleteSubproductProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    BackendMenuDeleteSubproductMutation,
    BackendMenuDeleteSubproductMutationVariables,
    BackendMenuDeleteSubproductProps<TChildProps, TDataName>
  >(BackendMenuDeleteSubproductDocument, {
    alias: 'backendMenuDeleteSubproduct',
    ...operationOptions,
  });
}

/**
 * __useBackendMenuDeleteSubproductMutation__
 *
 * To run a mutation, you first call `useBackendMenuDeleteSubproductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBackendMenuDeleteSubproductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [backendMenuDeleteSubproductMutation, { data, loading, error }] = useBackendMenuDeleteSubproductMutation({
 *   variables: {
 *      brandId: // value for 'brandId'
 *      companyId: // value for 'companyId'
 *      productId: // value for 'productId'
 *      subproductId: // value for 'subproductId'
 *   },
 * });
 */
export function useBackendMenuDeleteSubproductMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BackendMenuDeleteSubproductMutation,
    BackendMenuDeleteSubproductMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    BackendMenuDeleteSubproductMutation,
    BackendMenuDeleteSubproductMutationVariables
  >(BackendMenuDeleteSubproductDocument, baseOptions);
}
export type BackendMenuDeleteSubproductMutationHookResult = ReturnType<
  typeof useBackendMenuDeleteSubproductMutation
>;
export type BackendMenuDeleteSubproductMutationResult =
  ApolloReactCommon.MutationResult<BackendMenuDeleteSubproductMutation>;
export type BackendMenuDeleteSubproductMutationOptions = ApolloReactCommon.BaseMutationOptions<
  BackendMenuDeleteSubproductMutation,
  BackendMenuDeleteSubproductMutationVariables
>;
export const BackendMenuReorderProductDocument = gql`
  mutation BackendMenuReorderProduct(
    $brandId: Int!
    $companyId: Int!
    $productId: Int!
    $sequence: Int!
  ) {
    backendMenuReorderProduct(
      brandId: $brandId
      companyId: $companyId
      productId: $productId
      sequence: $sequence
    ) {
      success
    }
  }
`;
export type BackendMenuReorderProductMutationFn = ApolloReactCommon.MutationFunction<
  BackendMenuReorderProductMutation,
  BackendMenuReorderProductMutationVariables
>;
export type BackendMenuReorderProductComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    BackendMenuReorderProductMutation,
    BackendMenuReorderProductMutationVariables
  >,
  'mutation'
>;

export const BackendMenuReorderProductComponent = (
  props: BackendMenuReorderProductComponentProps
) => (
  <ApolloReactComponents.Mutation<
    BackendMenuReorderProductMutation,
    BackendMenuReorderProductMutationVariables
  >
    mutation={BackendMenuReorderProductDocument}
    {...props}
  />
);

export type BackendMenuReorderProductProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    BackendMenuReorderProductMutation,
    BackendMenuReorderProductMutationVariables
  >;
} & TChildProps;
export function withBackendMenuReorderProduct<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    BackendMenuReorderProductMutation,
    BackendMenuReorderProductMutationVariables,
    BackendMenuReorderProductProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    BackendMenuReorderProductMutation,
    BackendMenuReorderProductMutationVariables,
    BackendMenuReorderProductProps<TChildProps, TDataName>
  >(BackendMenuReorderProductDocument, {
    alias: 'backendMenuReorderProduct',
    ...operationOptions,
  });
}

/**
 * __useBackendMenuReorderProductMutation__
 *
 * To run a mutation, you first call `useBackendMenuReorderProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBackendMenuReorderProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [backendMenuReorderProductMutation, { data, loading, error }] = useBackendMenuReorderProductMutation({
 *   variables: {
 *      brandId: // value for 'brandId'
 *      companyId: // value for 'companyId'
 *      productId: // value for 'productId'
 *      sequence: // value for 'sequence'
 *   },
 * });
 */
export function useBackendMenuReorderProductMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BackendMenuReorderProductMutation,
    BackendMenuReorderProductMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    BackendMenuReorderProductMutation,
    BackendMenuReorderProductMutationVariables
  >(BackendMenuReorderProductDocument, baseOptions);
}
export type BackendMenuReorderProductMutationHookResult = ReturnType<
  typeof useBackendMenuReorderProductMutation
>;
export type BackendMenuReorderProductMutationResult =
  ApolloReactCommon.MutationResult<BackendMenuReorderProductMutation>;
export type BackendMenuReorderProductMutationOptions = ApolloReactCommon.BaseMutationOptions<
  BackendMenuReorderProductMutation,
  BackendMenuReorderProductMutationVariables
>;
export const BackendMenuUpsertItemDocument = gql`
  mutation BackendMenuUpsertItem(
    $brandId: Int!
    $companyId: Int!
    $backendMenuUpsertItemInput: BackendMenuUpsertItemInput!
    $imageFile: Upload
    $imageInput: CroppedPictureInput
  ) {
    backendMenuUpsertItem(
      brandId: $brandId
      companyId: $companyId
      backendMenuUpsertItemInput: $backendMenuUpsertItemInput
      imageFile: $imageFile
      imageInput: $imageInput
    ) {
      product {
        id
        numericalId
      }
    }
  }
`;
export type BackendMenuUpsertItemMutationFn = ApolloReactCommon.MutationFunction<
  BackendMenuUpsertItemMutation,
  BackendMenuUpsertItemMutationVariables
>;
export type BackendMenuUpsertItemComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    BackendMenuUpsertItemMutation,
    BackendMenuUpsertItemMutationVariables
  >,
  'mutation'
>;

export const BackendMenuUpsertItemComponent = (props: BackendMenuUpsertItemComponentProps) => (
  <ApolloReactComponents.Mutation<
    BackendMenuUpsertItemMutation,
    BackendMenuUpsertItemMutationVariables
  >
    mutation={BackendMenuUpsertItemDocument}
    {...props}
  />
);

export type BackendMenuUpsertItemProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    BackendMenuUpsertItemMutation,
    BackendMenuUpsertItemMutationVariables
  >;
} & TChildProps;
export function withBackendMenuUpsertItem<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    BackendMenuUpsertItemMutation,
    BackendMenuUpsertItemMutationVariables,
    BackendMenuUpsertItemProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    BackendMenuUpsertItemMutation,
    BackendMenuUpsertItemMutationVariables,
    BackendMenuUpsertItemProps<TChildProps, TDataName>
  >(BackendMenuUpsertItemDocument, {
    alias: 'backendMenuUpsertItem',
    ...operationOptions,
  });
}

/**
 * __useBackendMenuUpsertItemMutation__
 *
 * To run a mutation, you first call `useBackendMenuUpsertItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBackendMenuUpsertItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [backendMenuUpsertItemMutation, { data, loading, error }] = useBackendMenuUpsertItemMutation({
 *   variables: {
 *      brandId: // value for 'brandId'
 *      companyId: // value for 'companyId'
 *      backendMenuUpsertItemInput: // value for 'backendMenuUpsertItemInput'
 *      imageFile: // value for 'imageFile'
 *      imageInput: // value for 'imageInput'
 *   },
 * });
 */
export function useBackendMenuUpsertItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BackendMenuUpsertItemMutation,
    BackendMenuUpsertItemMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    BackendMenuUpsertItemMutation,
    BackendMenuUpsertItemMutationVariables
  >(BackendMenuUpsertItemDocument, baseOptions);
}
export type BackendMenuUpsertItemMutationHookResult = ReturnType<
  typeof useBackendMenuUpsertItemMutation
>;
export type BackendMenuUpsertItemMutationResult =
  ApolloReactCommon.MutationResult<BackendMenuUpsertItemMutation>;
export type BackendMenuUpsertItemMutationOptions = ApolloReactCommon.BaseMutationOptions<
  BackendMenuUpsertItemMutation,
  BackendMenuUpsertItemMutationVariables
>;
export const DigitalMenuUpsertItemDocument = gql`
  mutation DigitalMenuUpsertItem(
    $brandId: Int!
    $companyId: Int!
    $menuCreateOrUpdateItemInput: MenuCreateOrUpdateItemInput!
  ) {
    menuCreateOrUpdateItem(
      brandId: $brandId
      companyId: $companyId
      menuCreateOrUpdateItemInput: $menuCreateOrUpdateItemInput
    ) {
      menuProduct {
        id
      }
    }
  }
`;
export type DigitalMenuUpsertItemMutationFn = ApolloReactCommon.MutationFunction<
  DigitalMenuUpsertItemMutation,
  DigitalMenuUpsertItemMutationVariables
>;
export type DigitalMenuUpsertItemComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DigitalMenuUpsertItemMutation,
    DigitalMenuUpsertItemMutationVariables
  >,
  'mutation'
>;

export const DigitalMenuUpsertItemComponent = (props: DigitalMenuUpsertItemComponentProps) => (
  <ApolloReactComponents.Mutation<
    DigitalMenuUpsertItemMutation,
    DigitalMenuUpsertItemMutationVariables
  >
    mutation={DigitalMenuUpsertItemDocument}
    {...props}
  />
);

export type DigitalMenuUpsertItemProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    DigitalMenuUpsertItemMutation,
    DigitalMenuUpsertItemMutationVariables
  >;
} & TChildProps;
export function withDigitalMenuUpsertItem<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DigitalMenuUpsertItemMutation,
    DigitalMenuUpsertItemMutationVariables,
    DigitalMenuUpsertItemProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DigitalMenuUpsertItemMutation,
    DigitalMenuUpsertItemMutationVariables,
    DigitalMenuUpsertItemProps<TChildProps, TDataName>
  >(DigitalMenuUpsertItemDocument, {
    alias: 'digitalMenuUpsertItem',
    ...operationOptions,
  });
}

/**
 * __useDigitalMenuUpsertItemMutation__
 *
 * To run a mutation, you first call `useDigitalMenuUpsertItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDigitalMenuUpsertItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [digitalMenuUpsertItemMutation, { data, loading, error }] = useDigitalMenuUpsertItemMutation({
 *   variables: {
 *      brandId: // value for 'brandId'
 *      companyId: // value for 'companyId'
 *      menuCreateOrUpdateItemInput: // value for 'menuCreateOrUpdateItemInput'
 *   },
 * });
 */
export function useDigitalMenuUpsertItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DigitalMenuUpsertItemMutation,
    DigitalMenuUpsertItemMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DigitalMenuUpsertItemMutation,
    DigitalMenuUpsertItemMutationVariables
  >(DigitalMenuUpsertItemDocument, baseOptions);
}
export type DigitalMenuUpsertItemMutationHookResult = ReturnType<
  typeof useDigitalMenuUpsertItemMutation
>;
export type DigitalMenuUpsertItemMutationResult =
  ApolloReactCommon.MutationResult<DigitalMenuUpsertItemMutation>;
export type DigitalMenuUpsertItemMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DigitalMenuUpsertItemMutation,
  DigitalMenuUpsertItemMutationVariables
>;
export const SaveMenuCsvDocument = gql`
  mutation SaveMenuCsv($file: Upload!, $brandId: Int!, $companyId: Int!) {
    menuCsv(file: $file, brandId: $brandId, companyId: $companyId) {
      valid
      data
    }
  }
`;
export type SaveMenuCsvMutationFn = ApolloReactCommon.MutationFunction<
  SaveMenuCsvMutation,
  SaveMenuCsvMutationVariables
>;
export type SaveMenuCsvComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<SaveMenuCsvMutation, SaveMenuCsvMutationVariables>,
  'mutation'
>;

export const SaveMenuCsvComponent = (props: SaveMenuCsvComponentProps) => (
  <ApolloReactComponents.Mutation<SaveMenuCsvMutation, SaveMenuCsvMutationVariables>
    mutation={SaveMenuCsvDocument}
    {...props}
  />
);

export type SaveMenuCsvProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    SaveMenuCsvMutation,
    SaveMenuCsvMutationVariables
  >;
} & TChildProps;
export function withSaveMenuCsv<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SaveMenuCsvMutation,
    SaveMenuCsvMutationVariables,
    SaveMenuCsvProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SaveMenuCsvMutation,
    SaveMenuCsvMutationVariables,
    SaveMenuCsvProps<TChildProps, TDataName>
  >(SaveMenuCsvDocument, {
    alias: 'saveMenuCsv',
    ...operationOptions,
  });
}

/**
 * __useSaveMenuCsvMutation__
 *
 * To run a mutation, you first call `useSaveMenuCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveMenuCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveMenuCsvMutation, { data, loading, error }] = useSaveMenuCsvMutation({
 *   variables: {
 *      file: // value for 'file'
 *      brandId: // value for 'brandId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useSaveMenuCsvMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveMenuCsvMutation,
    SaveMenuCsvMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<SaveMenuCsvMutation, SaveMenuCsvMutationVariables>(
    SaveMenuCsvDocument,
    baseOptions
  );
}
export type SaveMenuCsvMutationHookResult = ReturnType<typeof useSaveMenuCsvMutation>;
export type SaveMenuCsvMutationResult = ApolloReactCommon.MutationResult<SaveMenuCsvMutation>;
export type SaveMenuCsvMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveMenuCsvMutation,
  SaveMenuCsvMutationVariables
>;
export const MenuDeleteItemDocument = gql`
  mutation MenuDeleteItem(
    $brandId: Int!
    $companyId: Int!
    $menuCategoryId: Int
    $menuProductId: Int
  ) {
    menuDeleteItem(
      brandId: $brandId
      companyId: $companyId
      menuCategoryId: $menuCategoryId
      menuProductId: $menuProductId
    ) {
      menuProduct {
        id
      }
    }
  }
`;
export type MenuDeleteItemMutationFn = ApolloReactCommon.MutationFunction<
  MenuDeleteItemMutation,
  MenuDeleteItemMutationVariables
>;
export type MenuDeleteItemComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    MenuDeleteItemMutation,
    MenuDeleteItemMutationVariables
  >,
  'mutation'
>;

export const MenuDeleteItemComponent = (props: MenuDeleteItemComponentProps) => (
  <ApolloReactComponents.Mutation<MenuDeleteItemMutation, MenuDeleteItemMutationVariables>
    mutation={MenuDeleteItemDocument}
    {...props}
  />
);

export type MenuDeleteItemProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    MenuDeleteItemMutation,
    MenuDeleteItemMutationVariables
  >;
} & TChildProps;
export function withMenuDeleteItem<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MenuDeleteItemMutation,
    MenuDeleteItemMutationVariables,
    MenuDeleteItemProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    MenuDeleteItemMutation,
    MenuDeleteItemMutationVariables,
    MenuDeleteItemProps<TChildProps, TDataName>
  >(MenuDeleteItemDocument, {
    alias: 'menuDeleteItem',
    ...operationOptions,
  });
}

/**
 * __useMenuDeleteItemMutation__
 *
 * To run a mutation, you first call `useMenuDeleteItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMenuDeleteItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [menuDeleteItemMutation, { data, loading, error }] = useMenuDeleteItemMutation({
 *   variables: {
 *      brandId: // value for 'brandId'
 *      companyId: // value for 'companyId'
 *      menuCategoryId: // value for 'menuCategoryId'
 *      menuProductId: // value for 'menuProductId'
 *   },
 * });
 */
export function useMenuDeleteItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MenuDeleteItemMutation,
    MenuDeleteItemMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<MenuDeleteItemMutation, MenuDeleteItemMutationVariables>(
    MenuDeleteItemDocument,
    baseOptions
  );
}
export type MenuDeleteItemMutationHookResult = ReturnType<typeof useMenuDeleteItemMutation>;
export type MenuDeleteItemMutationResult = ApolloReactCommon.MutationResult<MenuDeleteItemMutation>;
export type MenuDeleteItemMutationOptions = ApolloReactCommon.BaseMutationOptions<
  MenuDeleteItemMutation,
  MenuDeleteItemMutationVariables
>;
export const PublishMenuDocument = gql`
  mutation PublishMenu($companyId: Int!) {
    menuPublish(companyId: $companyId) {
      valid
    }
  }
`;
export type PublishMenuMutationFn = ApolloReactCommon.MutationFunction<
  PublishMenuMutation,
  PublishMenuMutationVariables
>;
export type PublishMenuComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<PublishMenuMutation, PublishMenuMutationVariables>,
  'mutation'
>;

export const PublishMenuComponent = (props: PublishMenuComponentProps) => (
  <ApolloReactComponents.Mutation<PublishMenuMutation, PublishMenuMutationVariables>
    mutation={PublishMenuDocument}
    {...props}
  />
);

export type PublishMenuProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    PublishMenuMutation,
    PublishMenuMutationVariables
  >;
} & TChildProps;
export function withPublishMenu<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PublishMenuMutation,
    PublishMenuMutationVariables,
    PublishMenuProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    PublishMenuMutation,
    PublishMenuMutationVariables,
    PublishMenuProps<TChildProps, TDataName>
  >(PublishMenuDocument, {
    alias: 'publishMenu',
    ...operationOptions,
  });
}

/**
 * __usePublishMenuMutation__
 *
 * To run a mutation, you first call `usePublishMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishMenuMutation, { data, loading, error }] = usePublishMenuMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function usePublishMenuMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PublishMenuMutation,
    PublishMenuMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<PublishMenuMutation, PublishMenuMutationVariables>(
    PublishMenuDocument,
    baseOptions
  );
}
export type PublishMenuMutationHookResult = ReturnType<typeof usePublishMenuMutation>;
export type PublishMenuMutationResult = ApolloReactCommon.MutationResult<PublishMenuMutation>;
export type PublishMenuMutationOptions = ApolloReactCommon.BaseMutationOptions<
  PublishMenuMutation,
  PublishMenuMutationVariables
>;
export const GetAllowedBrandsAndCompaniesDocument = gql`
  query GetAllowedBrandsAndCompanies {
    crmUser {
      userId
      allowedBrands {
        name
        numericalId
        company {
          edges {
            node {
              name
              numericalId
              isDigitalMenu
            }
          }
        }
      }
    }
  }
`;
export type GetAllowedBrandsAndCompaniesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetAllowedBrandsAndCompaniesQuery,
    GetAllowedBrandsAndCompaniesQueryVariables
  >,
  'query'
>;

export const GetAllowedBrandsAndCompaniesComponent = (
  props: GetAllowedBrandsAndCompaniesComponentProps
) => (
  <ApolloReactComponents.Query<
    GetAllowedBrandsAndCompaniesQuery,
    GetAllowedBrandsAndCompaniesQueryVariables
  >
    query={GetAllowedBrandsAndCompaniesDocument}
    {...props}
  />
);

export type GetAllowedBrandsAndCompaniesProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetAllowedBrandsAndCompaniesQuery,
    GetAllowedBrandsAndCompaniesQueryVariables
  >;
} & TChildProps;
export function withGetAllowedBrandsAndCompanies<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetAllowedBrandsAndCompaniesQuery,
    GetAllowedBrandsAndCompaniesQueryVariables,
    GetAllowedBrandsAndCompaniesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetAllowedBrandsAndCompaniesQuery,
    GetAllowedBrandsAndCompaniesQueryVariables,
    GetAllowedBrandsAndCompaniesProps<TChildProps, TDataName>
  >(GetAllowedBrandsAndCompaniesDocument, {
    alias: 'getAllowedBrandsAndCompanies',
    ...operationOptions,
  });
}

/**
 * __useGetAllowedBrandsAndCompaniesQuery__
 *
 * To run a query within a React component, call `useGetAllowedBrandsAndCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllowedBrandsAndCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllowedBrandsAndCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllowedBrandsAndCompaniesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAllowedBrandsAndCompaniesQuery,
    GetAllowedBrandsAndCompaniesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetAllowedBrandsAndCompaniesQuery,
    GetAllowedBrandsAndCompaniesQueryVariables
  >(GetAllowedBrandsAndCompaniesDocument, baseOptions);
}
export function useGetAllowedBrandsAndCompaniesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAllowedBrandsAndCompaniesQuery,
    GetAllowedBrandsAndCompaniesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetAllowedBrandsAndCompaniesQuery,
    GetAllowedBrandsAndCompaniesQueryVariables
  >(GetAllowedBrandsAndCompaniesDocument, baseOptions);
}
export type GetAllowedBrandsAndCompaniesQueryHookResult = ReturnType<
  typeof useGetAllowedBrandsAndCompaniesQuery
>;
export type GetAllowedBrandsAndCompaniesLazyQueryHookResult = ReturnType<
  typeof useGetAllowedBrandsAndCompaniesLazyQuery
>;
export type GetAllowedBrandsAndCompaniesQueryResult = ApolloReactCommon.QueryResult<
  GetAllowedBrandsAndCompaniesQuery,
  GetAllowedBrandsAndCompaniesQueryVariables
>;
export const GetBackendCompanyProductItemDocument = gql`
  query GetBackendCompanyProductItem($id: Int!, $companyId: Int!) {
    productCompanyById(id: $id, companyId: $companyId) {
      price
      product {
        name
        images {
          context
          url
          cropId
        }
        numericalId
        shortDescription
        category {
          name
        }
        subproducts {
          ...product
          subproducts {
            ...product
          }
        }
      }
    }
  }
  ${ProductFragmentDoc}
`;
export type GetBackendCompanyProductItemComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetBackendCompanyProductItemQuery,
    GetBackendCompanyProductItemQueryVariables
  >,
  'query'
> &
  ({ variables: GetBackendCompanyProductItemQueryVariables; skip?: boolean } | { skip: boolean });

export const GetBackendCompanyProductItemComponent = (
  props: GetBackendCompanyProductItemComponentProps
) => (
  <ApolloReactComponents.Query<
    GetBackendCompanyProductItemQuery,
    GetBackendCompanyProductItemQueryVariables
  >
    query={GetBackendCompanyProductItemDocument}
    {...props}
  />
);

export type GetBackendCompanyProductItemProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetBackendCompanyProductItemQuery,
    GetBackendCompanyProductItemQueryVariables
  >;
} & TChildProps;
export function withGetBackendCompanyProductItem<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetBackendCompanyProductItemQuery,
    GetBackendCompanyProductItemQueryVariables,
    GetBackendCompanyProductItemProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetBackendCompanyProductItemQuery,
    GetBackendCompanyProductItemQueryVariables,
    GetBackendCompanyProductItemProps<TChildProps, TDataName>
  >(GetBackendCompanyProductItemDocument, {
    alias: 'getBackendCompanyProductItem',
    ...operationOptions,
  });
}

/**
 * __useGetBackendCompanyProductItemQuery__
 *
 * To run a query within a React component, call `useGetBackendCompanyProductItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBackendCompanyProductItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBackendCompanyProductItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetBackendCompanyProductItemQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetBackendCompanyProductItemQuery,
    GetBackendCompanyProductItemQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetBackendCompanyProductItemQuery,
    GetBackendCompanyProductItemQueryVariables
  >(GetBackendCompanyProductItemDocument, baseOptions);
}
export function useGetBackendCompanyProductItemLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetBackendCompanyProductItemQuery,
    GetBackendCompanyProductItemQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetBackendCompanyProductItemQuery,
    GetBackendCompanyProductItemQueryVariables
  >(GetBackendCompanyProductItemDocument, baseOptions);
}
export type GetBackendCompanyProductItemQueryHookResult = ReturnType<
  typeof useGetBackendCompanyProductItemQuery
>;
export type GetBackendCompanyProductItemLazyQueryHookResult = ReturnType<
  typeof useGetBackendCompanyProductItemLazyQuery
>;
export type GetBackendCompanyProductItemQueryResult = ApolloReactCommon.QueryResult<
  GetBackendCompanyProductItemQuery,
  GetBackendCompanyProductItemQueryVariables
>;
export const GetMenuCompanyInfoDocument = gql`
  query GetMenuCompanyInfo($companyId: Int!, $showHiddenCompany: Boolean) {
    company(companyId: $companyId, showHiddenCompany: $showHiddenCompany) {
      name
      brand {
        images {
          url
          context
        }
      }
      aggregator {
        numericalId
      }
    }
  }
`;
export type GetMenuCompanyInfoComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetMenuCompanyInfoQuery,
    GetMenuCompanyInfoQueryVariables
  >,
  'query'
> &
  ({ variables: GetMenuCompanyInfoQueryVariables; skip?: boolean } | { skip: boolean });

export const GetMenuCompanyInfoComponent = (props: GetMenuCompanyInfoComponentProps) => (
  <ApolloReactComponents.Query<GetMenuCompanyInfoQuery, GetMenuCompanyInfoQueryVariables>
    query={GetMenuCompanyInfoDocument}
    {...props}
  />
);

export type GetMenuCompanyInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetMenuCompanyInfoQuery,
    GetMenuCompanyInfoQueryVariables
  >;
} & TChildProps;
export function withGetMenuCompanyInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetMenuCompanyInfoQuery,
    GetMenuCompanyInfoQueryVariables,
    GetMenuCompanyInfoProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetMenuCompanyInfoQuery,
    GetMenuCompanyInfoQueryVariables,
    GetMenuCompanyInfoProps<TChildProps, TDataName>
  >(GetMenuCompanyInfoDocument, {
    alias: 'getMenuCompanyInfo',
    ...operationOptions,
  });
}

/**
 * __useGetMenuCompanyInfoQuery__
 *
 * To run a query within a React component, call `useGetMenuCompanyInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuCompanyInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuCompanyInfoQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      showHiddenCompany: // value for 'showHiddenCompany'
 *   },
 * });
 */
export function useGetMenuCompanyInfoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetMenuCompanyInfoQuery,
    GetMenuCompanyInfoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetMenuCompanyInfoQuery, GetMenuCompanyInfoQueryVariables>(
    GetMenuCompanyInfoDocument,
    baseOptions
  );
}
export function useGetMenuCompanyInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMenuCompanyInfoQuery,
    GetMenuCompanyInfoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetMenuCompanyInfoQuery, GetMenuCompanyInfoQueryVariables>(
    GetMenuCompanyInfoDocument,
    baseOptions
  );
}
export type GetMenuCompanyInfoQueryHookResult = ReturnType<typeof useGetMenuCompanyInfoQuery>;
export type GetMenuCompanyInfoLazyQueryHookResult = ReturnType<
  typeof useGetMenuCompanyInfoLazyQuery
>;
export type GetMenuCompanyInfoQueryResult = ApolloReactCommon.QueryResult<
  GetMenuCompanyInfoQuery,
  GetMenuCompanyInfoQueryVariables
>;
export const GetMenuCompanyProductItemDocument = gql`
  query GetMenuCompanyProductItem($id: Int!, $companyId: Int!) {
    menuProductCompanyById(id: $id, companyId: $companyId) {
      price
      product {
        name
        numericalId
        shortDescription
        category {
          name
        }
      }
    }
  }
`;
export type GetMenuCompanyProductItemComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetMenuCompanyProductItemQuery,
    GetMenuCompanyProductItemQueryVariables
  >,
  'query'
> &
  ({ variables: GetMenuCompanyProductItemQueryVariables; skip?: boolean } | { skip: boolean });

export const GetMenuCompanyProductItemComponent = (
  props: GetMenuCompanyProductItemComponentProps
) => (
  <ApolloReactComponents.Query<
    GetMenuCompanyProductItemQuery,
    GetMenuCompanyProductItemQueryVariables
  >
    query={GetMenuCompanyProductItemDocument}
    {...props}
  />
);

export type GetMenuCompanyProductItemProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetMenuCompanyProductItemQuery,
    GetMenuCompanyProductItemQueryVariables
  >;
} & TChildProps;
export function withGetMenuCompanyProductItem<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetMenuCompanyProductItemQuery,
    GetMenuCompanyProductItemQueryVariables,
    GetMenuCompanyProductItemProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetMenuCompanyProductItemQuery,
    GetMenuCompanyProductItemQueryVariables,
    GetMenuCompanyProductItemProps<TChildProps, TDataName>
  >(GetMenuCompanyProductItemDocument, {
    alias: 'getMenuCompanyProductItem',
    ...operationOptions,
  });
}

/**
 * __useGetMenuCompanyProductItemQuery__
 *
 * To run a query within a React component, call `useGetMenuCompanyProductItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuCompanyProductItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuCompanyProductItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetMenuCompanyProductItemQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetMenuCompanyProductItemQuery,
    GetMenuCompanyProductItemQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetMenuCompanyProductItemQuery,
    GetMenuCompanyProductItemQueryVariables
  >(GetMenuCompanyProductItemDocument, baseOptions);
}
export function useGetMenuCompanyProductItemLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMenuCompanyProductItemQuery,
    GetMenuCompanyProductItemQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetMenuCompanyProductItemQuery,
    GetMenuCompanyProductItemQueryVariables
  >(GetMenuCompanyProductItemDocument, baseOptions);
}
export type GetMenuCompanyProductItemQueryHookResult = ReturnType<
  typeof useGetMenuCompanyProductItemQuery
>;
export type GetMenuCompanyProductItemLazyQueryHookResult = ReturnType<
  typeof useGetMenuCompanyProductItemLazyQuery
>;
export type GetMenuCompanyProductItemQueryResult = ApolloReactCommon.QueryResult<
  GetMenuCompanyProductItemQuery,
  GetMenuCompanyProductItemQueryVariables
>;
export const GetMenuPublishStateDocument = gql`
  query GetMenuPublishState($companyId: Int!) {
    menuPublishState(companyId: $companyId)
  }
`;
export type GetMenuPublishStateComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetMenuPublishStateQuery,
    GetMenuPublishStateQueryVariables
  >,
  'query'
> &
  ({ variables: GetMenuPublishStateQueryVariables; skip?: boolean } | { skip: boolean });

export const GetMenuPublishStateComponent = (props: GetMenuPublishStateComponentProps) => (
  <ApolloReactComponents.Query<GetMenuPublishStateQuery, GetMenuPublishStateQueryVariables>
    query={GetMenuPublishStateDocument}
    {...props}
  />
);

export type GetMenuPublishStateProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetMenuPublishStateQuery,
    GetMenuPublishStateQueryVariables
  >;
} & TChildProps;
export function withGetMenuPublishState<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetMenuPublishStateQuery,
    GetMenuPublishStateQueryVariables,
    GetMenuPublishStateProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetMenuPublishStateQuery,
    GetMenuPublishStateQueryVariables,
    GetMenuPublishStateProps<TChildProps, TDataName>
  >(GetMenuPublishStateDocument, {
    alias: 'getMenuPublishState',
    ...operationOptions,
  });
}

/**
 * __useGetMenuPublishStateQuery__
 *
 * To run a query within a React component, call `useGetMenuPublishStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuPublishStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuPublishStateQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetMenuPublishStateQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetMenuPublishStateQuery,
    GetMenuPublishStateQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetMenuPublishStateQuery, GetMenuPublishStateQueryVariables>(
    GetMenuPublishStateDocument,
    baseOptions
  );
}
export function useGetMenuPublishStateLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMenuPublishStateQuery,
    GetMenuPublishStateQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetMenuPublishStateQuery, GetMenuPublishStateQueryVariables>(
    GetMenuPublishStateDocument,
    baseOptions
  );
}
export type GetMenuPublishStateQueryHookResult = ReturnType<typeof useGetMenuPublishStateQuery>;
export type GetMenuPublishStateLazyQueryHookResult = ReturnType<
  typeof useGetMenuPublishStateLazyQuery
>;
export type GetMenuPublishStateQueryResult = ApolloReactCommon.QueryResult<
  GetMenuPublishStateQuery,
  GetMenuPublishStateQueryVariables
>;
export const GetListBackendMenuCategoriesDocument = gql`
  query GetListBackendMenuCategories($brandId: Int!) {
    listCategories(brandId: $brandId) {
      edges {
        node {
          name
          order
          numericalId
        }
      }
    }
  }
`;
export type GetListBackendMenuCategoriesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetListBackendMenuCategoriesQuery,
    GetListBackendMenuCategoriesQueryVariables
  >,
  'query'
> &
  ({ variables: GetListBackendMenuCategoriesQueryVariables; skip?: boolean } | { skip: boolean });

export const GetListBackendMenuCategoriesComponent = (
  props: GetListBackendMenuCategoriesComponentProps
) => (
  <ApolloReactComponents.Query<
    GetListBackendMenuCategoriesQuery,
    GetListBackendMenuCategoriesQueryVariables
  >
    query={GetListBackendMenuCategoriesDocument}
    {...props}
  />
);

export type GetListBackendMenuCategoriesProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetListBackendMenuCategoriesQuery,
    GetListBackendMenuCategoriesQueryVariables
  >;
} & TChildProps;
export function withGetListBackendMenuCategories<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetListBackendMenuCategoriesQuery,
    GetListBackendMenuCategoriesQueryVariables,
    GetListBackendMenuCategoriesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetListBackendMenuCategoriesQuery,
    GetListBackendMenuCategoriesQueryVariables,
    GetListBackendMenuCategoriesProps<TChildProps, TDataName>
  >(GetListBackendMenuCategoriesDocument, {
    alias: 'getListBackendMenuCategories',
    ...operationOptions,
  });
}

/**
 * __useGetListBackendMenuCategoriesQuery__
 *
 * To run a query within a React component, call `useGetListBackendMenuCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListBackendMenuCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListBackendMenuCategoriesQuery({
 *   variables: {
 *      brandId: // value for 'brandId'
 *   },
 * });
 */
export function useGetListBackendMenuCategoriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetListBackendMenuCategoriesQuery,
    GetListBackendMenuCategoriesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetListBackendMenuCategoriesQuery,
    GetListBackendMenuCategoriesQueryVariables
  >(GetListBackendMenuCategoriesDocument, baseOptions);
}
export function useGetListBackendMenuCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetListBackendMenuCategoriesQuery,
    GetListBackendMenuCategoriesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetListBackendMenuCategoriesQuery,
    GetListBackendMenuCategoriesQueryVariables
  >(GetListBackendMenuCategoriesDocument, baseOptions);
}
export type GetListBackendMenuCategoriesQueryHookResult = ReturnType<
  typeof useGetListBackendMenuCategoriesQuery
>;
export type GetListBackendMenuCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetListBackendMenuCategoriesLazyQuery
>;
export type GetListBackendMenuCategoriesQueryResult = ApolloReactCommon.QueryResult<
  GetListBackendMenuCategoriesQuery,
  GetListBackendMenuCategoriesQueryVariables
>;
export const GetCompanyProductsDocument = gql`
  query GetCompanyProducts(
    $companyId: Int!
    $first: Int
    $cursor: String
    $productType: ProductType
    $productHidden: Boolean
    $unavailable: Boolean
    $productNameStartsWith: String
    $categoryId: Int
  ) {
    productCompanies(
      companyId: $companyId
      first: $first
      after: $cursor
      productType: $productType
      productHidden: $productHidden
      unavailable: $unavailable
      productNameStartsWith: $productNameStartsWith
      categoryId: $categoryId
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        node {
          price
          numericalId
          product {
            name
            numericalId
            sequence
            category {
              name
              numericalId
            }
            images {
              context
              url
            }
          }
        }
      }
    }
  }
`;
export type GetCompanyProductsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetCompanyProductsQuery,
    GetCompanyProductsQueryVariables
  >,
  'query'
> &
  ({ variables: GetCompanyProductsQueryVariables; skip?: boolean } | { skip: boolean });

export const GetCompanyProductsComponent = (props: GetCompanyProductsComponentProps) => (
  <ApolloReactComponents.Query<GetCompanyProductsQuery, GetCompanyProductsQueryVariables>
    query={GetCompanyProductsDocument}
    {...props}
  />
);

export type GetCompanyProductsProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetCompanyProductsQuery,
    GetCompanyProductsQueryVariables
  >;
} & TChildProps;
export function withGetCompanyProducts<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetCompanyProductsQuery,
    GetCompanyProductsQueryVariables,
    GetCompanyProductsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetCompanyProductsQuery,
    GetCompanyProductsQueryVariables,
    GetCompanyProductsProps<TChildProps, TDataName>
  >(GetCompanyProductsDocument, {
    alias: 'getCompanyProducts',
    ...operationOptions,
  });
}

/**
 * __useGetCompanyProductsQuery__
 *
 * To run a query within a React component, call `useGetCompanyProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyProductsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *      productType: // value for 'productType'
 *      productHidden: // value for 'productHidden'
 *      unavailable: // value for 'unavailable'
 *      productNameStartsWith: // value for 'productNameStartsWith'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useGetCompanyProductsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCompanyProductsQuery,
    GetCompanyProductsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetCompanyProductsQuery, GetCompanyProductsQueryVariables>(
    GetCompanyProductsDocument,
    baseOptions
  );
}
export function useGetCompanyProductsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCompanyProductsQuery,
    GetCompanyProductsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetCompanyProductsQuery, GetCompanyProductsQueryVariables>(
    GetCompanyProductsDocument,
    baseOptions
  );
}
export type GetCompanyProductsQueryHookResult = ReturnType<typeof useGetCompanyProductsQuery>;
export type GetCompanyProductsLazyQueryHookResult = ReturnType<
  typeof useGetCompanyProductsLazyQuery
>;
export type GetCompanyProductsQueryResult = ApolloReactCommon.QueryResult<
  GetCompanyProductsQuery,
  GetCompanyProductsQueryVariables
>;
export const GetDigitalMenuCompanyProductsDocument = gql`
  query GetDigitalMenuCompanyProducts(
    $companyId: Int!
    $first: Int
    $cursor: String
    $productNameStartsWith: String
  ) {
    listMenuCompanyProducts(
      companyId: $companyId
      first: $first
      after: $cursor
      productNameStartsWith: $productNameStartsWith
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        node {
          price
          numericalId
          product {
            name
            numericalId
            updatedAt
            category {
              name
            }
          }
        }
      }
    }
  }
`;
export type GetDigitalMenuCompanyProductsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetDigitalMenuCompanyProductsQuery,
    GetDigitalMenuCompanyProductsQueryVariables
  >,
  'query'
> &
  ({ variables: GetDigitalMenuCompanyProductsQueryVariables; skip?: boolean } | { skip: boolean });

export const GetDigitalMenuCompanyProductsComponent = (
  props: GetDigitalMenuCompanyProductsComponentProps
) => (
  <ApolloReactComponents.Query<
    GetDigitalMenuCompanyProductsQuery,
    GetDigitalMenuCompanyProductsQueryVariables
  >
    query={GetDigitalMenuCompanyProductsDocument}
    {...props}
  />
);

export type GetDigitalMenuCompanyProductsProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetDigitalMenuCompanyProductsQuery,
    GetDigitalMenuCompanyProductsQueryVariables
  >;
} & TChildProps;
export function withGetDigitalMenuCompanyProducts<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetDigitalMenuCompanyProductsQuery,
    GetDigitalMenuCompanyProductsQueryVariables,
    GetDigitalMenuCompanyProductsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetDigitalMenuCompanyProductsQuery,
    GetDigitalMenuCompanyProductsQueryVariables,
    GetDigitalMenuCompanyProductsProps<TChildProps, TDataName>
  >(GetDigitalMenuCompanyProductsDocument, {
    alias: 'getDigitalMenuCompanyProducts',
    ...operationOptions,
  });
}

/**
 * __useGetDigitalMenuCompanyProductsQuery__
 *
 * To run a query within a React component, call `useGetDigitalMenuCompanyProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDigitalMenuCompanyProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDigitalMenuCompanyProductsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *      productNameStartsWith: // value for 'productNameStartsWith'
 *   },
 * });
 */
export function useGetDigitalMenuCompanyProductsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetDigitalMenuCompanyProductsQuery,
    GetDigitalMenuCompanyProductsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetDigitalMenuCompanyProductsQuery,
    GetDigitalMenuCompanyProductsQueryVariables
  >(GetDigitalMenuCompanyProductsDocument, baseOptions);
}
export function useGetDigitalMenuCompanyProductsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetDigitalMenuCompanyProductsQuery,
    GetDigitalMenuCompanyProductsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetDigitalMenuCompanyProductsQuery,
    GetDigitalMenuCompanyProductsQueryVariables
  >(GetDigitalMenuCompanyProductsDocument, baseOptions);
}
export type GetDigitalMenuCompanyProductsQueryHookResult = ReturnType<
  typeof useGetDigitalMenuCompanyProductsQuery
>;
export type GetDigitalMenuCompanyProductsLazyQueryHookResult = ReturnType<
  typeof useGetDigitalMenuCompanyProductsLazyQuery
>;
export type GetDigitalMenuCompanyProductsQueryResult = ApolloReactCommon.QueryResult<
  GetDigitalMenuCompanyProductsQuery,
  GetDigitalMenuCompanyProductsQueryVariables
>;
export const GetListMenuCategoriesDocument = gql`
  query GetListMenuCategories($brandId: Int!, $first: Int, $cursor: String) {
    listMenuCategories(brandId: $brandId, first: $first, after: $cursor) {
      edges {
        node {
          name
          numericalId
        }
      }
    }
  }
`;
export type GetListMenuCategoriesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetListMenuCategoriesQuery,
    GetListMenuCategoriesQueryVariables
  >,
  'query'
> &
  ({ variables: GetListMenuCategoriesQueryVariables; skip?: boolean } | { skip: boolean });

export const GetListMenuCategoriesComponent = (props: GetListMenuCategoriesComponentProps) => (
  <ApolloReactComponents.Query<GetListMenuCategoriesQuery, GetListMenuCategoriesQueryVariables>
    query={GetListMenuCategoriesDocument}
    {...props}
  />
);

export type GetListMenuCategoriesProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetListMenuCategoriesQuery,
    GetListMenuCategoriesQueryVariables
  >;
} & TChildProps;
export function withGetListMenuCategories<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetListMenuCategoriesQuery,
    GetListMenuCategoriesQueryVariables,
    GetListMenuCategoriesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetListMenuCategoriesQuery,
    GetListMenuCategoriesQueryVariables,
    GetListMenuCategoriesProps<TChildProps, TDataName>
  >(GetListMenuCategoriesDocument, {
    alias: 'getListMenuCategories',
    ...operationOptions,
  });
}

/**
 * __useGetListMenuCategoriesQuery__
 *
 * To run a query within a React component, call `useGetListMenuCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListMenuCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListMenuCategoriesQuery({
 *   variables: {
 *      brandId: // value for 'brandId'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetListMenuCategoriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetListMenuCategoriesQuery,
    GetListMenuCategoriesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetListMenuCategoriesQuery, GetListMenuCategoriesQueryVariables>(
    GetListMenuCategoriesDocument,
    baseOptions
  );
}
export function useGetListMenuCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetListMenuCategoriesQuery,
    GetListMenuCategoriesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetListMenuCategoriesQuery,
    GetListMenuCategoriesQueryVariables
  >(GetListMenuCategoriesDocument, baseOptions);
}
export type GetListMenuCategoriesQueryHookResult = ReturnType<typeof useGetListMenuCategoriesQuery>;
export type GetListMenuCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetListMenuCategoriesLazyQuery
>;
export type GetListMenuCategoriesQueryResult = ApolloReactCommon.QueryResult<
  GetListMenuCategoriesQuery,
  GetListMenuCategoriesQueryVariables
>;
export const CompanyWeekdayTimeRulesDocument = gql`
  mutation CompanyWeekdayTimeRules(
    $brandId: Int!
    $companyId: Int!
    $input: [CompanyWeekdayTimeRulesInput]!
  ) {
    companyWeekdayTimeRules(brandId: $brandId, companyId: $companyId, input: $input) {
      error
    }
  }
`;
export type CompanyWeekdayTimeRulesMutationFn = ApolloReactCommon.MutationFunction<
  CompanyWeekdayTimeRulesMutation,
  CompanyWeekdayTimeRulesMutationVariables
>;
export type CompanyWeekdayTimeRulesComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CompanyWeekdayTimeRulesMutation,
    CompanyWeekdayTimeRulesMutationVariables
  >,
  'mutation'
>;

export const CompanyWeekdayTimeRulesComponent = (props: CompanyWeekdayTimeRulesComponentProps) => (
  <ApolloReactComponents.Mutation<
    CompanyWeekdayTimeRulesMutation,
    CompanyWeekdayTimeRulesMutationVariables
  >
    mutation={CompanyWeekdayTimeRulesDocument}
    {...props}
  />
);

export type CompanyWeekdayTimeRulesProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    CompanyWeekdayTimeRulesMutation,
    CompanyWeekdayTimeRulesMutationVariables
  >;
} & TChildProps;
export function withCompanyWeekdayTimeRules<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CompanyWeekdayTimeRulesMutation,
    CompanyWeekdayTimeRulesMutationVariables,
    CompanyWeekdayTimeRulesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CompanyWeekdayTimeRulesMutation,
    CompanyWeekdayTimeRulesMutationVariables,
    CompanyWeekdayTimeRulesProps<TChildProps, TDataName>
  >(CompanyWeekdayTimeRulesDocument, {
    alias: 'companyWeekdayTimeRules',
    ...operationOptions,
  });
}

/**
 * __useCompanyWeekdayTimeRulesMutation__
 *
 * To run a mutation, you first call `useCompanyWeekdayTimeRulesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyWeekdayTimeRulesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyWeekdayTimeRulesMutation, { data, loading, error }] = useCompanyWeekdayTimeRulesMutation({
 *   variables: {
 *      brandId: // value for 'brandId'
 *      companyId: // value for 'companyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyWeekdayTimeRulesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CompanyWeekdayTimeRulesMutation,
    CompanyWeekdayTimeRulesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CompanyWeekdayTimeRulesMutation,
    CompanyWeekdayTimeRulesMutationVariables
  >(CompanyWeekdayTimeRulesDocument, baseOptions);
}
export type CompanyWeekdayTimeRulesMutationHookResult = ReturnType<
  typeof useCompanyWeekdayTimeRulesMutation
>;
export type CompanyWeekdayTimeRulesMutationResult =
  ApolloReactCommon.MutationResult<CompanyWeekdayTimeRulesMutation>;
export type CompanyWeekdayTimeRulesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CompanyWeekdayTimeRulesMutation,
  CompanyWeekdayTimeRulesMutationVariables
>;
export const GetCompanyTimeRulesDocument = gql`
  query GetCompanyTimeRules($companyId: Int!, $showHiddenCompany: Boolean) {
    company(companyId: $companyId, showHiddenCompany: $showHiddenCompany) {
      brandId
      openingTimes {
        ...timeRule
      }
    }
  }
  ${TimeRuleFragmentDoc}
`;
export type GetCompanyTimeRulesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetCompanyTimeRulesQuery,
    GetCompanyTimeRulesQueryVariables
  >,
  'query'
> &
  ({ variables: GetCompanyTimeRulesQueryVariables; skip?: boolean } | { skip: boolean });

export const GetCompanyTimeRulesComponent = (props: GetCompanyTimeRulesComponentProps) => (
  <ApolloReactComponents.Query<GetCompanyTimeRulesQuery, GetCompanyTimeRulesQueryVariables>
    query={GetCompanyTimeRulesDocument}
    {...props}
  />
);

export type GetCompanyTimeRulesProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetCompanyTimeRulesQuery,
    GetCompanyTimeRulesQueryVariables
  >;
} & TChildProps;
export function withGetCompanyTimeRules<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetCompanyTimeRulesQuery,
    GetCompanyTimeRulesQueryVariables,
    GetCompanyTimeRulesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetCompanyTimeRulesQuery,
    GetCompanyTimeRulesQueryVariables,
    GetCompanyTimeRulesProps<TChildProps, TDataName>
  >(GetCompanyTimeRulesDocument, {
    alias: 'getCompanyTimeRules',
    ...operationOptions,
  });
}

/**
 * __useGetCompanyTimeRulesQuery__
 *
 * To run a query within a React component, call `useGetCompanyTimeRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyTimeRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyTimeRulesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      showHiddenCompany: // value for 'showHiddenCompany'
 *   },
 * });
 */
export function useGetCompanyTimeRulesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCompanyTimeRulesQuery,
    GetCompanyTimeRulesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetCompanyTimeRulesQuery, GetCompanyTimeRulesQueryVariables>(
    GetCompanyTimeRulesDocument,
    baseOptions
  );
}
export function useGetCompanyTimeRulesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCompanyTimeRulesQuery,
    GetCompanyTimeRulesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetCompanyTimeRulesQuery, GetCompanyTimeRulesQueryVariables>(
    GetCompanyTimeRulesDocument,
    baseOptions
  );
}
export type GetCompanyTimeRulesQueryHookResult = ReturnType<typeof useGetCompanyTimeRulesQuery>;
export type GetCompanyTimeRulesLazyQueryHookResult = ReturnType<
  typeof useGetCompanyTimeRulesLazyQuery
>;
export type GetCompanyTimeRulesQueryResult = ApolloReactCommon.QueryResult<
  GetCompanyTimeRulesQuery,
  GetCompanyTimeRulesQueryVariables
>;

export interface IntrospectionResultData {
  __schema: {
    types: {
      kind: string;
      name: string;
      possibleTypes: {
        name: string;
      }[];
    }[];
  };
}
const result: IntrospectionResultData = {
  __schema: {
    types: [
      {
        kind: 'INTERFACE',
        name: 'Node',
        possibleTypes: [
          {
            name: 'AccountsUser',
          },
          {
            name: 'AdditionalChargeParameter',
          },
          {
            name: 'Address',
          },
          {
            name: 'Brand',
          },
          {
            name: 'BrandTheme',
          },
          {
            name: 'BrandThemeColor',
          },
          {
            name: 'BrandThemeMessage',
          },
          {
            name: 'CalendarTimeRule',
          },
          {
            name: 'Card',
          },
          {
            name: 'CardBrand',
          },
          {
            name: 'Category',
          },
          {
            name: 'Company',
          },
          {
            name: 'CompanyCategory',
          },
          {
            name: 'CompanyGroup',
          },
          {
            name: 'CompanyPhone',
          },
          {
            name: 'CostumerDocument',
          },
          {
            name: 'Customer',
          },
          {
            name: 'Document',
          },
          {
            name: 'ExceptionTimeRule',
          },
          {
            name: 'MenuCategory',
          },
          {
            name: 'MenuProduct',
          },
          {
            name: 'MenuProductCompany',
          },
          {
            name: 'Order',
          },
          {
            name: 'OrderExtra',
          },
          {
            name: 'OrderField',
          },
          {
            name: 'OrderFieldParameter',
          },
          {
            name: 'OrderItem',
          },
          {
            name: 'OrderTypeParameter',
          },
          {
            name: 'Parameter',
          },
          {
            name: 'Payment',
          },
          {
            name: 'PaymentParameter',
          },
          {
            name: 'Product',
          },
          {
            name: 'ProductCompany',
          },
          {
            name: 'Promotion',
          },
          {
            name: 'Reward',
          },
          {
            name: 'TemporaryUser',
          },
          {
            name: 'TimeRule',
          },
          {
            name: 'User',
          },
          {
            name: 'WeekedayTimeRule',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'Person',
        possibleTypes: [
          {
            name: 'Customer',
          },
          {
            name: 'TemporaryUser',
          },
        ],
      },
    ],
  },
};
export default result;
